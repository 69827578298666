import { genieUser } from './groupGuards.ts';
import type { AuthnState } from './Authn.tsx';

export const getGroupsFromAccessToken = (accessToken: { payload: Record<string, unknown> } | undefined): string[] => {
  return (accessToken?.payload['cognito:groups'] ?? []) as string[];
};

export const isGenieUser = (authnState: AuthnState) => 'user' in authnState && genieUser(authnState.user?.groups ?? []);
export const isImpersonating = (authnState: AuthnState) =>
  Boolean(
    'user' in authnState &&
      authnState.user &&
      authnState.originalUserId &&
      authnState.user.id !== authnState.originalUserId
  );
