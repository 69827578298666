import { type ReactElement, useState } from 'react';
import { createAssetAutocompleteProps, useAccountAssetPaginatedOptions } from '../../../market/asset/AssetService.tsx';
import { venues } from '../../../venue/VenueData.tsx';
import isNil from 'lodash/fp/isNil';
import { Stack, Typography as JoyTypography } from '@mui/joy';
import FormCheckbox from '../../../technical/form/FormCheckbox.tsx';
import * as yup from 'yup';
import FormSelectedChipsBoard from '../../../technical/form/FormSelectedChipsBoard.tsx';
import { IconVariant } from '../../../market/asset/cryptocurrencies/CryptocurrenciesData.tsx';
import { defaultRowSpacing } from 'components/StackSpacing.ts';
import { useFormContext } from 'react-hook-form';
import AssetLabel from '../../../market/asset/AssetLabel.tsx';
import GSingleAutocomplete from 'components/technical/inputs/Autocomplete/GSingleAutocomplete.tsx';

export const getInitialFields = (): Record<string, unknown> => {
  return {
    onboarding: {
      assets: [],
      recalculateHistoricalBalance: false,
    },
  };
};

export const createOnboardingSchema = (
  venue: string
): yup.ObjectSchema<{
  onboarding: unknown;
}> => {
  const onboarding = venues[venue].onboarding ?? {};
  const onboardingFields: [string, yup.Schema][] = [];
  if (onboarding.assets) {
    onboardingFields.push(['assets', yup.array().of(yup.mixed())]);
  }

  if (onboarding.recalculateHistoricalBalance) {
    onboardingFields.push(['recalculateHistoricalBalance', yup.boolean()]);
  }
  return yup.object({
    onboarding: yup.object(Object.fromEntries(onboardingFields)),
  });
};

const Onboarding = ({
  venue,
}: {
  venue: string;
}): ReactElement | null => {
  const [autocompleteClearKey, setAutocompleteClearKey] = useState(0);
  const options = useAccountAssetPaginatedOptions({
    account: {
      venue: {
        label: venue,
      },
    },
  });

  const methods = useFormContext();

  const onboarding = venues[venue].onboarding ?? {};
  if (isNil(onboarding.assets) && isNil(onboarding.recalculateHistoricalBalance)) {
    return null;
  }

  return (
    <>
      <JoyTypography level="title-lg">Onboarding</JoyTypography>
      <Stack spacing={defaultRowSpacing}>
        {onboarding.recalculateHistoricalBalance && (
          <FormCheckbox name={'onboarding.recalculateHistoricalBalance'} label={'Recalculate historical balances'} />
        )}
        {onboarding.assets && (
          <>
            <GSingleAutocomplete
              value={null}
              key={autocompleteClearKey.toString()} // to clear input after account selected, value=null works only on blur too late
              label="Assets to import"
              showLabelAboveInput
              placeholder="Search"
              width="xl2"
              menuWidth="xl3"
              showChipTooltips
              {...createAssetAutocompleteProps()}
              {...options}
              onChange={(assets) => {
                if (assets) {
                  methods.setValue('onboarding.assets', methods.getValues('onboarding.assets').concat(assets), {
                    shouldValidate: true,
                  });
                  setAutocompleteClearKey((prevAutocompleteClearKey) => prevAutocompleteClearKey + 1);
                }
              }}
            />
            <FormSelectedChipsBoard
              name="onboarding.assets"
              label={'Selected assets'}
              placeholder="Assets"
              height={200}
              width="fullWidth"
              getItemKey={(asset) => asset.id}
              renderItem={(asset) => <AssetLabel asset={asset} size={IconVariant.MEDIUM} wrap={false} />}
            />
          </>
        )}
      </Stack>
    </>
  );
};

export default Onboarding;
