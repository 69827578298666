import { List, Sheet } from '@mui/joy';
import type { ReactElement } from 'react';
import { useLocation } from 'react-router';

import { type MenuItem, shouldShowMenuItem } from './Menu';
import { SidebarGroupItem } from './SidebarGroupItem';
import SidebarSingleItem from './SidebarSingleItem';
import { useAuth } from '../../../UseAuth.tsx';

export const SidebarMenu = ({ menu, scrollable = false }: { menu: MenuItem[]; scrollable?: boolean }): ReactElement => {
  const { pathname } = useLocation();
  const { isAdmin, hasAccess } = useAuth();
  return (
    <Sheet sx={scrollable ? { overflowY: 'auto' } : { flexShrink: 0 }}>
      <List
        sx={{
          paddingInline: 1,
        }}
      >
        {menu
          .filter((item) => shouldShowMenuItem(item, isAdmin, hasAccess))
          .map((item) => {
            if (item.type === 'item') {
              return (
                <SidebarSingleItem item={item} key={item.link} activePath={pathname} level={0} isAdmin={isAdmin} />
              );
            }

            const subitems = item.items.filter((subitem) =>
              shouldShowMenuItem({ ...subitem, type: 'item' }, isAdmin, hasAccess)
            );
            return (
              <SidebarGroupItem
                item={{
                  ...item,
                  items: subitems,
                }}
                key={item.title}
                activePath={pathname}
                isAdmin={isAdmin}
              />
            );
          })}
      </List>
    </Sheet>
  );
};
