import type { FieldPath, FieldPathValue } from 'react-hook-form';
import isNil from 'lodash/fp/isNil';
import type { PortfolioOptimizerInputFields } from '../portfolio/PortfolioOptimizer.validation.ts';
import { bignumber } from 'mathjs';
import type { AssetOptimizerInputFields } from '../asset/AssetOptimizer.validation.ts';
import type { ItemOutlookInput, ItemOutlookSource } from './AssumptionsAndOutlook.validation.tsx';

export function syncOutlookWithGivenUniverseFields(
  getValues: <PATH_TYPE extends FieldPath<PortfolioOptimizerInputFields | AssetOptimizerInputFields>>(
    name: PATH_TYPE
  ) => FieldPathValue<PortfolioOptimizerInputFields | AssetOptimizerInputFields, PATH_TYPE>,
  setValue: (
    name: 'outlook',
    value: (PortfolioOptimizerInputFields | AssetOptimizerInputFields)['outlook'],
    options: {
      shouldValidate: boolean;
    }
  ) => void,
  clearErrors: (name: 'outlook') => void
): void {
  const newOutlook = updateOutlookWithNewGivenPortfolioAndUniverse(
    getValues('outlook'),
    getValues('givenPortfolio'),
    getValues('universe')
  );

  clearErrors('outlook');
  setValue('outlook', newOutlook, {
    shouldValidate: true,
  });
}

/** assumptions outlook contains assets from initial/given portfolio and from universe */
const updateOutlookWithNewGivenPortfolioAndUniverse = <
  TYPE extends PortfolioOptimizerInputFields | AssetOptimizerInputFields,
>(
  currentOutlook: TYPE['outlook'],
  givenPortfolio: Record<string, string | null>,
  universe: TYPE['universe']
): ItemOutlookInput[] => {
  const nonZeroGivenPortfolio = new Set(
    Object.entries(givenPortfolio)
      .filter(([_assetId, assetValue]) => !bignumber(assetValue ?? 0).isZero())
      .map(([assetId]) => assetId)
  );

  const universeItemIds = new Set<string>(universe.map((item) => item?.id).filter((id): id is string => !isNil(id)));
  const givenPortfolioUniverseIds = new Set([...Array.from(nonZeroGivenPortfolio), ...Array.from(universeItemIds)]);
  const outlookItemIds = new Set<string>(currentOutlook.map((out) => out.id));

  const newOutlook = [
    ...currentOutlook
      .filter((out) => givenPortfolioUniverseIds.has(out.id))
      .map((out) => ({
        ...out,
        sources: getSources(out.id, nonZeroGivenPortfolio, universeItemIds),
      })),
    ...Array.from(givenPortfolioUniverseIds)
      .filter((id) => !outlookItemIds.has(id))
      .map(
        (id): ItemOutlookInput => ({
          id,
          returns: null,
          riskWeight: null,
          leverage: null,
          yield: null,
          sources: getSources(id, nonZeroGivenPortfolio, universeItemIds),
        })
      ),
  ];

  return newOutlook;
};

function getSources(id: string, givenPortfolio: Set<string>, universe: Set<string>): ItemOutlookSource[] {
  const sources: ItemOutlookSource[] = [];

  if (universe.has(id)) {
    sources.push('universe');
  }

  if (givenPortfolio.has(id)) {
    sources.push('givenPortfolio');
  }

  return sources;
}
