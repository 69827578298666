import type { SubAccountOptionValue } from '../account/AccountService.tsx';
import type { Dayjs } from 'dayjs';
import * as yup from 'yup';
import { type FunctionComponent, useState } from 'react';
import {
  ISlotType,
  type ISnapshotEditorInputQuery,
  type ISnapshotPositionsQuery,
  SnapshotPositionsDocument,
  useUpdateSnapshotPositionMutation,
} from '../../../generated/graphql.tsx';
import { useFeedback } from '../../technical/Feedback/UseFeedback.tsx';
import { useForm, useWatch } from 'react-hook-form';
import gYupResolver from '../../technical/form/gYupResolver.ts';
import { canAppearInASnapshot, createAssetSelectOptions } from '../../market/asset/AssetService.tsx';
import { isNil } from 'lodash/fp';
import { convertDateInUtcToUTCISODate } from '../../date.utils.ts';
import { Stack } from '@mui/joy';
import GCheckbox from '../../technical/inputs/GCheckbox/GCheckbox.tsx';
import { defaultRowSpacing } from '../../StackSpacing.ts';
import GFormProvider from '../../technical/form/GFormProvider.tsx';
import FormSelect from '../../technical/form/FormSelect.tsx';
import SubmitButton from '../../technical/form/SubmitButton.tsx';
import type { FormInputType } from '../../technical/form/Form.types.ts';
import type { SelectOption } from '../../technical/inputs/Select/Select.props.ts';
import capitalize from 'lodash/fp/capitalize';
import FormInput from 'components/technical/form/FormInput.tsx';
import FormStaticSingleAutocomplete from '../../technical/form/FormStaticSingleAutocomplete.tsx';

type SubAccount = ISnapshotEditorInputQuery['portfolio']['accounts'][number]['subAccounts'][number];
export type SubAccountValue = SubAccountOptionValue<SubAccount>;

const inputWidth = 'normal';

interface FormOutputFields {
  asset: { id: string };
  amount: number;
  slot: ISlotType;
}

const slotOptions: SelectOption<ISlotType>[] = Object.values(ISlotType).map((val) => ({
  value: val,
  label: capitalize(val),
  key: val,
}));

type FormInputFields = FormInputType<FormOutputFields>;

const formSchema = yup.object({
  asset: yup.object({ id: yup.string().required() }).required(),
  amount: yup.number().required(),
  slot: yup.string().oneOf(Object.values(ISlotType)).required(),
});

type PositionEditorForm = {
  date: Dayjs;
  subAccount: SubAccountValue;
  positions: ISnapshotPositionsQuery['portfolio']['snapshot'][number]['positions'];
  assets: ISnapshotPositionsQuery['assets']['list'];
};

export const PositionEditorForm: FunctionComponent<PositionEditorForm> = ({ date, subAccount, positions, assets }) => {
  const [onlyExistingAssetPositions, setOnlyExistingAssetPositions] = useState(false);
  const [updateSnapshotPosition] = useUpdateSnapshotPositionMutation();
  const { showSuccessMessage, showGraphqlError } = useFeedback();

  const methods = useForm<FormInputFields>({
    resolver: gYupResolver(formSchema),
  });

  const asset = useWatch<FormInputFields, 'asset'>({ name: 'asset', control: methods.control });
  const slot = useWatch<FormInputFields, 'slot'>({ name: 'slot', control: methods.control });
  const snapshotSpotAssets = new Set<string>(positions.filter((p) => p.spot).map((p) => p.asset.id));

  const availableAssets = assets.filter((asset) => {
    if (onlyExistingAssetPositions && !snapshotSpotAssets.has(asset.id)) {
      return false;
    }

    return canAppearInASnapshot(asset);
  });

  const assetAutocompleteProps = createAssetSelectOptions(availableAssets);
  const assetIsSelected = !isNil(asset);

  async function setPosition(formData: FormInputFields): Promise<void> {
    try {
      const output = formData as unknown as FormOutputFields;
      await updateSnapshotPosition({
        variables: {
          date: convertDateInUtcToUTCISODate(date),
          amount: output.amount.toString(),
          assetId: output.asset.id,
          subAccountId: subAccount.id,
          slot: output.slot,
        },
        refetchQueries: [SnapshotPositionsDocument],
      });
      showSuccessMessage('Position successfully updated');
    } catch (e) {
      showGraphqlError(e);
    }
  }

  const sameAssetSlotPosition = positions.find((pos) => pos.asset.id === asset?.id && pos.spot?.slot === slot);
  const sameAssetSlotPosAmount = sameAssetSlotPosition?.spot?.amount;
  return (
    <Stack spacing={2}>
      <GCheckbox
        width="minContent"
        shiftByLabelHeight
        checked={onlyExistingAssetPositions}
        label="Show assets for open positions only"
        onChange={(value): void => setOnlyExistingAssetPositions(value)}
      />

      <GFormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(setPosition)}>
          <Stack direction="column" spacing={defaultRowSpacing}>
            <Stack direction="row" spacing={defaultRowSpacing} alignItems="flex-end" flexWrap="wrap">
              <FormStaticSingleAutocomplete {...assetAutocompleteProps} width={inputWidth} label="Asset" name="asset" />
              <FormSelect name="slot" label="Slot" width={inputWidth} options={slotOptions} />
              <FormInput
                type="number"
                name="amount"
                label="Amount"
                width={inputWidth}
                placeholder={isNil(sameAssetSlotPosAmount) ? '' : sameAssetSlotPosAmount}
              />
            </Stack>
            <SubmitButton width={inputWidth} disabled={!assetIsSelected}>
              {sameAssetSlotPosition ? 'Change position' : 'Add position'}
            </SubmitButton>
          </Stack>
        </form>
      </GFormProvider>
    </Stack>
  );
};
