import type { StaticAutocompleteOption } from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.props';
import { HEIGHT_PX, SubAccountLabel } from './SubAccountLabel.tsx';
import { type IAccount, IAssetType, type ISubAccount } from '../../../generated/graphql';
import { IconVariant } from '../../market/asset/cryptocurrencies/CryptocurrenciesData';
import { GENIE_VENUE, venues } from '../../venue/VenueData.tsx';
import { AccountLabel } from './AccountLabel.tsx';
import isNil from 'lodash/fp/isNil';
import { sortBy } from 'lodash/fp';
import { logWarnOnce } from '../../log.utils.ts';
import type { AssetSelectOptionValue } from '../../market/asset/AssetService.tsx';

type SubAccount = Pick<ISubAccount, 'id' | 'name'>;
type Account = Pick<IAccount, 'venue' | 'name' | 'id'>;

export type CreateAccountIdAutocompleteOptionsInputAccount = Account;
export type CreateSubAccountIdAutocompleteOptionsInputAccount = Account & {
  subAccounts: SubAccount[];
};

export type SubAccountOptionValue<TSubAccount extends SubAccount> = TSubAccount & {
  account: Omit<Account, 'subAccounts'>;
};

export type AccountOptionValue<TAccount extends Account> = TAccount;

export const createAccountAutocompleteOptions = <TAccount extends Account>(
  accounts: Array<TAccount>
): {
  options: StaticAutocompleteOption<AccountOptionValue<TAccount>>[];
  optionHeight: number;
  limitTags: number;
  isValueEqual: (
    a: AccountOptionValue<TAccount> | undefined | null,
    b: AccountOptionValue<TAccount> | undefined | null
  ) => boolean;
} => {
  return {
    options: sortBy((acc) => acc.name, accounts).map((account) => ({
      label: <AccountLabel account={account} size={IconVariant.MEDIUM} wrap={false} plain />,
      value: account,
      searchText: account.name,
      key: account.id,
    })),
    optionHeight: HEIGHT_PX,
    limitTags: 1,
    isValueEqual: (
      a: AccountOptionValue<TAccount> | undefined | null,
      b: AccountOptionValue<TAccount> | undefined | null
    ): boolean => (!a && !b) || a?.id === b?.id,
  };
};

const groupByAccount = (subAccount: { account: { name: string } } | null | undefined): string => {
  return !isNil(subAccount) ? subAccount.account?.name : '';
};

export const createSubAccountAutocompleteOptions = <TSubAccount extends SubAccount>(
  accounts: Array<
    Account & {
      subAccounts: TSubAccount[];
    }
  >
): {
  options: StaticAutocompleteOption<SubAccountOptionValue<TSubAccount & { account: Account }>>[];
  optionHeight: number;
  limitTags: number;
  groupBy: (option: StaticAutocompleteOption<(TSubAccount & { account: Account }) | undefined>) => string;
  isValueEqual: (
    a: SubAccountOptionValue<TSubAccount> | undefined | null,
    b: SubAccountOptionValue<TSubAccount> | undefined | null
  ) => boolean;
} => {
  const options = Object.values(accounts).flatMap((account) =>
    account.subAccounts.map((subAccount) => ({
      label: <SubAccountLabel subAccount={{ ...subAccount, account }} size={IconVariant.MEDIUM} wrap={false} plain />,
      value: {
        ...subAccount,
        account: {
          ...account,
          subAccounts: undefined, // better to avoid circle references
        },
      },
      searchText: subAccount.name,
      key: subAccount.id,
    }))
  );

  const sortedOptions = sortBy((opt) => groupByAccount(opt.value), options);
  return {
    options: sortedOptions,
    optionHeight: HEIGHT_PX,
    limitTags: 1,
    groupBy: (option: StaticAutocompleteOption<(TSubAccount & { account: Account }) | undefined>): string =>
      groupByAccount(option.value),
    isValueEqual: (
      a: SubAccountOptionValue<TSubAccount> | undefined | null,
      b: SubAccountOptionValue<TSubAccount> | undefined | null
    ): boolean => (!a && !b) || a?.id === b?.id,
  };
};

export const createSubAccountIdAutocompleteOptions = (
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[]
): {
  options: StaticAutocompleteOption<string>[];
  optionHeight: number;
  limitTags: number;
  groupBy: (option: StaticAutocompleteOption<string | null>) => string;
  isValueEqual: (a: string | undefined | null, b: string | undefined | null) => boolean;
} => {
  const props = createSubAccountAutocompleteOptions(accounts);
  const idToAccountName = Object.fromEntries(
    accounts.flatMap((account) => account.subAccounts.map((subAccount) => [subAccount.id, account.name]))
  );
  return {
    ...props,
    groupBy: (option: StaticAutocompleteOption<string | null>): string =>
      !isNil(option.value) ? idToAccountName[option.value] : '',
    options: props.options.map((option) => ({
      ...option,
      value: option.value.id,
    })),
    isValueEqual: (a: string | undefined | null, b: string | undefined | null): boolean => (!a && !b) || a === b,
  };
};

export const createAccountIdAutocompleteOptions = (
  accounts: CreateAccountIdAutocompleteOptionsInputAccount[]
): {
  options: StaticAutocompleteOption<string>[];
  optionHeight: number;
  limitTags: number;
  isValueEqual: (a: string | undefined | null, b: string | undefined | null) => boolean;
} => {
  const props = createAccountAutocompleteOptions(accounts);
  const optionsWithIdAsValue = props.options.map((option) => ({
    ...option,
    value: option.value.id,
  }));
  return {
    ...props,
    options: optionsWithIdAsValue,
    isValueEqual: (a: string | undefined | null, b: string | undefined | null): boolean => (!a && !b) || a === b,
  };
};

export enum AccountType {
  Blockchain = 'BLOCKCHAIN',
  Custodian = 'CUSTODIAN',
  Virtual = 'VIRTUAL',
}

export const calculateAccountType = (account: { venue: { label: string } }): AccountType => {
  const venueLabel = account.venue.label;
  if (venueLabel === GENIE_VENUE) {
    return AccountType.Virtual;
  }

  const venue = venues[venueLabel];
  if (venue?.blockchain) {
    return AccountType.Blockchain;
  }

  return AccountType.Custodian;
};

export const isAssetValidForAccount = (
  asset: AssetSelectOptionValue,
  account: { venue: { label: string } }
): boolean => {
  const accountType = calculateAccountType(account);
  if (accountType === AccountType.Virtual) {
    return asset.type !== IAssetType.Exchange && asset.type !== IAssetType.Derivative;
  }

  if ([AccountType.Custodian, AccountType.Blockchain].includes(accountType)) {
    if (asset.type === IAssetType.Unvested) {
      return false;
    }

    if (!asset.type) {
      logWarnOnce('Unknown asset type for asset', asset);
      return false;
    }

    if ([IAssetType.Public, IAssetType.Private].includes(asset.type)) {
      return true;
    }

    const venue = getExchangeDerivativeAssetVenue(account);
    if (asset.type === IAssetType.Exchange) {
      return venue === asset.exchangeDetails;
    }
    if (asset.type === IAssetType.Derivative) {
      if (!asset.derivativeDetails) {
        logWarnOnce('Unknown derivative details of asset', asset);
        return false;
      }
      return venue === asset.derivativeDetails.exchange;
    }

    console.warn('Unhandled asset type', asset);
    return false;
  }
  console.error('Unknown account type', accountType);
  throw new Error('Unknown account type');
};

// assets should be assigned always to venue for exchange v1
export const getExchangeDerivativeAssetVenue = (
  account: { venue?: { label?: string | null } | null } | null | undefined
): string | undefined => account?.venue?.label?.replace(/-v2$/, '');
