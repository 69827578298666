import type { ApolloClient } from '@apollo/client';
import { type ErrorHandlingOutput, useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import { type Asset, getAssets } from './Asset.types';
import { NoAssetFoundErrorMessage } from './NoAssetFoundErrorMessage.tsx';
import { AssetNameDocument, type IAsset, type IAssetNameQuery, useAssetQuery } from '../../../generated/graphql';

export const getAssetById = async (
  apolloClient: ApolloClient<unknown>,
  assetId: string
): Promise<Pick<IAsset, 'id' | 'name'>> => {
  const result = await apolloClient.query<IAssetNameQuery>({
    query: AssetNameDocument,
    variables: {
      filters: {
        assetIds: [assetId],
      },
    },
  });

  if (result.error) {
    console.error('Error loading breadcrumb', result.error);
    throw result.error;
  }

  const assets = result.data.assets.list;
  const asset = assets.find((asset) => asset.id === assetId);
  if (!asset) {
    throw new Error(`Couldn't find asset: ${assetId}`);
  }

  return asset;
};

export const useAsset = (id: string): ErrorHandlingOutput<{ data: Asset }> => {
  const { loaded, data, Fallback } = useDefaultErrorHandling(
    useAssetQuery({
      variables: { assetId: id },
    })
  );

  if (!loaded) {
    return { loaded: false, data, Fallback };
  }

  const asset = getAssets(data.assets.list).at(0);
  if (!asset) {
    return { loaded: false, data: undefined, Fallback: NoAssetFoundErrorMessage };
  }

  return { loaded: true, data: asset, Fallback };
};
