import { intersection, isEmpty, uniq } from 'lodash/fp';
import isNil from 'lodash/fp/isNil';
import { useEffect, useSyncExternalStore } from 'react';
import * as yup from 'yup';
import { emptyToUndefined } from 'components/bookkeeping/transactionsOld/TransactionService';
import type { SubAccountType } from 'components/portfolio/account/Account.types';
import type { ISubAccountAssetFiltersQuery } from 'generated/graphql';

type Listener = () => void;
const listeners = new Set<Listener>();

export const SUB_ACCOUNT_ASSET_FILTERS_KEY = 'subAccountAssetFilters';
export type SubFund = ISubAccountAssetFiltersQuery['portfolio']['subFunds']['list'][number];
export type SubAccount = ISubAccountAssetFiltersQuery['portfolio']['accounts'][number]['subAccounts'][number];

export type SubAccountAssetFilters = {
  subFundIds: number[] | undefined;
  accountIds: string[] | undefined;
  subAccountIds: string[] | undefined;
  assetIds: string[] | undefined;
};

const filtersSchema = yup.object().shape({
  subFundIds: yup.array().of(yup.number()).nullable(),
  accountIds: yup.array().of(yup.string()).nullable(),
  subAccountIds: yup.array().of(yup.string()).nullable(),
  assetIds: yup.array().of(yup.string()).nullable(),
});

type UseSubAccountAssetFilters = {
  subAccountAssetFilters: SubAccountAssetFilters;
  isFiltered: boolean;
  setSubAccountAssetFilters: (newFilterValues: SubAccountAssetFilters) => void;
};

function subscribe(listener: Listener): () => void {
  listeners.add(listener);
  return () => listeners.delete(listener);
}

function setSubAccountAssetFilterToExternalStorage(newFilterValues: SubAccountAssetFilters): void {
  sessionStorage.setItem(
    SUB_ACCOUNT_ASSET_FILTERS_KEY,
    JSON.stringify({
      subFundIds: emptyToUndefined(newFilterValues.subFundIds),
      subAccountIds: emptyToUndefined(newFilterValues.subAccountIds),
      accountIds: emptyToUndefined(newFilterValues.accountIds),
      assetIds: emptyToUndefined(newFilterValues.assetIds),
    })
  );
  for (const listener of listeners) {
    listener();
  }
}

/** Store filter value in session storage to keep it between pages refreshes */
export function useSubAccountAssetFilters(): UseSubAccountAssetFilters {
  const filterValueJson = useSyncExternalStore(subscribe, () => sessionStorage.getItem(SUB_ACCOUNT_ASSET_FILTERS_KEY));

  let filterValue: SubAccountAssetFilters | undefined;
  try {
    filterValue = JSON.parse(filterValueJson ?? '{}');
    filtersSchema.validateSync(filterValue);
  } catch (e) {
    console.warn(
      `filter value in session storage ${SUB_ACCOUNT_ASSET_FILTERS_KEY} should be {subFundIds: number[];subAccountIds: string[], assetIds: string[]}, but found ${filterValueJson}`,
      e
    );
    filterValue = undefined;
  }

  filterValue ??= { subFundIds: undefined, subAccountIds: undefined, assetIds: undefined, accountIds: undefined };

  return {
    subAccountAssetFilters: filterValue,
    isFiltered:
      !isNil(filterValue.subAccountIds) ||
      !isNil(filterValue.subFundIds) ||
      !isNil(filterValue.assetIds) ||
      !isNil(filterValue.accountIds),
    setSubAccountAssetFilters: setSubAccountAssetFilterToExternalStorage,
  };
}

/** We need update filter value in sessionStorage in case some saved in filter subFundIds were removed */
export function useSyncSubAccountAssetFilterWithCurrentData(queryData: ISubAccountAssetFiltersQuery): void {
  const { setSubAccountAssetFilters, subAccountAssetFilters } = useSubAccountAssetFilters();
  useEffect(() => {
    const subFundIds = intersection(
      subAccountAssetFilters.subFundIds,
      queryData.portfolio.subFunds.list.map((subFund) => subFund.id)
    );

    const subAccountIds = intersection(
      queryData.portfolio.accounts.flatMap((account) => account.subAccounts).map((subAccount) => subAccount.id),
      subAccountAssetFilters.subAccountIds
    );

    const accountIds = intersection(
      queryData.portfolio.accounts.map((account) => account.id),
      subAccountAssetFilters.accountIds
    );

    const assetIds = intersection(
      uniq(queryData.portfolio.positions.positions.map((position) => position.asset.id)),
      subAccountAssetFilters.assetIds
    );

    setSubAccountAssetFilters({
      subFundIds,
      subAccountIds,
      accountIds,
      assetIds,
    });
  }, [subAccountAssetFilters, setSubAccountAssetFilters, queryData]);
}

export function useSyncSubAccountAssetFilterWithCurrentSubFunds(subFunds: SubFund[] | undefined): void {
  const { setSubAccountAssetFilters, subAccountAssetFilters } = useSubAccountAssetFilters();
  useEffect(() => {
    if (isNil(subFunds) || isEmpty(subAccountAssetFilters.subFundIds)) {
      return;
    }
    const subFundIds = intersection(
      subAccountAssetFilters.subFundIds,
      subFunds.map((subFund) => subFund.id)
    );

    setSubAccountAssetFilters({
      ...subAccountAssetFilters,
      subFundIds,
    });
  }, [subAccountAssetFilters, setSubAccountAssetFilters, subFunds]);
}

export function useSyncSubAccountAssetFilterWithCurrentSubAccounts(subAccounts: SubAccountType[] | undefined): void {
  const { setSubAccountAssetFilters, subAccountAssetFilters } = useSubAccountAssetFilters();
  useEffect(() => {
    if (isNil(subAccounts) || isEmpty(subAccountAssetFilters.subAccountIds)) {
      return;
    }
    const subAccountIds = intersection(
      subAccountAssetFilters.subAccountIds,
      subAccounts.map((subAccount) => subAccount.id)
    );

    setSubAccountAssetFilters({
      ...subAccountAssetFilters,
      subAccountIds,
    });
  }, [subAccountAssetFilters, setSubAccountAssetFilters, subAccounts]);
}
