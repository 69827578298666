import { Add } from '@mui/icons-material';
import { RadioGroup, Stack, Tooltip } from '@mui/joy';
import { sortBy } from 'lodash/fp';
import { type FunctionComponent, type ReactElement, useEffect, useRef, useState } from 'react';

import CreatePresetDialog, { type FormType } from './CreatePresetDialog.tsx';
import type { ReportPreset, ReportSettings } from './GAgGridPresets.types.ts';
import PresetChip from './PresetChip.tsx';
import DialogButton from '../inputs/GButton/DialogButton.tsx';
import Button from '../inputs/GButton/GButton.tsx';
import { useDrawer } from '../Drawer/UseDrawer.tsx';
import { v4 } from 'uuid';
import { DrawerType } from '../Drawer/Drawer.types.ts';
import { useUnmount } from 'react-use';

type ReportPresetsProps = {
  userSettings: ReportSettings;
  defaultPresets: ReportPreset[];
  onAddPreset: (presetData: FormType) => Promise<void>;
  onSavePreset: () => Promise<void>;
  onDeletePreset: (preset: ReportPreset) => Promise<void>;
  setCurrentPreset: (preset: ReportPreset) => void;
  toggleFavorite: (presetName: string) => Promise<void>;
  onResetPreset: () => void;
  copyCurrentStateAsJson: () => void;
  currentPreset: ReportPreset;
  loading: boolean;
};

const ReportPresets: FunctionComponent<ReportPresetsProps> = ({
  userSettings,
  defaultPresets,
  currentPreset,
  setCurrentPreset,
  onAddPreset,
  onSavePreset,
  onDeletePreset,
  toggleFavorite,
  loading,
  onResetPreset,
  copyCurrentStateAsJson,
}) => {
  const [seeAllDrawerOpen, setSeeAllDrawerOpen] = useState(false);
  const openKeyRef = useRef(v4());
  const { openDrawer, closeDrawer, drawer, openKey } = useDrawer();
  const allPresets = [...defaultPresets, ...(userSettings.presets ?? [])];
  const favoritePresetsPlusCurrent = allPresets.filter(
    (preset) => userSettings.favorites.includes(preset.name) || preset.name === currentPreset.name
  );
  const existingPresetNames = allPresets.map((preset) => preset.name);

  useEffect(() => {
    if (drawer === DrawerType.Presets && openKey === openKeyRef.current) {
      setSeeAllDrawerOpen(true);
    } else {
      setSeeAllDrawerOpen(false);
    }
  }, [openKey, drawer]);

  useUnmount(() => {
    if (drawer === DrawerType.Presets && openKey === openKeyRef.current) {
      closeDrawer();
    }
  });

  function isFavorite(preset: ReportPreset): boolean {
    return userSettings.favorites.includes(preset.name);
  }

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <RadioGroup sx={{ display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap' }}>
          {favoritePresetsPlusCurrent.map((favoritePreset) => (
            <PresetChip
              key={favoritePreset.name}
              preset={favoritePreset}
              checked={currentPreset === favoritePreset}
              onChange={(): void => setCurrentPreset(favoritePreset)}
            />
          ))}
          <Button
            size="sm"
            sx={{ minHeight: 'var(--Chip-minHeight)' }} // align height with chips
            variant={seeAllDrawerOpen ? 'solid' : 'plain'}
            onClick={(): void => {
              setSeeAllDrawerOpen(!seeAllDrawerOpen);
              if (!seeAllDrawerOpen) {
                const sortedPresets = sortBy(
                  [(preset): boolean => !isFavorite(preset), (preset): string => preset.name.toLowerCase()],
                  allPresets
                );

                openDrawer(DrawerType.Presets, openKeyRef.current, {
                  drawerKey: v4(), // trigger unmounting the previous preset drawer
                  favorites: userSettings.favorites,
                  presets: sortedPresets,
                  currentPreset: currentPreset,
                  toggleFavorite: toggleFavorite,
                  setCurrentPreset: setCurrentPreset,
                  onDeletePreset: onDeletePreset,
                  copyCurrentStateAsJson: copyCurrentStateAsJson,
                });
              } else {
                closeDrawer();
              }
            }}
          >
            See all
          </Button>
        </RadioGroup>
        <Stack direction="row" alignItems="center" gap={1}>
          <Tooltip title="Return to current preset settings">
            <Button size="sm" variant="plain" onClick={onResetPreset}>
              Reset
            </Button>
          </Tooltip>
          <Button size="sm" onClick={onSavePreset} loading={loading} disabled={currentPreset.isDefault}>
            Save
          </Button>
          <DialogButton
            width="fullWidth"
            startDecorator={<Add />}
            sx={{ whiteSpace: 'nowrap' }}
            renderDialog={({ onClose }): ReactElement => (
              <CreatePresetDialog
                existingPresetNames={existingPresetNames}
                onClose={onClose}
                onAddPreset={onAddPreset}
              />
            )}
          >
            Save as
          </DialogButton>
        </Stack>
      </Stack>
    </>
  );
};

export default ReportPresets;
