import { Add, DeleteOutline, Edit } from '@mui/icons-material';
import { Stack, Tab, TabList, TabPanel, Tabs, Typography } from '@mui/joy';
import { type FunctionComponent, type ReactElement, useState } from 'react';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';

import {
  AssetGroupsDocument,
  type IAssetGroupsQuery,
  useAssetGroupsQuery,
  useCreateAssetGroupMutation,
  useDeleteClusterAssetGroupsMutation,
  useRenameClusterMutation,
} from '../../../../generated/graphql';
import { isNil, uniq } from 'lodash/fp';
import ClusterGroupsTable from './ClusterGroupsTable';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import { defaultRowSpacing } from 'components/StackSpacing';
import ConfirmationDialog from 'components/technical/form/dialog/ConfirmationDialog';
import CreateRenameClusterDialog from './CreateRenameClusterDialog';
import CreateRenameAssetGroupDialog from './CreateRenameAssetGroupDialog';

const tabsWidth = 300;
type AssetGroups = IAssetGroupsQuery['assets']['assetGroups'];
export type UserGroup = AssetGroups['userGroups'][number];

type GroupListProps = {
  assetGroups: AssetGroups;
  assets: IAssetGroupsQuery['assets']['feature'];
};

const maxNameLength = 64;

const AssetGroups: FunctionComponent<GroupListProps> = ({ assetGroups, assets }) => {
  const clusters = uniq(assetGroups.userGroups.map((group) => group.clusterName));
  const existingGroups = new Set(assetGroups.userGroups.map((group) => group.groupName));

  const refetch = { refetchQueries: [AssetGroupsDocument] };

  const [createAssetGroup] = useCreateAssetGroupMutation(refetch);
  const [renameCluster] = useRenameClusterMutation(refetch);
  const [deleteClusterAssetGroups] = useDeleteClusterAssetGroupsMutation(refetch);

  const [selectedCluster, setSelectedCluster] = useState<string | null>(clusters[0] ?? null);
  const { showGraphqlError, showSuccessMessage } = useFeedback();

  const selectedClusterGroups = assetGroups.userGroups.filter((group) => group.clusterName === selectedCluster);

  return (
    <>
      <Stack direction="row" gap={defaultRowSpacing} alignItems="center">
        <Stack direction="row" minWidth={tabsWidth} gap={defaultRowSpacing} justifyContent="space-between" pl={2}>
          <Typography level="h4">Clusters</Typography>
          <DialogButton
            startDecorator={<Add />}
            variant="outlined"
            renderDialog={({ onClose }): ReactElement => (
              <CreateRenameClusterDialog
                onClose={onClose}
                title="New cluster"
                existingClusters={clusters}
                submitButtonText="Create"
                handleFormSubmit={async (newClusterFormData, onErrorAndThrow): Promise<void> => {
                  try {
                    await createAssetGroup({
                      variables: {
                        input: {
                          groupName: `${newClusterFormData.cluster} - default`.slice(0, maxNameLength),
                          clusterName: newClusterFormData.cluster,
                          assets: [],
                        },
                      },
                    });
                  } catch (e) {
                    onErrorAndThrow(e);
                  }

                  setSelectedCluster(newClusterFormData.cluster);
                  onClose();
                }}
              />
            )}
          >
            Add
          </DialogButton>
        </Stack>

        {!isNil(selectedCluster) && (
          <>
            <Typography level="h4" noWrap flexShrink={0}>
              {selectedCluster}
            </Typography>
            <DialogButton
              paddingVariant="short"
              variant="plain"
              startDecorator={<Edit />}
              renderDialog={({ onClose }): ReactElement => (
                <CreateRenameClusterDialog
                  onClose={onClose}
                  title={`Rename cluster ${selectedCluster}`}
                  existingClusters={clusters}
                  submitButtonText="Rename"
                  currentCluster={selectedCluster}
                  handleFormSubmit={async (clusterFormData, onErrorAndThrow): Promise<void> => {
                    try {
                      await renameCluster({
                        variables: {
                          oldClusterName: selectedCluster,
                          newClusterName: clusterFormData.cluster,
                        },
                      });
                    } catch (e) {
                      onErrorAndThrow(e);
                    }

                    setSelectedCluster(clusterFormData.cluster);
                    onClose();
                    showSuccessMessage('Group successfully renamed');
                  }}
                />
              )}
            />

            <Stack direction="row-reverse" gap={defaultRowSpacing} width="100%" px={2}>
              <DialogButton
                variant="soft"
                startDecorator={<DeleteOutline />}
                color="danger"
                paddingVariant="short"
                renderDialog={({ onClose }): ReactElement => (
                  <ConfirmationDialog
                    onClose={onClose}
                    onApprove={async () => {
                      try {
                        await deleteClusterAssetGroups({
                          variables: {
                            clusterName: selectedCluster,
                          },
                        });
                      } catch (e) {
                        showGraphqlError(e);
                        onClose();
                        return;
                      }

                      showSuccessMessage(`All groups in cluster ${selectedCluster} successfully deleted`);
                      setSelectedCluster(clusters.find((cluster) => cluster !== selectedCluster) ?? null);
                      onClose();
                    }}
                  >
                    Are you sure you want to remove all {selectedCluster} cluster groups?
                  </ConfirmationDialog>
                )}
              />

              <DialogButton
                startDecorator={<Add />}
                variant="outlined"
                renderDialog={({ onClose }): ReactElement => (
                  <CreateRenameAssetGroupDialog
                    onClose={onClose}
                    title={`New group in "${selectedCluster}"`}
                    submitButtonText="Create"
                    existingGroups={existingGroups}
                    handleFormSubmit={async (newGroup, onErrorAndThrow): Promise<void> => {
                      try {
                        await createAssetGroup({
                          variables: {
                            input: {
                              groupName: newGroup.groupName,
                              clusterName: selectedCluster,
                              assets: [],
                            },
                          },
                        });
                      } catch (e) {
                        onErrorAndThrow(e);
                      }

                      onClose();
                      showSuccessMessage('New group successfully added');
                    }}
                  />
                )}
              >
                Add asset group
              </DialogButton>
            </Stack>
          </>
        )}
      </Stack>

      {clusters.length === 0 && (
        <Typography px={2} level="body-md">
          You haven&apos;t added any clusters yet. Click &quot;Add&quot; to get started.
        </Typography>
      )}

      {clusters.length > 0 && (
        <Tabs
          orientation="vertical"
          onChange={(_e, newSelectedCluster) => setSelectedCluster(String(newSelectedCluster))}
          value={selectedCluster}
          size="lg"
          sx={{ height: '100%' }}
        >
          <TabList sx={{ width: tabsWidth, flexShrink: 0 }}>
            {clusters.map((cluster) => (
              <Tab key={cluster} value={cluster}>
                <Typography level="body-md" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {cluster}
                </Typography>
              </Tab>
            ))}
          </TabList>

          {!isNil(selectedCluster) && (
            <TabPanel value={selectedCluster} sx={{ padding: 0, height: '100%' }}>
              <ClusterGroupsTable
                clusterGroups={selectedClusterGroups}
                assets={assets}
                existingGroups={existingGroups}
                setSelectedCluster={setSelectedCluster}
                clusters={clusters}
              />
            </TabPanel>
          )}
        </Tabs>
      )}
    </>
  );
};

const AssetGroupsDashboard = (): ReactElement => {
  const { data, Fallback, loaded } = useDefaultErrorHandling(
    useAssetGroupsQuery({
      fetchPolicy: 'cache-first',
    })
  );

  if (!loaded) {
    return <Fallback />;
  }
  return <AssetGroups assetGroups={data.assets.assetGroups} assets={data.assets.feature} />;
};

export default AssetGroupsDashboard;
