import { Stack, Typography } from '@mui/joy';
import { type ReactElement, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { uploadFile } from 'components/file.utils';
import { createSubAccountIdAutocompleteOptions } from 'components/portfolio/account/AccountService.tsx';
import { defaultRowSpacing } from 'components/StackSpacing';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import StaticSingleAutocomplete from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete';
import FileUploadInput from 'components/technical/inputs/FileUploadInput';
import {
  type ITransactionUploadInputQuery,
  useCreateUploadLinkMutation,
  useTransactionUploadInputSuspenseQuery,
  useValidateTransactionsUploadMutation,
} from 'generated/graphql';
import { useNavigate } from 'react-router';
import GAgGrid from 'components/technical/grids/GAgGrid';
import type { ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { DateTimeFormat, formatDate } from 'components/formatter.utils';
import { getStatusText } from './upload.utils';
import GLink from 'components/technical/GLink/GLink';
import { subAccountColumn } from 'components/technical/grids/SharedReportColumns';
import GInput from 'components/technical/inputs/GInput/GInput';
import { useAuth } from 'UseAuth';
import { useUserTimezone } from 'components/technical/UseUserTimezone';

const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
};

type RowData = ITransactionUploadInputQuery['bookkeeping']['transactionUpload']['uploads'][number];

const UploadTransactions = (): ReactElement => {
  const [validateTransactionsUpload, { loading: validationRequestLoading }] = useValidateTransactionsUploadMutation();
  const [createUploadLink, { loading: createUploadLinkLoading }] = useCreateUploadLinkMutation();
  const [uploadIsInProgress, setUploadIsInProgress] = useState(false);
  const { showErrorMessage } = useFeedback();
  const { data } = useTransactionUploadInputSuspenseQuery();
  const [subAccount, setSubAccount] = useState<string | null>(null);
  const [tag, setTag] = useState<string>('');
  const navigate = useNavigate();
  const { isAdmin } = useAuth();
  const timezone = useUserTimezone();

  const loading = validationRequestLoading || createUploadLinkLoading || uploadIsInProgress;

  const subAccountOptions = createSubAccountIdAutocompleteOptions(data.portfolio.accounts);

  async function uploadFileForValidation(file: File): Promise<void> {
    const uploadLinkResponse = await createUploadLink({
      variables: {
        fileName: file.name,
      },
      onError: () => showErrorMessage('Upload failed'),
    });

    try {
      setUploadIsInProgress(true);
      await uploadFile({
        blob: file,
        uploadUrl: uploadLinkResponse.data!.createUploadLink.uploadLink,
      });
    } catch (e) {
      showErrorMessage('Upload failed');
      // uploadFile sends file to s3 bucket we need to send error to sentry manually
      Sentry.captureException(e);
      return;
    } finally {
      setUploadIsInProgress(false);
    }

    const uploadResult = await validateTransactionsUpload({
      variables: {
        s3FileName: uploadLinkResponse.data!.createUploadLink.s3FileName,
        subAccountId: subAccount!,
        extraTag: tag,
      },
      onError: () => showErrorMessage('Upload failed'),
    });

    navigate(`/app/operations/upload-transactions/${uploadResult.data!.validateTransactionsUpload.uploadId}`);
  }

  const columns: ColDef<RowData>[] = useMemo(() => {
    const cols: ColDef<RowData>[] = [
      {
        headerName: 'Date',
        field: 'startedAt',
        initialSort: 'desc',
        valueGetter: (params: ValueGetterParams<RowData>): Date | undefined =>
          params.data ? dayjs(params.data.startedAt).toDate() : undefined,
        valueFormatter: (params: ValueFormatterParams<RowData, Date>): string =>
          formatDate(dayjs.utc(params.value), DateTimeFormat.DateTime, timezone),
      },
      {
        headerName: 'File',
        type: 'textColumn',
        field: 'fileName',
      },
      subAccountColumn(),
      {
        headerName: 'Status',
        type: 'textColumn',
        field: 'status',
        valueGetter: (params: ValueGetterParams<RowData>): string => (params.data ? getStatusText(params.data) : ''),
      },
      {
        headerName: 'Rows count',
        type: ['numericColumn', 'extendedNumericColumn'],
        field: 'fileRowsCount',
      },
      {
        headerName: 'Action',
        cellRenderer: (params: ICellRendererParams<RowData>): ReactElement | undefined => {
          if (!params.data) {
            return undefined;
          }
          return (
            <GLink to={`/app/operations/upload-transactions/${params.data.id}`}>
              {params.data.possibleToProceedWithUpload ? 'Proceed with upload' : 'Details'}
            </GLink>
          );
        },
      },
    ];

    if (isAdmin) {
      cols.push({
        field: 'executionTimeSec',
        type: 'numericColumn',
        headerName: 'Execution time (sec)',
      });
    }

    return cols;
  }, [isAdmin, timezone]);

  return (
    <Stack alignItems="stretch" gap={defaultRowSpacing} height="100%" width="100%">
      <Stack direction="row" gap={defaultRowSpacing} alignItems="flex-end">
        <StaticSingleAutocomplete
          label="Sub-account"
          value={subAccount}
          width="normal"
          {...subAccountOptions}
          onChange={(value): void => setSubAccount(value ?? null)}
        />
        <GInput label="Tag" width="normal" value={tag} onChange={(tagInput) => setTag(tagInput ?? '')} />

        <FileUploadInput
          variant="solid"
          onFileSelect={uploadFileForValidation}
          loading={loading}
          disabled={!subAccount}
          width="normal"
        >
          {loading ? 'Uploading...' : 'Upload file'}
        </FileUploadInput>
      </Stack>
      {data.bookkeeping.transactionUpload.uploads.length > 0 && (
        <>
          <Typography level="title-md">Upload history</Typography>
          <GAgGrid
            rowData={data.bookkeeping.transactionUpload.uploads}
            sideBar={{ toolPanels: ['filters'] }}
            enableCharts
            enableRangeSelection
            defaultColDef={defaultColDef}
            autoSizeStrategy={{ type: 'fitGridWidth' }}
            columnDefs={columns}
          />
        </>
      )}
    </Stack>
  );
};

export default UploadTransactions;
