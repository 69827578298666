import dayjs, { type Dayjs } from 'dayjs';
import capitalize from 'lodash/fp/capitalize';
import isNil from 'lodash/fp/isNil';
import { type BigNumber, bignumber } from 'mathjs';
import numbro from 'numbro';
import { setTimezone, type Timezone } from './date.utils';

export type NumberFormat = 'zero' | 'belowOne' | 'upToAMillion' | 'overMillion';
// short is for charts, long is when we need a precise number like in a precise tooltip or portfolio report. Normal is for other cases
export type FormatLength = 'short' | 'normal' | 'long';

export const numberOfZeros = (value: number | string | BigNumber): number => {
  const val = bignumber(value).toNumber();
  const firstImportantDigit = -Math.log10(Math.abs(val));
  return Math.floor(firstImportantDigit);
};

const getOverMillionFormat = (variant: FormatLength = 'normal'): numbro.Format => {
  const commonFormat = {
    mantissa: 2,
    trimMantissa: true,
  };

  if (variant === 'short') {
    return {
      ...commonFormat,
      average: true,
      totalLength: 3,
    };
  }
  if (variant === 'normal') {
    return {
      ...commonFormat,
      average: true,
      totalLength: 5,
    };
  }

  return {
    ...commonFormat,
    thousandSeparated: true,
  };
};
const getUpToMillionFormat = (variant: FormatLength = 'normal'): numbro.Format => {
  const commonFormat = {
    thousandSeparated: true,
    trimMantissa: true,
  };

  if (variant === 'short') {
    return {
      ...commonFormat,
      average: true,
      mantissa: 2,
    };
  }

  return {
    ...commonFormat,
    mantissa: 2,
  };
};

const calculateNumbroNumericFormat = (
  value: number | string | BigNumber,
  variant: FormatLength = 'normal'
): numbro.Format | undefined => {
  const format = calculateNumericFormat(value);
  switch (format) {
    case 'zero':
      return {
        mantissa: 0,
      };
    case 'belowOne': {
      return {
        mantissa: Math.min(5, numberOfZeros(value)) + 4,
        trimMantissa: true,
      };
    }
    case 'upToAMillion':
      return getUpToMillionFormat(variant);
    case 'overMillion':
      return getOverMillionFormat(variant);
    default:
      throw new Error('Unknown format: ' + format);
  }
};

export const calculateNumericFormat = (value: number | string | BigNumber): NumberFormat => {
  const val = bignumber(value).toNumber();
  if (val === 0) {
    return 'zero';
  }

  if (Math.abs(val) < 1) {
    return 'belowOne';
  }

  if (Math.abs(val) < 10 ** 6) {
    return 'upToAMillion';
  }

  return 'overMillion';
};

const capitalizeNumberSuffix = (value: string): string => {
  return value.toUpperCase().replace('INFINITY', 'Infinity');
};

const isValueNaN = (value: string | BigNumber | number): boolean => {
  return bignumber(value).isNaN();
};

const isEmptyValue = (value: string | number | null | undefined | BigNumber): value is null | undefined => {
  return value === undefined || value == null || value === '' || isValueNaN(value);
};

export const formatCash = (
  value: number | string | undefined | null | BigNumber,
  variant: FormatLength = 'normal'
): string => {
  if (isEmptyValue(value)) {
    return '';
  }

  const format = calculateNumbroNumericFormat(value, variant);
  return capitalizeNumberSuffix(
    numbro(value).format({
      ...format,
      output: 'currency',
    })
  );
};

export const formatNumber = (
  value: number | string | undefined | null | BigNumber,
  variant: FormatLength = 'normal'
): string => {
  if (isEmptyValue(value)) {
    return '';
  }

  return capitalizeNumberSuffix(numbro(value).format(calculateNumbroNumericFormat(value, variant)));
};

export const formatPercentage = (value: number | undefined | string | null | BigNumber, mantissa = 2): string => {
  if (isEmptyValue(value)) {
    return '';
  }

  const parsedValue = bignumber(value);
  return capitalizeNumberSuffix(
    numbro(parsedValue.toNumber()).format({
      trimMantissa: true,
      mantissa,
      output: 'percent',
    })
  );
};

export const formatDuration = (value: number | string | undefined | BigNumber): string => {
  if (isEmptyValue(value)) {
    return '';
  }

  return dayjs.duration(bignumber(value).toNumber(), 'milliseconds').humanize();
};

export const normalizeParts = (name: string): string[] => {
  const noWeirdChars = name.replace(/[_-]/g, ' ');
  const strippedLabel = noWeirdChars.substring(noWeirdChars.indexOf(':') + 1);
  return strippedLabel.split('/');
};

// for formatting asset labels to name, use IAsset.name attribute
export const formatLabelToName = (name: Label): string => {
  if (!name) {
    return '';
  }

  const parts = normalizeParts(name);
  return capitalize(parts[0]);
};

export const formatEnum = (value: string | null | undefined): string => {
  if (isNil(value)) {
    return '';
  }
  return capitalize(value.split('_').join(' '));
};

export enum DateTimeFormat {
  Date = 'D MMM YYYY', // 1 Jan 2024
  Time = 'hh:mm:ss A', // 12:00:00 AM
  DateTime = 'MMM D YYYY | hh:mm:ss A',
  ShortDate = 'MM/DD/YY',
  LongDate = 'dddd, D MMM YYYY',
  ShortDateTime = 'MM/DD/YY | hh:mm:ss A',
  FileDateTime = 'YYYY-MM-DDThh_mm_A',
}

export function formatDate(
  date: Dayjs | UtcDate | string | null | undefined,
  format: DateTimeFormat.Date | DateTimeFormat.ShortDate | DateTimeFormat.LongDate
): string;
export function formatDate(date: Dayjs | string | null | undefined, format: DateTimeFormat, timezone: Timezone): string;
export function formatDate(
  date: Dayjs | UtcDate | string | null | undefined,
  format: DateTimeFormat,
  timezone?: Timezone
): string {
  if (isNil(date)) {
    return '';
  }

  let dayjsDate = dayjs.isDayjs(date) ? date : dayjs(date.toString());

  if (timezone) {
    dayjsDate = setTimezone(dayjsDate, timezone);
  }

  return dayjsDate.format(format);
}

const IsoDateFormat = 'YYYY-MM-DD';
export const formatISODate = (date: Dayjs): UtcDate => date.format(IsoDateFormat) as unknown as UtcDate;

export const formatterForName = (
  format: 'cash' | 'number' | 'percentage' | 'duration' | 'percentage_4'
): ((value: string | number | undefined | BigNumber) => string) => {
  switch (format) {
    case 'cash':
      return formatCash;
    case 'percentage':
      return formatPercentage;
    case 'number':
      return formatNumber;
    case 'duration':
      return formatDuration;
    case 'percentage_4':
      return (value) => formatPercentage(value, 4);
    default:
      throw new Error('Unknown format: ' + format);
  }
};
