import type { Dayjs } from 'dayjs';

const isIncrementalInterval = (step: 'year' | 'quarter' | 'month' | 'week'): step is 'week' => {
  return step === 'week';
};

export const range = (startDate: Dayjs, endDate: Dayjs, step: 'year' | 'quarter' | 'month' | 'week'): Dayjs[] => {
  if (!isIncrementalInterval(step)) {
    const dates = [];
    let stepNum = 0;
    while (!startDate.add(stepNum, step).isAfter(endDate)) {
      dates.push(startDate.add(stepNum, step));
      stepNum++;
    }

    return dates;
  }

  const dates = [];
  let lastDate = startDate;
  while (!lastDate.isAfter(endDate)) {
    dates.push(lastDate);
    lastDate = lastDate.add(1, 'week');
  }

  return dates;
};
