import type { IPortfolioRegimeResultQuery } from '../../../../../generated/graphql.tsx';
import type { ReactNode } from 'react';
import groupBy from 'lodash/fp/groupBy';
import { Stack, Typography } from '@mui/joy';
import WarningMessage from '../../../../technical/WarningMessage/WarningMessage.tsx';

export const PortfolioRegimeWarning = ({
  risk,
}: { risk: IPortfolioRegimeResultQuery['portfolio']['regimeRisk'] }): ReactNode => {
  const messagesPerPortfolio = groupBy(
    (msg) => msg.portfolio.id,
    risk.flatMap((r) =>
      r.data
        .map((regimeData) => ({
          message: regimeData.message,
          regime: regimeData.regimeName,
          portfolio: r.portfolio,
        }))
        .filter((row) => !!row.message)
    )
  );

  if (Object.keys(messagesPerPortfolio).length === 0) {
    return null;
  }

  return (
    <Stack rowGap={2}>
      {Object.entries(messagesPerPortfolio).map(([portfolioId, regimeMessage]) => (
        <div key={portfolioId}>
          <Typography level="title-lg" mb={1}>
            {regimeMessage[0].portfolio.name}
          </Typography>
          <WarningMessage>{regimeMessage.map((regimeMessage) => regimeMessage.message).join('. ')}</WarningMessage>
        </div>
      ))}
    </Stack>
  );
};
