import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { type ReactElement, type ReactNode, useMemo } from 'react';

import { createMaterialUiTheme } from './theme/materialUiTheme';
import { useFinalColorScheme } from './useFinalColorScheme';

const MaterialTheme = ({ children }: { children: ReactNode }): ReactElement => {
  const mode = useFinalColorScheme();
  const theme = useMemo(() => createMaterialUiTheme(mode), [mode]);

  const materialTheme = materialExtendTheme(theme);

  return <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>{children}</MaterialCssVarsProvider>;
};

export default MaterialTheme;
