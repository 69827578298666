/* eslint-disable no-console */
import { ApolloClient, ApolloError, ApolloLink, from, HttpLink, InMemoryCache, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Auth } from 'aws-amplify';
import { v4 } from 'uuid';
import { GraphQLError } from 'graphql';

import { getGroupsFromAccessToken } from './AuthService.ts';
import { getSavedImpersonatedUserId } from './AuthStorage';
import { getEnvConfig } from './config';
import { genieUser } from './groupGuards';
import { requestIdHeader } from './setup/headers.ts';

const envConfig = getEnvConfig();
const apiUrl = `${envConfig.apiHostnameAndPort}/graphql`;

console.debug('Using graphqlApi: ', apiUrl);
const api = new HttpLink({
  uri: apiUrl,
});

const stripTypename = <T>(key: string, value: T): undefined | T => {
  return key === '__typename' ? undefined : value;
};

const stripTypenameFromVariables = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), stripTypename);
  }

  return forward(operation);
});

const createBackendError = (): ApolloError => {
  return new ApolloError({
    graphQLErrors: [
      new GraphQLError('Simulated graphql error', null, null, null, null, null, {
        code: 'simulated error code',
        message: 'simulated error message',
      }),
    ],
  });
};

// test apollo link to simulate backend errors for a single operation
const simulateBackendErrors = new ApolloLink((operation, forward) => {
  if (operation.operationName === 'testError') {
    return new Observable((observer) => {
      observer.error(createBackendError());
    });
  }

  return forward(operation);
});

const stringifyError = (error: Omit<Error, 'name'>): unknown => {
  try {
    return JSON.stringify(error);
  } catch {
    return error;
  }
};

const logBackendErrorsLink = onError(({ graphQLErrors, networkError }) => {
  for (const graphQLError of graphQLErrors ?? []) {
    console.error('GraphQL error', stringifyError(graphQLError));
  }

  if (networkError && networkError.name !== 'AbortError') {
    console.error('GraphQL network error', stringifyError(networkError));
  }
});

const logoutLink = onError(({ graphQLErrors }) => {
  if (!graphQLErrors) {
    return;
  }

  for (const error of graphQLErrors) {
    if (error.extensions?.code === 'not_authorized') {
      console.log('Signing user out after error from backend');
      Auth.signOut().then(
        () => {
          console.log('SignOut after backend error successful');
        },
        () => {
          console.error('Failed to sign user out after backend error');
        }
      );
    }
  }
});

const authentication = setContext(async (_req, { headers }) => {
  try {
    const tokens = await Auth.currentSession();
    const token = tokens.getAccessToken();
    const groups = getGroupsFromAccessToken(token);
    let impersonationId: null | string = null;
    if (genieUser(groups)) {
      impersonationId = getSavedImpersonatedUserId();
    }

    const extraHeaders: Record<string, string> = {
      authorization: token ? `Bearer ${token.getJwtToken()}` : '',
    };

    if (token) {
      // send request id only for logged in users
      extraHeaders[requestIdHeader] = v4();
    }

    if (impersonationId && token.decodePayload()?.sub !== impersonationId) {
      extraHeaders['x-impersonated'] = impersonationId;
    }

    return {
      headers: {
        ...headers,
        ...extraHeaders,
      },
    };
  } catch (e) {
    console.error('Error from cognito', e);
    throw new Error('Cognito error');
  }
});

export default new ApolloClient({
  link: from([
    authentication,
    logBackendErrorsLink,
    logoutLink,
    stripTypenameFromVariables,
    simulateBackendErrors,
    api,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Assets: {
        // singleton type
        keyFields: [],
        merge: true,
      },
      AssetDetails: {
        keyFields: false,
        merge: true,
      },
      AssetGroups: {
        keyFields: [],
      },
      Articles: {
        keyFields: [],
        merge: true,
      },
      Article: {
        merge: true,
      },
      ArticleToArticleLink: {
        keyFields: false,
      },
      DerivativeDetails: {
        keyFields: false,
        merge: true,
      },
      Bookkeeping: {
        keyFields: [],
      },
      Investments: {
        keyFields: [],
      },
      Calendar: {
        keyFields: [],
      },
      FactorRouterType: {
        keyFields: [],
      },
      FactorRouterTypeV2: {
        keyFields: [],
      },
      ScenarioAnalysisV2: {
        keyFields: [],
      },
      Management: {
        keyFields: [],
      },
      Notification: {
        keyFields: [],
      },
      PortfolioTimeWeightedReturnsType: {
        keyFields: false,
      },
      TimeWeightedReturnType: {
        keyFields: false,
      },
      WeightedValueType: {
        keyFields: false,
        merge: true,
      },
      Portfolio: {
        keyFields: [],
        merge: true,
      },
      Journal: {
        keyFields: [],
      },
      PortfolioOptimizationResult: {
        merge: true,
      },
      PortfolioOptimization: {
        keyFields: [],
      },
      FundOptimization: {
        keyFields: [],
      },
      PortfolioDefinition: {
        merge: true,
      },
      FundOptimizationResult: {
        merge: true,
      },
      StressTestType: {
        keyFields: [],
      },
      Reconciliation: {
        keyFields: [],
      },
      RegimeType: {
        keyFields: [],
      },
      ReconciliationReportRow: {
        // prevents cache from computing an id. used when object is not a singleton, but we want to cache it as part of a parent
        keyFields: false,
      },
      NotCoveredJournalTransaction: {
        keyFields: false,
      },
      Strategy: {
        keyFields: false,
      },
      StrategyType: {
        keyFields: [],
      },
      NewsType: {
        keyFields: [],
      },
      AssetBulletPoints: {
        keyFields: [],
      },
      NavigatorGraph: {
        keyFields: [],
      },
      NewsFeed: {
        merge: true,
      },
      SubAccountPositions: {
        keyFields: false,
        merge: true,
      },
      MultiFactorQueryType: {
        keyFields: [],
        merge: true,
      },
      Agents: {
        keyFields: [],
        merge: true,
      },
      Conversation: {
        merge: true,
      },
    },
  }),
  devtools: {
    enabled: envConfig.apolloDevTools,
  },
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
    mutate: {
      fetchPolicy: 'network-only',
    },
  },
});
