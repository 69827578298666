import { useEffect, useRef, useState } from 'react';

// implementation of useThrottle hook from https://github.com/streamich/react-use/issues/2488 is buggy
export const useThrottle = <V>(value: V, limitMs: number): V => {
  const [throttledValue, setThrottledValue] = useState(value);
  const lastRan = useRef(Date.now());

  useEffect(() => {
    const handler = setTimeout(
      () => {
        if (Date.now() - lastRan.current >= limitMs) {
          setThrottledValue(value);
          lastRan.current = Date.now();
        }
      },
      limitMs - (Date.now() - lastRan.current)
    );

    return () => {
      clearTimeout(handler);
    };
  }, [value, limitMs]);

  return throttledValue;
};
