import { Stack } from '@mui/joy';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';
import { type Dispatch, type FunctionComponent, type ReactElement, type SetStateAction, useMemo } from 'react';
import SeeMoreDropdown from 'components/technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import ConfirmationDialog from 'components/technical/form/dialog/ConfirmationDialog';
import GTable from 'components/technical/GTable/GTable';

import {
  type ISubFundsQuery,
  SubFundsDocument,
  useDeleteSubFundMutation,
  useUpdateSubFundMutation,
} from 'generated/graphql';
import ChangeSubFundAccountsDialog from './ChangeSubFundAccountsDialog.tsx';
import { AccountLabel } from '../account/AccountLabel.tsx';
import { DateTimeFormat, formatDate } from 'components/formatter.utils.ts';
import CreateRenameSubFundDialog from './CreateRenameSubFundDialog.tsx';
import { useUserTimezone } from 'components/technical/UseUserTimezone.tsx';
import type { SubFund } from './SubFundsDashboard.tsx';
import { IconVariant } from 'components/market/asset/cryptocurrencies/CryptocurrenciesData.tsx';
import { sortBy } from 'lodash/fp';
import { DialogMenuItem } from '../../technical/DialogDropdown/DialogMenuItem.tsx';

const columnHelper = createColumnHelper<SubFund>();

type DimensionSubFundsTableProps = {
  dimensionSubFunds: SubFund[];
  existingSubFunds: Set<string>;
  accounts: ISubFundsQuery['portfolio']['accounts'];
  setSelectedDimension: Dispatch<SetStateAction<string | null>>;
  dimensions: string[];
};

const dateCellStyles = {
  headerStyles: {
    width: '180px',
  },
};

const DimensionSubFundsTable: FunctionComponent<DimensionSubFundsTableProps> = ({
  dimensionSubFunds,
  existingSubFunds,
  accounts,
  dimensions,
  setSelectedDimension,
}) => {
  const refetchFunds = { refetchQueries: [SubFundsDocument] };
  const [deleteSubFund] = useDeleteSubFundMutation(refetchFunds);
  const [updateSubFund] = useUpdateSubFundMutation(refetchFunds);

  const { showGraphqlError, showSuccessMessage } = useFeedback();

  const timezone = useUserTimezone();

  // memo - cell is as a new component each rerender, it loses dialog state, rerender can be caused by api error
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Sub-fund name',
        meta: {
          headerStyles: {
            minWidth: '140px',
          },
        },
      }),
      columnHelper.display({
        header: 'Accounts',
        cell: (props: CellContext<SubFund, unknown>) => {
          const accounts = sortBy((a) => a.name, props.row.original.accounts);
          return (
            <Stack spacing={1} flexWrap={'wrap'} direction={'row'}>
              {accounts.map((account) => (
                <AccountLabel account={account} key={account.id} size={IconVariant.MEDIUM} />
              ))}
            </Stack>
          );
        },
      }),
      columnHelper.accessor('createdAt', {
        header: 'Added on',
        meta: dateCellStyles,
        cell: (cellProps: CellContext<SubFund, string>) =>
          formatDate(cellProps.getValue(), DateTimeFormat.DateTime, timezone),
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Last updated',
        meta: dateCellStyles,
        cell: (cellProps: CellContext<SubFund, string | undefined>) =>
          formatDate(cellProps.getValue(), DateTimeFormat.DateTime, timezone),
      }),
      columnHelper.display({
        header: 'Actions',
        meta: {
          headerStyles: {
            width: '100px',
          },
          align: 'center',
        },
        cell: (props: CellContext<SubFund, unknown>) => {
          const subFundRow = props.row.original;
          if (subFundRow.isDefault) {
            return null;
          }

          return (
            <Stack justifyContent="center" spacing={1.5}>
              <SeeMoreDropdown>
                <DialogMenuItem
                  renderDialog={({ onClose }): ReactElement => (
                    <CreateRenameSubFundDialog
                      onClose={onClose}
                      title={`Rename "${subFundRow.name}"`}
                      submitButtonText="Rename"
                      existingSubFunds={existingSubFunds}
                      initialState={{ name: subFundRow.name }}
                      handleFormSubmit={async (subFundForm, onErrorAndThrow): Promise<void> => {
                        try {
                          await updateSubFund({
                            variables: {
                              input: {
                                oldName: subFundRow.name,
                                newName: subFundForm.name,
                                dimension: subFundRow.dimension,
                                accounts: subFundRow.accounts.map((account) => account.id),
                                subAccounts: [],
                              },
                            },
                          });
                        } catch (e) {
                          onErrorAndThrow(e);
                        }

                        onClose();
                        showSuccessMessage('Sub-fund successfully renamed');
                      }}
                    />
                  )}
                >
                  Rename
                </DialogMenuItem>
                <DialogMenuItem
                  renderDialog={({ onClose }): ReactElement => (
                    <ChangeSubFundAccountsDialog
                      onClose={onClose}
                      accounts={accounts.filter(
                        (account) =>
                          // exclude accounts that are already belong  to other sub-funds in the same dimension
                          !dimensionSubFunds.some(
                            (subFund) =>
                              subFund !== subFundRow &&
                              !subFund.isDefault &&
                              subFund.accounts.some((acc) => acc.id === account.id)
                          )
                      )}
                      initialState={subFundRow}
                      handleFormSubmit={async (updatesSubFund, onErrorAndThrow): Promise<void> => {
                        try {
                          await updateSubFund({
                            variables: {
                              input: {
                                oldName: subFundRow.name,
                                newName: subFundRow.name,
                                dimension: subFundRow.dimension,
                                accounts: updatesSubFund.accounts.map((account) => account.id),
                                subAccounts: [],
                              },
                            },
                          });
                        } catch (e) {
                          onErrorAndThrow(e);
                        }

                        showSuccessMessage('Sub-fund successfully updated');
                        onClose();
                      }}
                    />
                  )}
                >
                  Edit
                </DialogMenuItem>
                <DialogMenuItem
                  renderDialog={({ onClose }): ReactElement => (
                    <ConfirmationDialog
                      onClose={onClose}
                      onApprove={async (): Promise<void> => {
                        try {
                          await deleteSubFund({
                            variables: {
                              subFund: subFundRow.name,
                            },
                          });
                        } catch (e) {
                          close();
                          showGraphqlError(e);
                          return;
                        }

                        if (dimensionSubFunds.length === 1) {
                          setSelectedDimension(
                            dimensions.find((dimension) => dimension !== subFundRow.dimension) ?? null
                          );
                        }

                        showSuccessMessage(`Fund ${subFundRow.name} successfully deleted`);
                        close();
                      }}
                    >
                      {dimensionSubFunds.length === 1
                        ? `Are you sure you want to remove sub-fund "${subFundRow.name}"? Removing the last sub-fund will also delete its associated dimension.`
                        : `Are you sure you want to remove sub-fund "${subFundRow.name}"?`}
                    </ConfirmationDialog>
                  )}
                >
                  Remove
                </DialogMenuItem>
              </SeeMoreDropdown>
            </Stack>
          );
        },
      }),
    ],
    [
      accounts,
      deleteSubFund,
      dimensionSubFunds,
      existingSubFunds,
      showSuccessMessage,
      showGraphqlError,
      timezone,
      updateSubFund,
      setSelectedDimension,
      dimensions,
    ]
  );

  const sorted = sortBy('name', dimensionSubFunds);
  return <GTable<SubFund> fullHeight columns={columns} data={sorted} disablePagination />;
};

export default DimensionSubFundsTable;
