import capitalize from 'lodash/fp/capitalize';
import isNil from 'lodash/fp/isNil';
import uniq from 'lodash/fp/uniq';

import { formatLabelToName } from '../../formatter.utils';
import { type AssetCategoryInput, getAssetCategory, getUnderlyingAsset } from '../../market/asset/AssetService';
import { createAssetToClusters } from '../../market/asset/groups/GroupService.ts';
import { venues } from '../../venue/VenueData.tsx';
import { calculateLongShort } from '../../technical/grids/SharedReportColumns.tsx';

const subAccountCategory = 'wallet';
const venueCategory = 'exchange';
const assetTypeCategory = 'assetType';
export const longShortCategory = 'longShort';
export const categoryCategory = 'category';
const sectorCategory = 'sector';
const underlyingAssetCategory = 'underlyingAsset';
const assetCategory = 'asset';

const calculateCustomGroupCategoryName = (clusterName: string): string => {
  return `custom-${clusterName}`;
};

export interface AggregationValue {
  id: string;
  label: string;
}

export interface Aggregation<TPosition> {
  label: string;
  category: string;
  calculateValue: (position: TPosition) => AggregationValue;
}

export const NOT_DEFINED = 'Not defined';
const formatGenieCategory = (value: string | undefined): string => {
  if (isNil(value)) {
    return NOT_DEFINED;
  }

  return formatLabelToName(value);
};

export interface GroupWithAssetId {
  clusterName: string;
  groupName: string;
  assets: {
    id: string;
  }[];
}

export const subAccountAggregation = {
  label: 'Sub-account',
  category: subAccountCategory,
  calculateValue: (pos: { subAccount: { id: string; name: string } }): AggregationValue => ({
    id: pos.subAccount.id,
    label: pos.subAccount.name,
  }),
} satisfies Aggregation<{ subAccount: { id: string; name: string } }>;

export const venueAggregation = {
  label: 'Venue',
  category: venueCategory,
  calculateValue: (pos: { subAccount: { account: { venue: { label: string } } } }): AggregationValue => {
    const label = pos.subAccount.account.venue.label;
    return {
      id: label,
      label: venues[label]?.name,
    };
  },
};

export const assetTypeAggregation = {
  label: 'Asset type',
  category: assetTypeCategory,
  calculateValue: (pos: { asset: AssetCategoryInput }): AggregationValue => {
    const cat = getAssetCategory(pos.asset);

    return {
      id: cat,
      label: cat,
    };
  },
};

export const longShortAggregation = {
  label: 'Long/short',
  category: longShortCategory,
  calculateValue: (pos: {
    spot?: { amount: string } | null;
    derivative?: { side: string } | null;
  }): AggregationValue => {
    const longShort = calculateLongShort(pos);
    return {
      id: longShort,
      label: longShort,
    };
  },
};

export const underlyingAssetAggregation = {
  label: 'Underlying asset',
  category: underlyingAssetCategory,
  calculateValue: (pos: {
    asset: {
      id: string;
      symbol: string;
      derivativeDetails?:
        | {
            baseAsset: { symbol: string; id: string };
          }
        | null
        | undefined;
    };
  }): AggregationValue => {
    return {
      id: pos.asset.derivativeDetails?.baseAsset.id ?? pos.asset.id,
      label: pos.asset.derivativeDetails?.baseAsset.symbol ?? pos.asset.symbol,
    };
  },
};

export const assetAggregation = {
  label: 'Asset',
  category: assetCategory,
  calculateValue: (pos: {
    asset: {
      symbol: string;
      id: string;
    };
  }): AggregationValue => {
    return {
      id: pos.asset.id,
      label: pos.asset.symbol,
    };
  },
};

type AssetPositionOnlyId = {
  asset: { id: string; derivativeDetails?: { baseAsset: { id: string } } | null | undefined };
};

export const categoryAggregation = (
  category: typeof categoryCategory | typeof sectorCategory,
  assetToGenieGroups: Record<string, Record<string, string>>
): Aggregation<AssetPositionOnlyId> => ({
  label: capitalize(category),
  category: category,
  calculateValue: (pos: AssetPositionOnlyId): AggregationValue => {
    const categoryName = assetToGenieGroups[getUnderlyingAsset(pos.asset).id]?.[category] ?? NOT_DEFINED;
    return {
      id: categoryName,
      label: formatGenieCategory(categoryName),
    };
  },
});

export const getGenieGroupAggregations = <TPosition extends AssetPositionOnlyId>(
  genieGroups: GroupWithAssetId[]
): Aggregation<TPosition>[] => {
  const assetToGenieGroups = createAssetToClusters(genieGroups);
  return [
    categoryAggregation(categoryCategory, assetToGenieGroups),
    categoryAggregation(sectorCategory, assetToGenieGroups),
  ];
};

export const getUserGroupAggregations = <TPosition extends AssetPositionOnlyId>(
  userGroups: GroupWithAssetId[]
): Aggregation<TPosition>[] => {
  const assetToUserGroups = createAssetToClusters(userGroups);
  return uniq(userGroups.map((group) => group.clusterName)).map((cluster) => ({
    label: cluster,
    category: calculateCustomGroupCategoryName(cluster),
    calculateValue: (pos: TPosition): AggregationValue => {
      const userGroup = assetToUserGroups[getUnderlyingAsset(pos.asset).id]?.[cluster] ?? NOT_DEFINED;

      return {
        id: userGroup,
        label: userGroup,
      };
    },
  }));
};
