import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoutesFromChildren, useLocation, useNavigationType } from 'react-router';
import { matchRoutes } from 'react-router-dom';

import { sentryDsn, getEnvConfig } from '../config.ts';
import isNil from 'lodash/fp/isNil';
import { requestIdHeader } from './headers.ts';

export const REPLAY_UNMASK_CLASS_NAME = 'genie-unmask';

const envConfig = getEnvConfig();
Sentry.init({
  release: import.meta.env.VITE_APP_VERSION,
  enabled: envConfig.sentryEnabled,
  dsn: sentryDsn,
  environment: envConfig.sentryEnvironment,
  beforeBreadcrumb(breadcrumb, hint) {
    try {
      if (breadcrumb.category === 'xhr') {
        const url = breadcrumb.data?.url as string | undefined;
        // dont get clarity and termly requests
        if (!isNil(url) && url.match(/clarity.ms|app.termly.io/)) {
          return null;
        }
      }

      if (!hint) {
        return breadcrumb;
      }

      if (breadcrumb.type !== 'http') {
        return breadcrumb;
      }

      const input = hint.input;
      if (!input) {
        return breadcrumb;
      }

      if (!Array.isArray(input)) {
        return breadcrumb;
      }

      // fetch api has first param - url and the second options
      const options = input.at(1) as RequestInit | undefined;
      if (isNil(options?.headers)) {
        return breadcrumb;
      }

      const headers = options.headers;
      const requestIdValue = new Headers(headers).get(requestIdHeader);
      return {
        ...breadcrumb,
        data: {
          ...breadcrumb.data,
          [requestIdHeader]: requestIdValue,
        },
      };
    } catch (e) {
      console.error('Failed to process breadcrumb', e);
      // if anything crashes, we want to still get a breadcrumb
      return breadcrumb;
    }
  },
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      unmask: [
        '.sentry-unmask',
        '[data-sentry-unmask]',
        '.ag-tool-panel-wrapper',
        '.ag-header-viewport',
        '[role=menu]',
        '[role=listbox]',
        'label',
        'button',
        `.${REPLAY_UNMASK_CLASS_NAME}`,
      ],
      networkRequestHeaders: [requestIdHeader],
      networkResponseHeaders: ['apollo-trace-id'],
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [envConfig.apiHostnameAndPort],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
