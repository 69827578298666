import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box, Button, Chip, IconButton, List, ListItem, ListItemButton, Stack, Typography } from '@mui/joy';
import { Tooltip } from '@mui/joy';
import type { FunctionComponent } from 'react';
import { useAuth } from 'UseAuth.tsx';
import { transitionDuration } from 'theme/consts.ts';

import type { ReportPreset } from './GAgGridPresets.types.ts';
import DeleteDialogButton from '../inputs/GButton/DeleteDialogButton.tsx';

export type ReportPresetsDrawerProps = {
  presets: ReportPreset[];
  favorites: string[];
  toggleFavorite: (presetName: string) => Promise<void>;
  onDeletePreset: (preset: ReportPreset) => Promise<void>;
  setCurrentPreset: (preset: ReportPreset) => void;
  currentPreset: ReportPreset;
  copyCurrentStateAsJson: () => void;
};

const ReportPresetsDrawer: FunctionComponent<ReportPresetsDrawerProps> = ({
  presets,
  favorites,
  toggleFavorite,
  onDeletePreset,
  setCurrentPreset,
  currentPreset,
  copyCurrentStateAsJson,
}) => {
  const { isAdmin } = useAuth();

  return (
    <Stack spacing={1.5} height="100%">
      <Typography level="body-sm">
        Select your favorite presets to easily access them from the panel above the report.
      </Typography>
      <List sx={{ flex: 1 }}>
        {presets.map((preset) => {
          const isFavorite = favorites.includes(preset.name);
          return (
            <ListItem
              key={preset.name}
              sx={{
                '&:hover .reportPresetsDeleteButton': {
                  opacity: 1,
                },
              }}
              startAction={
                <IconButton size="sm" onClick={(): Promise<void> => toggleFavorite(preset.name)} variant="plain">
                  {isFavorite ? <StarIcon fontSize="small" /> : <StarBorderIcon fontSize="small" />}
                </IconButton>
              }
              endAction={
                !preset.isDefault && (
                  <Box
                    className="reportPresetsDeleteButton"
                    sx={{
                      opacity: 0,
                      marginLeft: 'auto',
                      transition: `opacity ${transitionDuration.Standard}`,
                    }}
                  >
                    <DeleteDialogButton
                      length="short"
                      variant="plain"
                      deletedMessage="Preset successfully deleted"
                      confirmationMessage="Are you sure you want to remove this preset?"
                      deleteItem={(): Promise<unknown> => onDeletePreset(preset)}
                    />
                  </Box>
                )
              }
            >
              <ListItemButton
                selected={preset.name === currentPreset.name}
                variant="plain"
                onClick={(): void => setCurrentPreset(preset)}
              >
                {preset.name}
                {preset.isDefault && (
                  <Chip sx={{ marginLeft: 1 }} size="sm" variant="plain" color="primary">
                    Default
                  </Chip>
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      {isAdmin && (
        <Tooltip title="For dev use: generate default preset JSON from current report state">
          <Button size="sm" variant="plain" onClick={copyCurrentStateAsJson}>
            Copy current report state as JSON*
          </Button>
        </Tooltip>
      )}
    </Stack>
  );
};

export default ReportPresetsDrawer;
