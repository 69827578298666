import { Chip, Stack } from '@mui/joy';
import { type ReactElement, type ReactNode, useState } from 'react';
import { useAgentListSuspenseQuery } from '../../../../generated/graphql.tsx';
import ConversationView from './ConversationView.tsx';
import isNil from 'lodash/fp/isNil';
import { SelectAgent } from './SelectAgent.tsx';

const ConversationTab = ({
  conversationId,
  onConversationStarted,
}: { conversationId?: string; onConversationStarted: (convId: string) => void }): ReactElement => {
  const query = useAgentListSuspenseQuery();
  const agents = query.data.news.agents.agents.map((ag) => ({
    ...ag,
    name: (
      <Stack direction={'row'} gap={0.5}>
        {ag.name}
        <Chip color="success" size={'sm'}>
          {'Beta'}
        </Chip>
      </Stack>
    ),
  }));
  const [agent, setAgent] = useState<{ id: string; name: ReactNode } | null>(
    agents.length > 1 ? null : agents.at(0) ?? null
  );

  if (!conversationId && isNil(agent)) {
    return (
      <Stack justifyContent={'center'} height={'100%'}>
        <SelectAgent
          agents={agents}
          onSelected={(agent) => {
            setAgent(agent);
          }}
        />
      </Stack>
    );
  }

  return (
    <ConversationView
      conversationId={conversationId}
      agent={agent}
      agents={agents}
      onConversationStarted={onConversationStarted}
      onAgentSelected={(ag) => setAgent(ag)}
    />
  );
};

export default ConversationTab;
