import dayjs, { type Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';
import {
  type FunctionComponent,
  type InputHTMLAttributes,
  type PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import DatePicker from 'react-datepicker';

import { convertDateInUtcToLocalWithoutOffset, convertLocalDateToUTCWithoutOffset } from 'components/date.utils';
import CalendarPredefinedRangesList from './CalendarPredefinedRangesList';
import CalendarWrapper from './CalendarWrapper';
import type { PredefinedRange } from '../../../predefinedDateRanges';
import { createContext } from 'react';
import { ensureDateRangeWithinBounds } from './dateRange.utils';
import { Typography } from '@mui/joy';

type DateRangeContextType = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  onConfirm: (val: [Dayjs, Dayjs] | null) => void;
  onCancel: () => void;
  minDate: Dayjs | undefined | null;
  maxDate: Dayjs | undefined | null;
  predefinedRanges?: PredefinedRange[];
  showDaysSelected?: boolean;
  setDateRange: (val: [Dayjs | null, Dayjs | null]) => void;
};

const defaultDateRangeContext: DateRangeContextType = {
  startDate: null,
  endDate: null,
  onConfirm: () => {
    // pass
  },
  onCancel: () => {
    // pass
  },
  minDate: undefined,
  maxDate: undefined,
  setDateRange: () => {
    // pass
  },
};

const DateRangeContext = createContext<DateRangeContextType>(defaultDateRangeContext);

const DatePickerContainer: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const {
    startDate,
    endDate,
    onConfirm,
    onCancel,
    minDate,
    maxDate,
    predefinedRanges,
    showDaysSelected,
    setDateRange,
  } = useContext(DateRangeContext);

  const selectedDaysCount = startDate && endDate ? endDate.diff(startDate, 'day') : undefined;

  return (
    <CalendarWrapper
      footerMessage={
        showDaysSelected && !isNil(selectedDaysCount) ? (
          <Typography>Days selected: {selectedDaysCount}</Typography>
        ) : undefined
      }
      confirmationDisabled={!isNil(startDate) && isNil(endDate)}
      onConfirm={() => {
        if (isNil(startDate) || isNil(endDate)) {
          onConfirm(null);
        } else {
          onConfirm(ensureDateRangeWithinBounds(minDate, maxDate, [startDate, endDate]));
        }
      }}
      onCancel={onCancel}
      predefinedRangesSelector={
        predefinedRanges ? (
          <CalendarPredefinedRangesList
            predefinedRanges={predefinedRanges}
            maxDate={maxDate ?? undefined}
            minDate={minDate ?? undefined}
            setDateRange={setDateRange}
          />
        ) : undefined
      }
    >
      {children}
    </CalendarWrapper>
  );
};

type DateRangeInputCalendarProps = {
  defaultValue: [Dayjs, Dayjs] | null;
  onConfirm: (val: [Dayjs, Dayjs] | null) => void;
  onCancel: () => void;
  minDate?: Dayjs | null;
  maxDate?: Dayjs | null;
  predefinedRanges?: PredefinedRange[];
  showDaysSelected?: boolean;
  disabled?: boolean;
} & Pick<InputHTMLAttributes<HTMLInputElement>, 'onFocus' | 'onBlur'>;

const calculateMinMaxDate = (value: null | [Dayjs, Dayjs]): [Dayjs | null, Dayjs | null] => {
  if (isNil(value)) {
    return [null, null];
  }
  return value;
};

/* 
we try to keep all dates in utc, but DatePicker input/output dates are local, we need to convert it to utc 
and we need to convert component inputs maxDate, minDate, startDate, endDate from utc to local for DatePicker
*/
const DateRangeInputCalendar: FunctionComponent<DateRangeInputCalendarProps> = ({
  defaultValue,
  onConfirm,
  onCancel,
  minDate,
  maxDate,
  predefinedRanges,
  showDaysSelected,
  disabled,
}) => {
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>(calculateMinMaxDate(defaultValue));

  useEffect(() => {
    setDateRange(calculateMinMaxDate(defaultValue));
  }, [defaultValue]);

  const [startDate, endDate] = dateRange ?? [];
  const finalStartDate =
    !isNil(startDate) && startDate.isValid() ? convertDateInUtcToLocalWithoutOffset(startDate).toDate() : undefined;
  return (
    // purpose of context is communication with DatePickerContainer
    <DateRangeContext.Provider
      value={{
        startDate,
        endDate,
        onConfirm,
        onCancel,
        minDate,
        maxDate,
        predefinedRanges,
        showDaysSelected,
        setDateRange,
      }}
    >
      <DatePicker
        disabledKeyboardNavigation
        minDate={convertDateInUtcToLocalWithoutOffset(minDate?.utc())?.toDate()}
        maxDate={convertDateInUtcToLocalWithoutOffset(maxDate?.utc())?.toDate()}
        startDate={finalStartDate}
        endDate={
          !isNil(endDate) && endDate.isValid() ? convertDateInUtcToLocalWithoutOffset(endDate).toDate() : undefined
        }
        key={finalStartDate?.toISOString()} // use key to reset calendar in case someone clicks predefined ranges - this way calendar will switch to start date
        disabled={disabled}
        selectsRange
        inline
        onChange={([newStartDateLocal, newEndDateLocal]): void => {
          console.log('on change');
          // to cover most of the cases take start of the start day and end of the end day
          // and it doesn't change anything in case when we send only dates without time
          const start = newStartDateLocal
            ? convertLocalDateToUTCWithoutOffset(dayjs(newStartDateLocal)).startOf('day')
            : null;
          const end = newEndDateLocal ? convertLocalDateToUTCWithoutOffset(dayjs(newEndDateLocal)).endOf('day') : null;
          setDateRange([start, end]);
        }}
        calendarContainer={DatePickerContainer}
      />
    </DateRangeContext.Provider>
  );
};

export default DateRangeInputCalendar;
