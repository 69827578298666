import React, { type FunctionComponent, type PropsWithChildren, useCallback, useContext } from 'react';
import { useLocalStorage } from 'react-use';
import { AuthnContext, type AuthnState } from './Authn.tsx';
import { isGenieUser, isImpersonating } from './AuthService.ts';

export type AuthzState = {
  hasAccess: (group: string) => boolean;
  isImpersonatingAdmin: boolean;
  setIsImpersonatingAdmin: (val: boolean) => void;
  isAdmin: boolean;
};

export const AuthzContext = React.createContext<AuthzState>({
  hasAccess: () => {
    throw new Error('Not initialized');
  },
  isAdmin: false,
  isImpersonatingAdmin: false,
  setIsImpersonatingAdmin: () => {
    throw new Error('Not initialized');
  },
});

const IS_IMPERSONATING_ADMIN_KEY = 'genieIsImpersonatingAdmin';

const isAdmin = (authnState: AuthnState, parsedIsImpersonatingAdmin: boolean): boolean => {
  return isGenieUser(authnState) || (isImpersonating(authnState) && parsedIsImpersonatingAdmin);
};

export const AuthzProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const authnState = useContext(AuthnContext);
  const [isImpersonatingAdmin, setIsImpersonatingAdmin] = useLocalStorage(IS_IMPERSONATING_ADMIN_KEY, 'false', {
    raw: true,
  });

  const parsedIsImpersonatingAdmin = isImpersonatingAdmin === 'true';
  const hasAccess = useCallback(
    (group: string): boolean => {
      if (!authnState.initialized) {
        return false;
      }

      if (!authnState.user) {
        return false;
      }

      if (isAdmin(authnState, parsedIsImpersonatingAdmin)) {
        return true;
      }

      return authnState.user?.groups.includes(group);
    },
    [authnState, parsedIsImpersonatingAdmin]
  );

  const admin = isAdmin(authnState, parsedIsImpersonatingAdmin);

  return (
    <AuthzContext.Provider
      value={{
        hasAccess,
        isAdmin: admin,
        isImpersonatingAdmin: parsedIsImpersonatingAdmin,
        setIsImpersonatingAdmin: (val: boolean) => {
          setIsImpersonatingAdmin(val.toString());
        },
      }}
    >
      {children}
    </AuthzContext.Provider>
  );
};
