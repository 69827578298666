import { Drawer } from '@mui/joy';
import isNil from 'lodash/fp/isNil';
import { type FunctionComponent, type PropsWithChildren, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { drawerContent } from './Drawer.constants';
import type { DrawerType } from './Drawer.types';
import { Context } from './DrawerContext.tsx';
import { REPLAY_UNMASK_CLASS_NAME } from 'setup/initializeAll.ts';
import { useLocation } from 'react-router';

const DrawerContainer: FunctionComponent<PropsWithChildren<object>> = ({ children }: PropsWithChildren<object>) => {
  const [drawerState, setDrawerState] = useState<{
    type: DrawerType | null;
    extraProps: Record<string, unknown>;
    open: boolean;
    openKey: string;
  }>({
    extraProps: {},
    type: null,
    open: false,
    openKey: v4(),
  });
  const location = useLocation();
  const { type } = drawerState;
  const drawerConfig = !isNil(type) ? drawerContent[type] : null;
  const persistentDrawer = drawerConfig?.persistent ?? false;

  useEffect(() => {
    if (!type) {
      return;
    }

    const shouldHideDrawer = drawerContent[type].shouldHideDrawer;
    if (shouldHideDrawer?.(location)) {
      setDrawerState((state) => ({
        ...state,
        open: false,
      }));
    }
  }, [type, location]);

  const Component = drawerState.type ? drawerContent[drawerState.type]?.children : null;
  return (
    <Context.Provider
      value={{
        setDrawer<Drawer extends DrawerType>(
          drawer: Drawer | null,
          openKey?: string,
          extraProps?: Omit<(typeof drawerContent)[Drawer]['children'], 'open'>
        ): void {
          if (drawer) {
            setDrawerState({
              open: true,
              type: drawer,
              extraProps: extraProps ?? {},
              openKey: openKey ?? v4(),
            });
          } else {
            setDrawerState((state) => ({
              ...state,
              open: false,
            }));
          }
        },
        drawer: drawerState.open ? drawerState.type : null,
        openKey: drawerState.openKey,
      }}
    >
      <Drawer
        anchor="right"
        open={drawerState.open}
        onClose={(): void =>
          setDrawerState((state) => ({
            ...state,
            open: false,
          }))
        }
        className={REPLAY_UNMASK_CLASS_NAME}
        // @todo: move to theme when joy ui offers it
        slotProps={{
          content: {
            // we don't want the modal to resize based on resolution
            sx: [{ width: 'unset' }, drawerConfig?.contentSx ?? false],
          },
          root: {
            sx: {
              // we want to let user interact with the rest of the page when drawer is open
              left: 'unset',
            },
          },
        }}
        hideBackdrop={persistentDrawer}
        disableEscapeKeyDown={persistentDrawer}
        disableEnforceFocus={persistentDrawer}
        disableScrollLock={persistentDrawer}
        disableAutoFocus={persistentDrawer}
      >
        {Component && <Component open={drawerState.open} {...drawerState.extraProps} />}
      </Drawer>
      {children}
    </Context.Provider>
  );
};

export default DrawerContainer;
