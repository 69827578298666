import type {
  ColDef,
  ColDefField,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterFunc,
  ValueGetterParams,
} from 'ag-grid-community';
import { relativeChange, reverseRelativeChange } from 'bigNumMath';
import { isNil } from 'lodash/fp';
import { type BigNumber, bignumber } from 'mathjs';
import type { ReactElement } from 'react';
import {
  assetCellRenderer,
  assetNameGetter,
  type AssetNameRow,
  assetSymbolGetter,
  dateReadableValueGetter,
  derivativeAssetCellRenderer,
  type DerivativeAssetNameRow,
  derivativeAssetValueGetter,
  getRowDataForRowGroupColumn,
  getValueFormatterCellValue,
  sideAwareHeaderName,
} from 'components/technical/grids/agGrid.utils.tsx';
import { logErrorOnce } from 'components/log.utils.ts';
import { IconVariant } from 'components/market/asset/cryptocurrencies/CryptocurrenciesData';
import type { TupleKeyMap } from 'components/TupleKeyMap.ts';
import type { IAssetRiskMetricsQuery, IMultifactorScores, IPositionSide, IPriceForADayQuery } from 'generated/graphql';

import type { PriceChangeDates } from '../../portfolio/openPositions/UsePriceChanges.tsx';
import { venues } from '../../venue/VenueData.tsx';
import VenueLabel from '../../venue/VenueLabel.tsx';
import { SubAccountLabel, type SubAccountLabelInputAccount } from '../../portfolio/account/SubAccountLabel.tsx';
import {
  calculateBalance,
  calculateExposure,
  calculateUnrealizedGain,
  getUnderlyingPosAsset,
  sideMultiplier,
  type SubAccountPosition,
} from '../../portfolio/account/SubAccountPositionsService.ts';
import { formatMetricValue, getName } from 'components/metrics/MetricsData.tsx';
import type { MetricParams } from 'components/metrics/MetricsData.types.ts';
import { formatEnum, formatNumber, formatPercentage } from 'components/formatter.utils.ts';
import type { ObjectKeyMap } from 'components/objectKeyMap.ts';
import { AccountLabel } from 'components/portfolio/account/AccountLabel.tsx';
import dayjs from 'dayjs';
import get from 'lodash/fp/get';
import { parseUtcDate } from '../../date.utils.ts';
import capitalize from 'lodash/fp/capitalize';
import type { AssetLabelInput } from '../../market/asset/AssetLabelService.ts';
import type { FlattenUnion } from '../../type.utils.ts';

type ColumnOptions = Partial<
  Pick<
    ColDef,
    'initialHide' | 'initialRowGroup' | 'initialRowGroupIndex' | 'lockVisible' | 'lockPinned' | 'pinned' | 'hide'
  >
>;

type ColumnOptionsWithSideAware = ColumnOptions & { sideAware: boolean };

export const exposureColumn = <
  TRow extends {
    spot?: { amount: string | number | BigNumber; price: string } | null;
    derivative?: { notional?: string | null; side: IPositionSide } | null;
  },
>({
  sideAware,
  ...columnOptions
}: ColumnOptionsWithSideAware): ColDef<TRow> => ({
  colId: `exposure-sideAware-${sideAware}`,
  headerName: sideAwareHeaderName('Exposure', sideAware),
  type: ['numericColumn', 'cashColumn'],
  valueGetter: (params): number | undefined => {
    if (!params.data) {
      return undefined;
    }

    return calculateExposure(params.data, sideAware)?.toNumber();
  },
  initialAggFunc: 'sum',
  ...columnOptions,
});

export const amountColumn = <
  TRow extends {
    spot?: { amount: string | number | BigNumber } | null;
    derivative?: { amount: string; side: IPositionSide } | null;
  },
>({
  sideAware,
  ...columnOptions
}: ColumnOptionsWithSideAware): ColDef<TRow> => ({
  colId: `amount-sideAware-${sideAware}`,
  headerName: sideAwareHeaderName('Amount', sideAware),
  type: ['numericColumn', 'extendedNumericColumn'],
  valueGetter: (params): number | undefined => {
    if (!params.data) {
      return undefined;
    }

    if (params.data.spot) {
      return bignumber(params.data.spot.amount).toNumber();
    }

    if (!params.data.derivative) {
      console.error('Missing data for amount of derivative', params.data);
      return undefined;
    }
    const derivative = params.data.derivative;

    const value = bignumber(derivative.amount);
    if (sideAware) {
      return value.mul(sideMultiplier(derivative.side)).toNumber();
    }

    return value.toNumber();
  },
  initialAggFunc: 'sum',
  ...columnOptions,
});

export const underlyingAssetColumn = <TRow extends DerivativeAssetNameRow>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'underlyingAsset',
  headerName: 'Underlying asset',
  type: 'textColumn',

  initialHide: true,
  // biome-ignore lint/suspicious/noExplicitAny:
  valueGetter: derivativeAssetValueGetter as unknown as any,
  cellRenderer: derivativeAssetCellRenderer,
  ...columnOptions,
});

export const nameColumn = <TRow extends AssetNameRow>(columnOptions: ColumnOptions = {}): ColDef<TRow> => ({
  colId: 'name',
  headerName: 'Name',
  type: 'textColumn',
  minWidth: 160,
  initialHide: true,
  valueGetter: assetNameGetter,
  cellRenderer: assetCellRenderer,
  ...columnOptions,
});

export const symbolColumn = <TRow extends { asset: { symbol: string } }>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'symbol',
  headerName: 'Symbol',
  type: 'textColumn',
  minWidth: 160,
  initialHide: true,
  valueGetter: assetSymbolGetter,
  ...columnOptions,
});

export const slotColumn = <TRow extends SubAccountPosition<unknown>>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'state',
  headerName: 'State',
  type: ['textColumn'],
  valueGetter: (params): string | undefined => {
    if (!params.data) {
      return undefined;
    }

    const spot = params.data.spot;
    if (isNil(spot)) {
      return undefined;
    }

    return formatEnum(spot.slot);
  },
  ...columnOptions,
});

export const balanceColumn = <
  TRow extends {
    spot?: { balance: string | number | BigNumber } | null;
    derivative?: { balance?: string | null } | null;
  },
>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'balance',
  headerName: 'Balance',
  type: ['numericColumn', 'cashColumn'],
  valueGetter: (params): number | undefined => {
    if (!params.data) {
      return undefined;
    }

    return calculateBalance(params.data)?.toNumber();
  },
  initialAggFunc: 'sum',
  ...columnOptions,
});

export const balanceToMetricColumn = <TRow extends SubAccountPosition<unknown> & { asset: { id: string } }>({
  metricsByAsset,
  metricLabel,
  headerName,
}: {
  metricsByAsset: Map<string, AssetMetricValues>;
  metricLabel: string;
  headerName: string;
}): ColDef<TRow> => ({
  colId: 'balance',
  headerName,
  type: ['numericColumn', 'percentageColumn'],
  valueGetter: (params): number | undefined => {
    if (!params.data) {
      return undefined;
    }
    const metricValues = metricsByAsset.get(params.data.asset.id);
    if (!metricValues) {
      return undefined;
    }
    const metricValue = metricValues[metricLabel] ? bignumber(metricValues[metricLabel]).toNumber() : undefined;
    const balance = calculateBalance(params.data)?.toNumber();

    if (isNil(balance) || isNil(metricValue) || metricValue === 0) {
      return undefined;
    }

    return balance / metricValue;
  },
  initialAggFunc: 'sum',
});

export const subAccountColumn = <TRow extends { subAccount: SubAccountLabelInputAccount }>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow, string> => {
  return {
    field: 'subAccount.name' satisfies ColDefField<{ subAccount: SubAccountLabelInputAccount }> as ColDefField<TRow>,
    headerName: 'Sub-account',
    type: 'textColumn',
    cellRenderer: (
      params: ICellRendererParams<{ subAccount: SubAccountLabelInputAccount }>
    ): ReactElement | undefined => {
      const rowData = getRowDataForRowGroupColumn(params);
      if (!rowData) {
        return undefined;
      }
      const subAccount = rowData.subAccount;
      return subAccount ? (
        <SubAccountLabel subAccount={subAccount} wrap={false} size={IconVariant.MEDIUM} plain />
      ) : undefined;
    },
    ...columnOptions,
  };
};

export const accountColumn = <TRow extends { subAccount: SubAccountLabelInputAccount }>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  // biome-ignore lint/suspicious/noExplicitAny:
  field: 'subAccount.account.name' as any,
  headerName: 'Account',
  type: 'textColumn',
  cellRenderer: (
    params: ICellRendererParams<{ subAccount: SubAccountLabelInputAccount }>
  ): ReactElement | undefined => {
    const rowData = getRowDataForRowGroupColumn(params);
    if (!rowData) {
      return undefined;
    }
    const account = rowData.subAccount.account;
    return account ? <AccountLabel account={account} wrap={false} size={IconVariant.MEDIUM} plain /> : undefined;
  },
  ...columnOptions,
});

export const venueColumn = <TRow extends { subAccount: SubAccountLabelInputAccount }>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'venue',
  headerName: 'Venue',
  type: 'textColumn',
  valueGetter: (params): string | undefined => {
    if (!params.data) {
      return undefined;
    }
    const venueLabel = params.data.subAccount.account.venue.label;

    if (!venueLabel) {
      return undefined;
    }

    return venues[venueLabel]?.name;
  },
  cellRenderer: (
    params: ICellRendererParams<{ subAccount: SubAccountLabelInputAccount }>
  ): ReactElement | undefined => {
    const rowData = getRowDataForRowGroupColumn(params);
    if (!rowData) {
      return undefined;
    }

    return (
      <VenueLabel
        accountName={rowData.subAccount.account.name}
        venue={rowData.subAccount.account.venue.label}
        size={IconVariant.MEDIUM}
        format="long"
      />
    );
  },
  ...columnOptions,
});

export const unrealizedPnlColumn = <TRow extends SubAccountPosition<{ id: string }>>(
  costBasisPerAssetSubFund?: TupleKeyMap<[string, number | null], BigNumber>,
  subAccountToFunds?: (subAcc: string) => number | null
): ColDef<TRow> => ({
  colId: 'unrealizedPnl',
  headerName: 'Unrealized P&L',
  type: ['numericColumn', 'cashColumn'],
  valueGetter(params): number | undefined {
    if (!params.data) {
      return undefined;
    }
    const { derivative, spot, asset } = params.data;
    if (derivative) {
      return derivative.unrealizedPnl ? bignumber(derivative.unrealizedPnl)?.toNumber() : undefined;
    }

    if (!costBasisPerAssetSubFund) {
      logErrorOnce('costBasisPerAssetSubFund should be provided for spot Unrealized P&L calculation');
      return undefined;
    }

    if (!subAccountToFunds) {
      logErrorOnce('subAccountToFunds should be provided for spot Unrealized P&L calculation');
      return undefined;
    }

    const unitCostBasis = costBasisPerAssetSubFund.get([asset.id, subAccountToFunds(params.data.subAccount.id)]);
    if (spot?.amount && spot.price && unitCostBasis) {
      return calculateUnrealizedGain(bignumber(spot.amount), bignumber(spot.price), unitCostBasis).toNumber();
    }
  },
  enableValue: false,
});

export const costBasisColumn = <TRow extends SubAccountPosition>(
  costBasisPerAssetSubFund: TupleKeyMap<[string, number | null], BigNumber>,
  subAccountToFunds?: (subAcc: string) => number | null
): ColDef<TRow> => ({
  colId: 'costBasis',
  headerName: 'Cost basis',
  type: ['numericColumn', 'cashColumn'],
  valueGetter(params): number | undefined {
    if (!params.data) {
      return undefined;
    }
    const { spot, asset, derivative } = params.data;

    if (!costBasisPerAssetSubFund) {
      logErrorOnce('costBasisPerAssetSubFund should be provided for spot CostBasis P&L calculation');
      return undefined;
    }

    if (!subAccountToFunds) {
      logErrorOnce('subAccountToFunds should be provided for spot CostBasis P&L calculation');
      return undefined;
    }

    const unitCostBasis = costBasisPerAssetSubFund.get([asset.id, subAccountToFunds(params.data.subAccount.id)]);
    if (spot?.amount && unitCostBasis) {
      return unitCostBasis.mul(bignumber(spot.amount)).toNumber();
    }

    if (derivative?.unitEntryPrice) {
      return bignumber(derivative.unitEntryPrice).mul(derivative.amount).toNumber();
    }
  },
  enableValue: false,
});

export const weightColumn = <TRow extends SubAccountPosition<unknown>>(totalBalance: BigNumber): ColDef<TRow> => ({
  colId: 'balanceContribution',
  headerName: 'Balance %',
  headerTooltip: 'Contribution to balance',
  type: ['numericColumn', 'percentageColumn'],
  valueGetter(params): number | undefined {
    if (!params.data) {
      return undefined;
    }
    const balance = calculateBalance(params.data);
    if (!isNil(balance)) {
      const balanceContribution = balance.div(totalBalance);
      return balanceContribution.isFinite() ? balanceContribution.toNumber() : undefined;
    }
    return undefined;
  },
  initialAggFunc: 'sum',
});

export const calculateLongShort = (pos: {
  spot?: { amount: string | number | BigNumber } | null;
  derivative?: { side: string } | null;
}): string => {
  const spot = pos.spot;
  if (spot) {
    if (bignumber(spot.amount).isNegative()) {
      return 'Short';
    }

    return 'Long';
  }

  const derivativeDetails = pos.derivative;
  if (derivativeDetails) {
    return capitalize(derivativeDetails.side);
  }

  return '';
};

export const sideColumn = <
  TRow extends {
    spot?: { amount: string | number | BigNumber } | null;
    derivative?: { side: string } | null;
  },
>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'side',
  headerName: 'Side',
  type: 'textColumn',
  valueGetter: (params): string => {
    if (!params.data) {
      return '';
    }

    return calculateLongShort(params.data);
  },
  ...columnOptions,
});

export const typeColumn = <TRow extends { spot?: unknown | null }>(
  columnOptions: ColumnOptions = {}
): ColDef<TRow> => ({
  colId: 'type',
  headerName: 'Type',
  type: 'textColumn',
  minWidth: 100,
  valueGetter: (params): string => {
    if (!params.data) {
      return '';
    }

    return params.data.spot ? 'Spot' : 'Derivative';
  },
  ...columnOptions,
});

export function clusterColumn<
  TRow extends {
    asset: { id: string } & FlattenUnion<AssetLabelInput>;
  },
>(cluster: string, assetAndGroupClusterMapToGroup: TupleKeyMap<[string, string], string>): ColDef<TRow> {
  return {
    colId: `cluster-${cluster}`,
    headerName: cluster,
    type: 'textColumn',
    initialHide: true,
    valueGetter: (params: ValueGetterParams<TRow>): string | undefined => {
      if (!params.data) {
        return undefined;
      }

      const asset = getUnderlyingPosAsset(params.data);
      if (!asset) {
        return undefined;
      }

      return assetAndGroupClusterMapToGroup.get([asset.id, cluster]);
    },
  };
}

export const initialMarginColumn = <TRow extends SubAccountPosition<unknown>>(): ColDef<TRow> => ({
  colId: 'initialMargin',
  headerName: 'Initial margin',
  type: ['numericColumn', 'cashColumn'],
  initialHide: true,
  valueGetter: (params): number | undefined =>
    params.data?.derivative?.initialMargin ? bignumber(params.data.derivative.initialMargin).toNumber() : undefined,
  initialAggFunc: 'sum',
});

export const maintenanceMarginColumn = <TRow extends SubAccountPosition<unknown>>(): ColDef<TRow> => ({
  colId: 'maintenanceMargin',
  headerName: 'Maintenance margin',
  type: ['numericColumn', 'cashColumn'],
  initialHide: true,
  valueGetter: (params): number | undefined =>
    params.data?.derivative?.maintenanceMargin
      ? bignumber(params.data.derivative.maintenanceMargin).toNumber()
      : undefined,
  initialAggFunc: 'sum',
});

export const leverageColumn = <TRow extends SubAccountPosition<unknown>>(): ColDef<TRow> => ({
  colId: 'leverage',
  headerName: 'Leverage',
  type: ['numericColumn'],
  initialHide: true,
  valueGetter: (params): number | undefined =>
    params.data?.derivative?.leverage ? bignumber(params.data.derivative.leverage).toNumber() : undefined,
  initialAggFunc: 'last',
});

export const notionalColumn = <TRow extends SubAccountPosition<unknown>>(): ColDef<TRow> => ({
  colId: 'notional',
  headerName: 'Notional',
  type: ['numericColumn', 'cashColumn'],
  initialHide: true,
  valueGetter: (params): number | undefined =>
    params.data?.derivative?.notional ? bignumber(params.data.derivative.notional).toNumber() : undefined,
  initialAggFunc: 'sum',
});

export const unitMarketPriceColumn = <TRow extends SubAccountPosition<unknown>>(): ColDef<TRow> => ({
  colId: 'unitMarketPrice',
  headerName: 'Unit Market Price',
  type: ['numericColumn', 'cashColumn'],
  valueGetter: (params): number | undefined =>
    params.data?.derivative?.unitMarketPrice ? bignumber(params.data.derivative.unitMarketPrice).toNumber() : undefined,
});

export const unitEntryPriceColumn = <TRow extends SubAccountPosition<unknown>>(): ColDef<TRow> => ({
  colId: 'unitEntryPrice',
  headerName: 'Unit Entry Price',
  type: ['numericColumn', 'cashColumn'],
  valueGetter: (params): number | undefined =>
    params.data?.derivative?.unitEntryPrice ? bignumber(params.data.derivative.unitEntryPrice).toNumber() : undefined,
});

export type Price = IPriceForADayQuery['assets']['priceForDay'][number]['rows'][number];

export const priceChangesGroupColumns = <TRow extends { asset: { id: string } }>(
  priceChangeDates: PriceChangeDates,
  pricesByAssetAndDate: TupleKeyMap<[string, string], Price>,
  priceChanges24hByAssetId: Map<string, number | undefined>
): ColGroupDef<TRow>[] => [
  {
    headerName: 'Price',
    marryChildren: true,
    children: [
      // for today date endpoint assets.priceForDay returns the most recent price
      priceColumn(pricesByAssetAndDate, priceChangeDates.today, 'Latest'),
      minus24HoursPriceColumn(pricesByAssetAndDate, priceChanges24hByAssetId, priceChangeDates.today),
      priceColumn(pricesByAssetAndDate, priceChangeDates.firstDayOfMonth, 'MTD'),
      priceColumn(pricesByAssetAndDate, priceChangeDates.firstDayOfQuarter, 'QTD'),
      priceColumn(pricesByAssetAndDate, priceChangeDates.firstDayOfYear, 'YTD'),
    ],
  },
  {
    headerName: 'Price change %',
    marryChildren: true,
    children: [
      todayPriceChangeColumn(priceChanges24hByAssetId),
      priceChangeColumn(
        pricesByAssetAndDate,
        priceChangeDates.firstDayOfMonth,
        priceChangeDates.today,
        'MTD',
        'Month to date'
      ),
      priceChangeColumn(
        pricesByAssetAndDate,
        priceChangeDates.firstDayOfQuarter,
        priceChangeDates.today,
        'QTD',
        'Quarter to date'
      ),
      priceChangeColumn(
        pricesByAssetAndDate,
        priceChangeDates.firstDayOfYear,
        priceChangeDates.today,
        'YTD',
        'Year to date'
      ),
    ],
  },
];

export const todayPriceChangeColumn = <TRow extends { asset: { id: string } }>(
  priceChanges24h: Map<string, number | undefined>
): ColDef<TRow> => ({
  headerName: '24h',
  colId: 'price-change-24h',
  type: ['numericColumn', 'percentageColumn'],
  valueGetter: (params): number | undefined => {
    if (!params.data) {
      return undefined;
    }

    return priceChanges24h.get(params.data.asset.id);
  },
});

/**
 * Shows price for a day, but for today it shows the most recent price
 */
export const priceColumn = <TRow extends { asset: { id: string } }>(
  pricesByAssetAndDate: TupleKeyMap<[string, string], Price>,
  priceDateIso: UtcDate,
  priceDateForHeader: string
): ColDef<TRow> => {
  return {
    headerName: priceDateForHeader,
    colId: `past-price-${priceDateForHeader}`,
    type: ['numericColumn', 'cashColumn'],
    initialAggFunc: '',
    valueGetter: (params): number | undefined => {
      if (!params.data) {
        return undefined;
      }
      const assetId = params.data.asset.id;
      const price = pricesByAssetAndDate.get([assetId, priceDateIso.toString()])?.price;

      if (!price) {
        return undefined;
      }

      return bignumber(price).toNumber();
    },
  };
};

/**
 * calculate "24 hours ago" price from the most recent (todayDate price) and price change, note that it's not yesterday "day" price
 */
export const minus24HoursPriceColumn = <TRow extends { asset: { id: string } }>(
  pricesByAssetAndDate: TupleKeyMap<[string, string], Price>,
  priceChanges24h: Map<string, number | undefined>,
  todayDate: UtcDate
): ColDef<TRow> => ({
  headerName: '24h',
  colId: 'past-price-24h',
  type: ['numericColumn', 'cashColumn'],
  initialAggFunc: '',
  valueGetter: (params): number | undefined => {
    if (!params.data) {
      return undefined;
    }
    const assetId = params.data.asset.id;
    const todayPrice = pricesByAssetAndDate.get([assetId, todayDate.toString()])?.price;
    const priceChange = priceChanges24h.get(assetId);
    if (!todayPrice || !priceChange) {
      return undefined;
    }
    return reverseRelativeChange(bignumber(todayPrice), bignumber(priceChange)).toNumber();
  },
});

export const priceChangeColumn = <TRow extends { asset: { id: string } }>(
  pricesByAssetAndDate: TupleKeyMap<[string, string], Price>,
  pastPriceDate: UtcDate,
  today: UtcDate,
  headerName: string,
  headerTooltip: string
): ColDef<TRow> => ({
  headerName,
  headerTooltip,
  colId: `price-change-${headerName}`,
  type: ['numericColumn', 'percentageColumn'],
  valueGetter: (params): number | undefined => {
    if (!params.data) {
      return undefined;
    }
    const assetId = params.data.asset.id;
    const pastPrice = pricesByAssetAndDate.get([assetId, pastPriceDate.toString()])?.price;
    const todayPrice = pricesByAssetAndDate.get([assetId, today.toString()])?.price;
    if (!pastPrice || !todayPrice) {
      return undefined;
    }

    return relativeChange(bignumber(todayPrice), bignumber(pastPrice))?.toNumber();
  },
});

export type AssetMetricValues = IAssetRiskMetricsQuery['assets']['details'][number]['metrics'];

export type MetricColumnConfig = {
  metricLabel: string;
  params?: MetricParams;
  initialHide: boolean;
};

export function assetMetricColumn<TRow extends { asset: { id: string } }>(
  metricsByAsset: Map<string, AssetMetricValues>,
  metricLabel: string,
  initialHide: boolean
): ColDef<TRow, number> {
  return {
    colId: metricLabel,
    headerName: getName(metricLabel, undefined, true),
    initialHide,
    type: ['numericColumn', 'extendedNumericColumn'],
    valueFormatter: (params): string => {
      const metricValue = getValueFormatterCellValue(params.value);
      if (!metricValue) {
        return '';
      }
      return formatMetricValue(metricLabel, metricValue);
    },
    valueGetter: (params): number | undefined => {
      if (!params.data) {
        return undefined;
      }
      const metricValues = metricsByAsset.get(params.data.asset.id);
      if (!metricValues) {
        return undefined;
      }
      return metricValues[metricLabel] ? bignumber(metricValues[metricLabel]).toNumber() : undefined;
    },
  };
}

export type PortfolioRiskMetricReportMapKey = {
  assetId: string;
  window: number;
  metricLabel: string;
  benchmark: string;
};

export type PortfolioRiskMetricReportMapValue = {
  value: string;
  seriesTotal: string;
};

export function portfolioMetricContributionColumn<TRow extends { asset: { id: string } }>({
  portfolioMetricValues,
  metricLabel,
  window,
  benchmark,
  ofTotal,
  ...columnOptions
}: {
  portfolioMetricValues: ObjectKeyMap<PortfolioRiskMetricReportMapKey, PortfolioRiskMetricReportMapValue>;
  metricLabel: string;
  window: number;
  benchmark: { id: string; symbol: string } | undefined;
  ofTotal: boolean;
} & ColumnOptions): ColDef<TRow> {
  const params = benchmark ? { assetSymbol: benchmark.symbol } : undefined;
  const metricName = getName(metricLabel, params);
  const headerSuffix = ofTotal ? ' of total' : '';
  return {
    colId: metricLabel,
    headerName: metricName + headerSuffix,
    ...columnOptions,
    type: ['numericColumn', 'extendedNumericColumn'],
    valueFormatter: (params): string => {
      if (!params.value) {
        return '';
      }
      return ofTotal ? formatPercentage(params.value) : formatMetricValue(metricLabel, params.value);
    },
    valueGetter: (params): number | undefined => {
      if (!params.data) {
        return undefined;
      }

      const metricValue = portfolioMetricValues.get({
        assetId: params.data.asset.id,
        window,
        metricLabel,
        benchmark: benchmark?.id ?? '',
      });
      if (!metricValue) {
        return undefined;
      }
      const value = bignumber(metricValue.value);
      if (ofTotal) {
        return value.toNumber();
      }
      return value.mul(metricValue.seriesTotal).toNumber();
    },
  };
}

export const subFundGroupColumn = <TRow extends { subAccount: SubAccountLabelInputAccount }>(
  subFundDimension: string,
  subAccountAndDimensionToFund: TupleKeyMap<[string, string], string>
): ColDef<TRow> => ({
  colId: `subFund-group-dimension-${subFundDimension}`,
  headerName: subFundDimension,
  type: 'textColumn',
  hide: true,
  valueGetter: (params): string | undefined => {
    if (!params.data) {
      return undefined;
    }

    const subAccountId = params.data.subAccount.id;
    return subAccountAndDimensionToFund.get([subAccountId, subFundDimension]);
  },
});

const getFormattedDate = <T, F extends ColDefField<T, UtcDate>>(
  field: F,
  format: string
): ValueGetterFunc<T, string | undefined> => {
  return (params: ValueGetterParams<T>): string | undefined => {
    if (!params.data) {
      return undefined;
    }

    return parseUtcDate(get(field, params.data)).format(format);
  };
};

const dayFormat = 'DD';
const isoWeekFormat = 'W';
const monthFormat = 'MMMM';
const yearFormat = 'YYYY';

const dayComparator = (valueA: string, valueB: string): number => {
  return Number.parseInt(valueA) - Number.parseInt(valueB);
};

const isoWeekComparator = (valueA: string, valueB: string): number => {
  return dayjs(valueA, [isoWeekFormat]).valueOf() - dayjs(valueB, [isoWeekFormat]).valueOf();
};

const monthComparator = (valueA: string, valueB: string): number => {
  return dayjs(valueA, [monthFormat]).valueOf() - dayjs(valueB, [monthFormat]).valueOf();
};

const yearComparator = (valueA: string, valueB: string): number => {
  return Number.parseInt(valueA) - Number.parseInt(valueB);
};

export const yearMonthDayColumns = <RowData, F extends ColDefField<RowData, UtcDate> = ColDefField<RowData, UtcDate>>({
  field,
  show,
}: { field: F; show: { day?: boolean; week?: boolean } }): ColDef<RowData> & ColGroupDef<RowData> => {
  const dateColumn: ColDef<RowData> = {
    headerName: 'Date',
    colId: 'date-date',
    rowGroup: false,
    type: 'dateColumn',
    hide: true,
    valueGetter: (params: ValueGetterParams<RowData>): string | undefined =>
      params.data ? dateReadableValueGetter(get(field, params.data)) : undefined,
  };

  const dayColumn: ColDef<RowData> = {
    headerName: 'Day',
    enableRowGroup: true,
    colId: 'date-day',
    valueGetter: getFormattedDate(field, dayFormat),
    chartDataType: 'category' as const,
    filter: 'agSetColumnFilter',
    comparator: dayComparator,
    pivotComparator: dayComparator,
    pivot: false,
    rowGroup: true,
    filterParams: {
      comparator: dayComparator,
    },
  };

  const isoWeekColumn: ColDef<RowData> = {
    headerName: 'Week',
    enableRowGroup: true,
    colId: 'date-week',
    valueGetter: getFormattedDate(field, isoWeekFormat),
    chartDataType: 'category' as const,
    filter: 'agSetColumnFilter',
    comparator: isoWeekComparator,
    pivotComparator: isoWeekComparator,
    hide: true,
    pivot: false,
    rowGroup: true,
    filterParams: {
      comparator: isoWeekComparator,
    },
  };

  const monthColumn: ColDef<RowData> = {
    headerName: 'Month',
    enableRowGroup: true,
    colId: 'date-month',
    valueGetter: getFormattedDate(field, monthFormat),
    chartDataType: 'category' as const,
    filter: 'agSetColumnFilter',
    comparator: monthComparator,
    pivotComparator: monthComparator,
    pivot: false,
    rowGroup: true,
    filterParams: {
      comparator: monthComparator,
    },
  };

  const yearColumn: ColDef<RowData> = {
    headerName: 'Year',
    enableRowGroup: true,
    colId: 'date-year',
    valueGetter: getFormattedDate(field, yearFormat),
    chartDataType: 'category' as const,
    filter: 'agSetColumnFilter',
    comparator: yearComparator,
    pivotComparator: yearComparator,
    pivot: false,
    rowGroup: true,
    filterParams: {
      comparator: yearComparator,
    },
  };

  const children = [yearColumn, monthColumn, isoWeekColumn, dayColumn, dateColumn].filter((col) => {
    if (col.colId === dayColumn.colId) {
      return show.day;
    }

    if (col.colId === isoWeekColumn.colId) {
      return show.week;
    }

    return true;
  });

  return {
    headerName: 'Date',
    colId: 'date',
    field: field,

    enableRowGroup: true,
    chartDataType: 'series' as const,

    children,
  };
};

export const multifactorScoresColumn = <TRow extends { asset: { id: string } }>(
  scores: IMultifactorScores
): ColDef<TRow> => {
  const scoresMap = new Map(scores.assetScores.map((score) => [score.asset.id, score.score]));

  return {
    headerName: scores.multifactor.multifactor.name,
    colId: `multifacor-${scores.multifactor.multifactor.id}`,
    type: ['numericColumn'],
    valueGetter: (params): number | undefined => {
      if (!params.data) {
        return undefined;
      }

      const score = scoresMap.get(params.data?.asset.id);
      if (score) {
        return Number.parseFloat(score);
      }
    },
    valueFormatter: (params: ValueFormatterParams) => formatNumber(params.value),
  };
};
