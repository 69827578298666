import { TupleKeyMap } from 'components/TupleKeyMap.ts';
import type { ISubFundToSubAccountFieldsFragment } from 'generated/graphql';

/**
 * important prop: for one account and one dimension should be ONLY ONE correspondent sub-fund
 * @returns accountAndDimensionToSubFund: TupleKeyMap [account.id, dimension] -> sub-fund
 * @returns subFundDimensions: list of uniq sub-fund dimensions
 */
export function mapSubAccountAndDimensionToSubFund(subFunds: ISubFundToSubAccountFieldsFragment[]): {
  subAccountAndDimensionToSubFund: TupleKeyMap<[string, string], string>;
  subFundDimensions: string[];
} {
  const subAccountAndDimensionToSubFund = new TupleKeyMap<[string, string], string>();
  const subFundDimensions = new Set<string>();
  for (const subFund of subFunds) {
    subFundDimensions.add(subFund.dimension);
    for (const account of subFund.accounts) {
      for (const subAccount of account.subAccounts) {
        subAccountAndDimensionToSubFund.set([subAccount.id, subFund.dimension], subFund.name);
      }
    }

    for (const subAcc of subFund.subAccounts) {
      subAccountAndDimensionToSubFund.set([subAcc.id, subFund.dimension], subFund.name);
    }
  }

  return {
    subAccountAndDimensionToSubFund: subAccountAndDimensionToSubFund,
    subFundDimensions: Array.from(subFundDimensions).sort(),
  };
}
