import type { ReactElement } from 'react';
import { Stack, Typography } from '@mui/joy';
import type { GridWidth } from 'components/technical/GCard/GCard.tsx';
import GCard from 'components/technical/GCard/GCard.tsx';
import type { IPublicAssetRequestsQuery } from 'generated/graphql';
import { GCardContentWithDivider } from '../../../../technical/GCard/GCardContentWithDivider.tsx';
import { GCardKeyValueContent } from '../../../../technical/GCard/GCardKeyValueContent.tsx';
import { useUserTimezone } from '../../../../technical/UseUserTimezone.tsx';
import { DateTimeFormat, formatDate } from '../../../../formatter.utils.ts';
import { RequestStatusChip } from './RequestStatusChip.tsx';

const PublicAssetRequestCard = ({
  request,
  gridWidth,
  height,
}: {
  request: IPublicAssetRequestsQuery['assets']['publicAssetRequests'][number];
  gridWidth: GridWidth;
  height: string;
}): ReactElement => {
  const timezone = useUserTimezone();
  return (
    <GCard height={height} gridWidth={gridWidth}>
      <GCardContentWithDivider>
        <Stack justifyContent="space-between" direction="row" alignItems={'center'} columnGap={1}>
          <Typography level={'title-lg'}>{request.symbol}</Typography>
          <RequestStatusChip status={request.status} />
        </Stack>
        <GCardKeyValueContent
          data={[]}
          updateSummary={[
            { key: 'Requested on', value: formatDate(request.startTime, DateTimeFormat.DateTime, timezone) },
            { key: 'Added on', value: formatDate(request.endTime, DateTimeFormat.DateTime, timezone) },
          ]}
        />
      </GCardContentWithDivider>
    </GCard>
  );
};

export default PublicAssetRequestCard;
