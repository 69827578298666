const config: ConfigOptions = {
  number: 'BigNumber',
};

import {
  bignumber,
  type BigNumber,
  type ConfigOptions,
  create,
  sumDependencies,
  meanDependencies,
  minDependencies,
  maxDependencies,
  floorDependencies,
  ceilDependencies,
} from 'mathjs';
const { sum, mean, min, max, floor, ceil } = create(
  { sumDependencies, meanDependencies, minDependencies, maxDependencies, floorDependencies, ceilDependencies },
  config
);

export default {
  sum,
  mean,
  min,
  max,
  floor,
  ceil,
};

/**
 * calculates pastValue =  currentValue / (1 + change) from original relative change = (currentValue - pastValue) / pastValue
 */
export const reverseRelativeChange = (currentValue: BigNumber, relChange: BigNumber): BigNumber => {
  const denominator = relChange.plus(1);
  if (denominator.equals(0)) {
    return bignumber(0);
  }

  return currentValue.div(denominator);
};

/**
 * returns relativeChange = (currentValue - previousValue) / previousValue
 */
export const relativeChange = (currentValue: BigNumber, previousValue: BigNumber): BigNumber | null => {
  if (previousValue.equals(0)) {
    return null;
  }

  return currentValue.sub(previousValue).div(previousValue);
};
