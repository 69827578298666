import { Card, Typography } from '@mui/joy';
import { type ReactElement, useRef } from 'react';
import { ipsToWhitelist } from './IpsToWhitelist.ts';
import { commonWhite, neutralPlainDisabledColor } from '../../theme/colors.ts';

export const SelectableIPAddresses = (): ReactElement => {
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <div
      onClick={(): void => {
        const elementToSelect = ref.current;
        if (!elementToSelect) {
          return;
        }

        const selection = window.document.getSelection();
        if (!selection) {
          console.warn('No selection api supported in a browser');
          return;
        }

        const range = new Range();
        range.selectNode(elementToSelect);
        selection.removeAllRanges();
        selection.addRange(range);
      }}
    >
      <Card sx={{ background: neutralPlainDisabledColor }}>
        <Typography level={'body-sm'} textColor={commonWhite} textTransform="uppercase">
          <div ref={ref}>{ipsToWhitelist.join(', ')}</div>
        </Typography>
      </Card>
    </div>
  );
};
