import { IAssetManagerStatusType } from '../../../../../generated/graphql.tsx';
import StatusChip from '../../../../technical/StatusChip.tsx';
import { IconCheck, IconClockHour1Filled, IconX } from '@tabler/icons-react';

export const RequestStatusChip = ({ status }: { status: IAssetManagerStatusType }) => {
  const size = 'md';
  switch (status) {
    case IAssetManagerStatusType.Completed:
      return (
        <StatusChip color="success" icon={IconCheck} size={size}>
          Completed
        </StatusChip>
      );
    case IAssetManagerStatusType.Failed:
      return (
        <StatusChip color="danger" icon={IconX} size={size}>
          Failed
        </StatusChip>
      );
    case IAssetManagerStatusType.Running:
      return (
        <StatusChip color="neutral" icon={IconClockHour1Filled} size={size}>
          Running
        </StatusChip>
      );
    default:
      return null;
  }
};
