import { useEffect, useState } from 'react';
import { useThrottle } from './useThrottle.ts';

// stops polling when user navigates away
export const useBackgroundPolling = (intervalMs: number): number => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const cb = () => {
      setIsVisible(document.visibilityState === 'visible');
    };
    document.addEventListener('visibilitychange', cb);

    return () => {
      document.removeEventListener('visibilitychange', cb);
    };
  }, []);

  const throttledIsVisible = useThrottle(isVisible, intervalMs);
  return throttledIsVisible ? intervalMs : 0;
};
