import { Avatar, Stack, Typography } from '@mui/joy';
import type { FunctionComponent, ReactElement } from 'react';
import GLink from 'components/technical/GLink/GLink';
import { useScreenBreakpointDown } from 'components/technical/screenSizeUtils.ts';
import UnknownIcon from 'components/technical/UnknownIcon/UnknownIcon';
import { type AssetLabelInput, isAssetLabelInput, type NotVerifiedAsset } from './AssetLabelService.ts';
import { createAssetLink, IconVariant, iconVariantToPx } from './cryptocurrencies/CryptocurrenciesData';
import { IAssetType } from '../../../generated/graphql';
import { venues } from '../../venue/VenueData.tsx';
import { getAssetName } from './AssetService.tsx';
import { logWarnOnce } from 'components/log.utils.ts';
import { truncateText } from '../../text.styles.ts';

export interface AssetLabelProps {
  asset: NotVerifiedAsset;
  format?: 'short' | 'long';
  link?: boolean;
  size?: IconVariant;
  wrap?: boolean;
  plain?: boolean;
  hideDebug?: boolean;
}

export type AssetIconAssetProps = NotVerifiedAsset;

export const AssetIcon = ({
  asset,
  size = IconVariant.LARGE,
}: {
  asset: AssetIconAssetProps;
  size?: IconVariant;
}): ReactElement => {
  const iconSize = iconVariantToPx(size);
  const iconSizeStyles = {
    height: iconSize,
    width: iconSize,
  } as const;

  const centerIconInAvatarStyle = {
    '& .MuiAvatar-img': {
      objectFit: 'fill',
    },
  };

  const fallbackAsset = <UnknownIcon size={size} text={asset.symbol} />;
  if (asset.type === IAssetType.Exchange) {
    if (!asset.exchangeDetails) {
      logWarnOnce(`unknown exchangeDetails for exchange asset: ${asset.symbol}`, asset);
      return fallbackAsset;
    }

    const venue = venues[asset.exchangeDetails];
    if (!venue) {
      logWarnOnce(`unknown venue for exchange asset: ${asset.symbol}`, asset);
      return fallbackAsset;
    }

    return (
      <Avatar
        component={venue.icon}
        alt={asset.symbol}
        variant="plain"
        sx={{
          ...iconSizeStyles,
          ...centerIconInAvatarStyle,
          background: venue.iconBackground,
          padding: 0.25, // add spacing so that exchange is in the icon
        }}
      />
    );
  }

  if (asset.type === IAssetType.Derivative) {
    if (!asset.derivativeDetails) {
      logWarnOnce(`unknown derivativeDetails for derivative asset: ${asset.symbol}`, asset);
      return fallbackAsset;
    }

    if (!asset.derivativeDetails.exchange) {
      logWarnOnce(`unknown venue for derivative asset: ${asset.symbol}`, asset);
      return fallbackAsset;
    }

    const venue = venues[asset.derivativeDetails.exchange];
    return (
      <Avatar
        component={venue?.icon}
        alt={asset.symbol}
        variant="plain"
        sx={{
          ...iconSizeStyles,
          ...centerIconInAvatarStyle,
          background: venue?.iconBackground ?? 'transparent',
          padding: 0.25, // add spacing so that exchange is in the icon
        }}
      />
    );
  }

  if (asset.type === IAssetType.Private) {
    return <UnknownIcon text={asset.symbol} size={size} />;
  }

  if (asset.type === IAssetType.Public) {
    return (
      <Avatar
        src={asset.icon ?? '?'}
        alt={asset.symbol}
        variant="plain"
        sx={{
          ...iconSizeStyles,
          ...centerIconInAvatarStyle,
        }}
      />
    );
  }

  return <UnknownIcon size={size} text={asset.symbol} />;
};

function shouldShowLink(asset: AssetLabelInput, link: boolean, isXsScreen: boolean): boolean {
  // when asset page is adapted for mobile screens, we can remove isXsScreen check
  return link && !isXsScreen && [IAssetType.Public, IAssetType.Private, IAssetType.Derivative].includes(asset.type);
}

function shouldShowIcon(asset: AssetLabelInput, format: 'short' | 'long'): boolean {
  return (
    format === 'long' &&
    [IAssetType.Public, IAssetType.Private, IAssetType.Derivative, IAssetType.Exchange].includes(asset.type)
  );
}

const AssetLabel: FunctionComponent<AssetLabelProps> = ({
  asset: notVerifiedAsset,
  format = 'long',
  size = IconVariant.MEDIUM,
  link = false,
  wrap = true,
  plain = false,
}) => {
  const isXsScreen = useScreenBreakpointDown('sm');

  if (!isAssetLabelInput(notVerifiedAsset)) {
    return notVerifiedAsset?.symbol ?? '';
  }

  const asset = notVerifiedAsset as AssetLabelInput;

  const showIcon = shouldShowIcon(asset, format);

  const assetIconAndName = (
    <Stack
      direction="row"
      spacing={0.5}
      alignItems="center"
      display="inline-flex"
      maxWidth="100%"
      flexWrap={wrap ? 'wrap' : 'nowrap'}
    >
      {showIcon && <AssetIcon asset={asset} size={size} />}
      {plain ? (
        <span style={truncateText}>{getAssetName(asset, format)}</span>
      ) : (
        <Typography
          component="span"
          sx={{
            ...truncateText,
            mr: 1,
          }}
          level={'title-sm'}
        >
          {getAssetName(asset, format)}
        </Typography>
      )}
    </Stack>
  );

  const showLink = shouldShowLink(asset, link, isXsScreen);
  return showLink ? (
    <GLink to={createAssetLink(asset)} lineHeight={1}>
      {assetIconAndName}
    </GLink>
  ) : (
    assetIconAndName
  );
};

const AssetLabelWithOptionalIds = (props: AssetLabelProps): ReactElement => {
  return <AssetLabel {...props} />;
};

export default AssetLabelWithOptionalIds;
