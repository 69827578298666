export interface CognitoPoolSettings {
  region: string;
  userPoolId: string;
  clientAppId: string;
}

const cognitoDevPool: CognitoPoolSettings = {
  region: 'eu-central-1',
  userPoolId: 'eu-central-1_X1SQjEdlb',
  clientAppId: '1le7jifpj9qrvd4et7k6q92j74',
};

const cognitoProdPool: CognitoPoolSettings = {
  region: 'us-west-2',
  userPoolId: 'us-west-2_Lv9rFzXwh',
  clientAppId: '6a9lp5h12fn9bba84he3reem9f',
};

export const termlyId = '4c713548-2d73-4b47-8620-3f370f9bf49b';

export interface OAuthSettings {
  domain: string;
  redirectSignIn: string;
  redirectSignOut: string;
}

const devDomain = 'dev.genieai.tech';
const prodDomain = 'app.genieai.tech';

export type EnvType = 'localhost-dev' | 'dev' | 'prod' | 'localhost-prod';

interface EnvConfiguration {
  apolloDevTools: boolean;
  formDevTools: boolean;
  logFormErrors: boolean;
  authSettings: {
    cognitoPool: CognitoPoolSettings;
    oAuthSettings?: OAuthSettings;
  };
  apiHostnameAndPort: string;
  breakpointTools: boolean;
  clarityId?: string;
  showCookieConsent: boolean;
  showTestIds?: boolean;
  sentryEnvironment: string;
  sentryEnabled: boolean;
}

const localhost = {
  apolloDevTools: true,
  formDevTools: true,
  breakpointTools: true,
  logFormErrors: false,
  apiHostnameAndPort: '',
  showCookieConsent: false,
  showTestIds: true,
  sentryEnvironment: 'localhost',
  // for testing switch domain to "test.genie" and add it to hosts
  sentryEnabled: false,
} as const satisfies Partial<EnvConfiguration>;

const envConfig: Record<EnvType, EnvConfiguration> = {
  prod: {
    apolloDevTools: false,
    formDevTools: false,
    logFormErrors: false,
    breakpointTools: false,
    authSettings: {
      cognitoPool: cognitoProdPool,
      oAuthSettings: {
        domain: 'login.genieai.tech',
        redirectSignIn: 'https://app.genieai.tech',
        redirectSignOut: 'https://app.genieai.tech',
      },
    },
    apiHostnameAndPort: 'https://api.genieai.tech',
    clarityId: 'hbh06yv4c4',
    showCookieConsent: true,
    sentryEnvironment: 'production',
    sentryEnabled: true,
  },
  dev: {
    apolloDevTools: true,
    formDevTools: false,
    breakpointTools: false,
    logFormErrors: false,
    authSettings: {
      cognitoPool: cognitoDevPool,
      oAuthSettings: {
        domain: 'login.dev.genieai.tech',
        redirectSignIn: 'https://dev.genieai.tech',
        redirectSignOut: 'https://dev.genieai.tech',
      },
    },
    apiHostnameAndPort: 'https://api.dev.genieai.tech',
    clarityId: 'hbgzzpvbxd',
    showCookieConsent: true,
    sentryEnvironment: 'development',
    sentryEnabled: true,
  },
  'localhost-dev': {
    ...localhost,
    authSettings: {
      cognitoPool: cognitoDevPool,
      oAuthSettings: {
        domain: 'login.dev.genieai.tech',
        redirectSignIn: 'http://localhost:8080',
        redirectSignOut: 'http://localhost:8080',
      },
    },
  },
  'localhost-prod': {
    ...localhost,
    authSettings: {
      cognitoPool: cognitoProdPool,
      oAuthSettings: {
        domain: 'login.genieai.tech',
        redirectSignIn: 'https://app.genieai.tech',
        redirectSignOut: 'https://app.genieai.tech',
      },
    },
  },
};

const getEnvType = (): EnvType => {
  const mode = import.meta.env.MODE;
  if (mode === 'test') {
    return 'localhost-dev';
  }

  switch (window.location.hostname) {
    case prodDomain:
      return 'prod';
    case devDomain:
      return 'dev';
    default:
      if (import.meta.env.VITE_LOCALHOST_ENVIRONMENT === 'prod') {
        return 'localhost-prod';
      }
      return 'localhost-dev';
  }
};

export const getEnvConfig = (): EnvConfiguration => {
  return envConfig[getEnvType()];
};

export const oAuthResponseType = 'code';
export const oAuthScope = ['email', 'openid', 'aws.cognito.signin.user.admin'];
export const sentryDsn = 'https://c1e2cca49a350986af384ba5a648ddc7@o4506003148439552.ingest.sentry.io/4506383624306688';
