import type { ReactElement } from 'react';
import { useSessionStorage } from 'react-use';

import type { SubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import {
  assetAggregation,
  assetTypeAggregation,
  getGenieGroupAggregations,
  getUserGroupAggregations,
  longShortAggregation,
  subAccountAggregation,
  underlyingAssetAggregation,
  venueAggregation,
} from './PositionAggregationsService.ts';
import { type ISubAccountSnapshotQuery, useSubAccountSnapshotQuery } from '../../../generated/graphql.tsx';
import { useDefaultErrorHandling } from '../../technical/UseDefaultErrorHandling.tsx';
import { calculateBalance, calculateExposure } from '../account/SubAccountPositionsService.ts';
import PortfolioSunburstWithAggregations from './PortfolioSunburstWithAggregations.tsx';

const SAVED_CONFIG_KEY = 'portfolioBalanceExposureSunburst';
export interface SavedConfiguration {
  aggregations: { category: string; active: boolean }[];
}

export type PortfolioSunburstPosition = ISubAccountSnapshotQuery['portfolio']['snapshot'][number]['positions'][number];

const PortfolioSnapshotSunburstContainerContainer = (props: {
  subAccountAssetFilters: SubAccountAssetFilters;
  date: UtcDate;
}): ReactElement => {
  const query = useDefaultErrorHandling(
    useSubAccountSnapshotQuery({
      variables: {
        dates: {
          since: props.date,
          to: props.date,
        },
        subAccountAssetFilters: props.subAccountAssetFilters,
      },
    }),
    {
      loaderFullHeight: true,
    }
  );

  const subAccountSnapshots = query.data?.portfolio.snapshot ?? [];
  const groups = query.data?.assets.assetGroups;

  const aggregations = groups
    ? [
        assetAggregation,
        subAccountAggregation,
        venueAggregation,
        assetTypeAggregation,
        longShortAggregation,
        underlyingAssetAggregation,
        ...getGenieGroupAggregations(groups.genieGroups),
        ...getUserGroupAggregations(groups.userGroups),
      ]
    : [];

  const [savedAggregationsState, setSavedAggregationsState] = useSessionStorage<SavedConfiguration>(
    SAVED_CONFIG_KEY,
    {
      aggregations: [
        {
          category: assetAggregation.category,
          active: true,
        },
      ],
    },
    false
  );

  const positions = subAccountSnapshots.find((snap) => snap.date === props.date)?.positions ?? [];

  return (
    <PortfolioSunburstWithAggregations<PortfolioSunburstPosition>
      positions={positions}
      aggregations={aggregations}
      savedAggregations={savedAggregationsState.aggregations}
      loaded={query.loaded}
      Fallback={query.Fallback}
      onSetAggregationConfig={setSavedAggregationsState}
      calculateBalance={(pos) => calculateBalance(pos)}
      calculateExposure={(pos) => calculateExposure(pos, false)}
    />
  );
};

export default PortfolioSnapshotSunburstContainerContainer;
