import type { ReactElement, ReactNode } from 'react';
import { getEnvConfig } from '../../../config.ts';
import { Box } from '@mui/joy';

const BreakpointTools = ({ children }: { children: ReactNode }): ReactElement => {
  const config = getEnvConfig();
  if (config.breakpointTools) {
    return (
      <>
        <Box
          sx={{
            '&:before': {
              left: '1.5rem',
              content: {
                xs: "'xs'",
                sm: "'sm'",
                md: "'md'",
                lg: "'lg'",
                xl: "'xl'",
              },
              position: 'fixed',
              top: '0.25rem',
              zIndex: 'var(--breakpointtools-z-index)',
              cursor: 'pointer',
            },
          }}
        />
        {children}
      </>
    );
  }

  return <>{children}</>;
};

export default BreakpointTools;
