import { type BigNumber, bignumber } from 'mathjs';
import { usePriceForADayQuery } from '../../generated/graphql.tsx';
import { convertDateInUtcToUTCISODate, isValidDayjsDate } from '../date.utils.ts';
import dayjs, { type Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';

export type MarketAutocompleteQuery =
  | {
      prices: BigNumber[];
      loading: false;
      error: false;
    }
  | {
      loading: true;
      error: boolean;
    }
  | {
      loading: false;
      error: true;
    };

export function useMarketValueAutocomplete(
  time: Dayjs | null | undefined,
  assetId: string | undefined | null
): MarketAutocompleteQuery {
  const isValidDate = !isNil(time) && isValidDayjsDate(time);
  const { data, loading, error } = usePriceForADayQuery({
    variables: {
      assetIds: assetId ? [assetId] : [],
      dates: isValidDate ? [convertDateInUtcToUTCISODate(dayjs(time))] : [],
    },
    skip: !isValidDate || !assetId,
  });

  if (loading) {
    return {
      loading,
      error: error !== undefined,
    };
  }
  if (data === undefined) {
    return {
      loading: false,
      error: true,
    };
  }

  const prices = data.assets?.priceForDay?.at(0)?.rows.map((row) => bignumber(row.price));

  return {
    prices: prices ?? [],
    loading: false,
    error: false,
  };
}
