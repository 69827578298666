import { type ReactNode, useMemo } from 'react';
import { type OptionsWithExtraProps, useSnackbar } from 'notistack';
import { v4 } from 'uuid';
import Notification from './Notification.tsx';
import { calculateErrorMessage } from '../form/GraphQLAPIService.ts';

const hideDurationSeconds = 4;
export interface UseFeedback {
  showInfoMessage(message: string | { msg: string; preventDuplicates?: boolean }): void;
  showSuccessMessage(message: ReactNode): void;
  showErrorMessage(text: string): void;
  showGraphqlError(e: unknown): void;
}

const snackbarAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
} as const;

const createPermanentSettings = (): { key: string; config: OptionsWithExtraProps<'default'> } => {
  const notificationId = v4();
  return {
    key: notificationId,
    config: {
      anchorOrigin: snackbarAnchorOrigin,
      key: notificationId,
      persist: true,
    },
  };
};

const calculatedKeys = new Map<ReactNode, string>();
const createTemporarySettings = (
  message: ReactNode,
  preventDuplicates: boolean
): { key: string; config: OptionsWithExtraProps<'default'> } => {
  let notificationId = v4();
  if (preventDuplicates) {
    const existingId = calculatedKeys.get(message);
    if (existingId) {
      notificationId = existingId;
    } else {
      calculatedKeys.set(message, notificationId);
    }
  }

  return {
    key: notificationId,
    config: {
      anchorOrigin: snackbarAnchorOrigin,
      key: notificationId,
      autoHideDuration: hideDurationSeconds * 1000,
      preventDuplicate: true,
    },
  };
};

export const useFeedback = (): UseFeedback => {
  const { enqueueSnackbar } = useSnackbar();

  return useMemo(
    () => ({
      showInfoMessage: (message: string | { msg: string; preventDuplicates?: boolean }): void => {
        const msg = typeof message === 'string' ? message : message.msg;
        const preventDuplicates = !!(typeof message === 'object' && message.preventDuplicates);
        const { key, config } = createTemporarySettings(msg, preventDuplicates);
        enqueueSnackbar(
          <Notification notificationId={key} variant={'info'}>
            {msg}
          </Notification>,
          config
        );
      },
      showSuccessMessage: (message: ReactNode): void => {
        const { key, config } = createTemporarySettings(message, false);
        enqueueSnackbar(
          <Notification notificationId={key} variant={'success'}>
            {message}
          </Notification>,
          config
        );
      },
      showErrorMessage(message: string): void {
        const { key, config } = createPermanentSettings();
        enqueueSnackbar(
          <Notification notificationId={key} variant={'danger'}>
            {message}
          </Notification>,
          config
        );
      },
      showGraphqlError(e: unknown): void {
        const { key, config } = createPermanentSettings();
        const errorMessage = calculateErrorMessage(e);
        const errorLines = Array.isArray(errorMessage) ? errorMessage : [errorMessage];
        enqueueSnackbar(
          <Notification notificationId={key} variant={'danger'}>
            {errorLines.map((line, i) => (
              <span key={i}>{line}</span>
            ))}
          </Notification>,
          config
        );
      },
    }),
    [enqueueSnackbar]
  );
};
