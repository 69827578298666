import { Stack } from '@mui/joy';
import dayjs, { type Dayjs } from 'dayjs';
import { type FunctionComponent, type ReactElement, Suspense, useState } from 'react';
import { isValidDayjsDate } from 'components/date.utils.ts';
import {
  AccountType,
  calculateAccountType,
  createSubAccountAutocompleteOptions,
} from 'components/portfolio/account/AccountService.tsx';
import { defaultRowSpacing } from 'components/StackSpacing.ts';
import StaticSingleAutocomplete from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete.tsx';
import DateTimeInput from 'components/technical/inputs/date/DateTimeInput.tsx';
import { useSnapshotEditorInputSuspenseQuery, useSnapshotPositionsSuspenseQuery } from 'generated/graphql.tsx';
import { PositionEditorForm, type SubAccountValue } from './PositionEditorForm.tsx';
import { formatISODate } from '../../formatter.utils.ts';
import Loader from 'components/technical/Loader/Loader.tsx';
import Message from '../../technical/Message.tsx';
import { useGenerateKeyOnValueChanged } from '../../UseGenerateKeyOnValueChanged.tsx';

const PositionsEditorContainer: FunctionComponent = () => {
  const inputQueryResult = useSnapshotEditorInputSuspenseQuery();
  const [selectedSnapshotDate, setSelectedSnapshotDate] = useState<Dayjs | null>(null);
  const [selectedSubAccount, setSelectedSubAccount] = useState<SubAccountValue | null>(null);

  const custodianAccounts = inputQueryResult.data.portfolio.accounts.filter((account) =>
    [AccountType.Custodian, AccountType.Blockchain].includes(calculateAccountType(account))
  );

  const minDate = selectedSubAccount ? dayjs.utc(selectedSubAccount.createdAt) : undefined;

  const isValidDate = isValidDayjsDate(selectedSnapshotDate);
  const hasAllValidParameters = selectedSnapshotDate && selectedSubAccount && isValidDate;

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={defaultRowSpacing} alignItems="flex-end" flexWrap="wrap">
        <StaticSingleAutocomplete
          name="subAccountIds"
          {...createSubAccountAutocompleteOptions(custodianAccounts)}
          label="Sub-account"
          width="normal"
          value={selectedSubAccount}
          onChange={setSelectedSubAccount}
        />
        <DateTimeInput
          label="Date"
          name="date"
          width="normal"
          onChange={setSelectedSnapshotDate}
          value={selectedSnapshotDate}
          showTime={false}
          minDate={minDate}
          error={!isValidDate ? 'Invalid date' : ''}
          maxDate={dayjs.utc().subtract(1, 'day')}
          disabled={!selectedSubAccount}
        />
      </Stack>
      {hasAllValidParameters ? (
        <Suspense fallback={<Loader />}>
          <PositionsEditor date={selectedSnapshotDate} subAccount={selectedSubAccount} />
        </Suspense>
      ) : undefined}
    </Stack>
  );
};

const PositionsEditor = ({ date, subAccount }: { date: Dayjs; subAccount: SubAccountValue }): ReactElement => {
  const positionsQueryResult = useSnapshotPositionsSuspenseQuery({
    variables: {
      date: formatISODate(date),
      subAccountId: subAccount.id,
      exchange: subAccount.account.venue.label,
    },
  });

  const snapshot = positionsQueryResult.data.portfolio.snapshot;
  const dataKey = useGenerateKeyOnValueChanged({
    subAccount,
    date,
  });
  if (snapshot.length === 0) {
    return <Message>No snapshot available for selected date.</Message>;
  }

  if (snapshot.length > 1) {
    return <Message>Error: Multiple snapshots found for selected date. Should be only 1.</Message>;
  }

  return (
    <PositionEditorForm
      key={dataKey}
      date={date}
      subAccount={subAccount}
      positions={snapshot.at(0)?.positions ?? []}
      assets={positionsQueryResult.data.assets.list}
    />
  );
};

export default PositionsEditorContainer;
