// more minimal version of https://github.com/olahol/scrollparent.js/blob/master/scrollparent.js
const regex = /(auto|scroll)/;

const style = (node: Element, prop: string) => getComputedStyle(node, null).getPropertyValue(prop);

const scroll = (node: Element) => regex.test(style(node, 'overflow') + style(node, 'overflow-y'));

const findScrollableParent = (node: Element | null): Element =>
  !node || node === document.body
    ? document.body
    : scroll(node)
      ? node
      : findScrollableParent(node.parentNode as Element);

export default findScrollableParent;
