import type { ReactElement } from 'react';
import { sortBy } from 'lodash/fp';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';
import { Stack } from '@mui/joy';
import PortfolioSummary from './PortfolioSummary.tsx';
import PortfolioReturnsGrid from './PortfolioReturnsGrid.tsx';
import PortfolioReturnsChart from './PortfolioReturnsChart.tsx';
import type { PortfolioReturnsSectionProps } from './PortfolioReturnsSectionProps.tsx';

const PortfolioReturnsSection = ({ analysis, enableAi, summaryQuery }: PortfolioReturnsSectionProps): ReactElement => {
  const sortedAnalysis = sortBy(
    (pd) => pd.name,
    analysis.map((analysis) => analysis.portfolioDefinition)
  );

  return (
    <Stack gap={defaultRowSpacing}>
      <PortfolioReturnsChart analysis={analysis} />
      {enableAi &&
        (summaryQuery.loaded ? (
          <PortfolioSummary portfolios={sortedAnalysis} summary={summaryQuery.data.portfolio.portfolioSummary} />
        ) : (
          <summaryQuery.Fallback />
        ))}
      <PortfolioReturnsGrid analysis={analysis} />
    </Stack>
  );
};

export default PortfolioReturnsSection;
