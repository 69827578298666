import Close from '@mui/icons-material/Close';
import { IconButton, Stack, SvgIcon, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import { type ReactElement, Suspense, useContext, useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import CloseModalContext from '@mui/joy/Modal/CloseModalContext';
import Loader from '../../technical/Loader/Loader.tsx';
import ConversationTab from './conversation/ConversationTab.tsx';
import HistoryTab from './history/HistoryTab.tsx';

const iconSize = 'md' as const;

const AgentDrawer = (): ReactElement => {
  const closeModalContext = useContext(CloseModalContext);
  const [convId, setConvId] = useState<undefined | string>();
  const [activeTab, setActiveTab] = useState(0);
  return (
    <Tabs
      size={'md'}
      sx={{
        height: '100%',
      }}
      value={activeTab}
      onChange={(_event, value) => setActiveTab(value as number)}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'stretch'}
        flexShrink={0}
        sx={(theme) => ({
          height: theme.spacing(9),
          px: 1,
        })}
      >
        <TabList
          disableUnderline
          sx={{
            flexGrow: 1,
          }}
        >
          <Tab>Chat</Tab>
          <Tab>History</Tab>
        </TabList>

        <Stack direction={'row'} alignItems={'center'} gap={0.5}>
          <IconButton
            size={iconSize}
            onClick={() => {
              setActiveTab(0);
              setConvId(undefined);
            }}
          >
            <SvgIcon component={IconPlus} />
          </IconButton>
          <IconButton
            size={iconSize}
            onClick={(e): void => {
              closeModalContext?.(e, 'closeClick');
            }}
          >
            <Close />
          </IconButton>
        </Stack>
      </Stack>
      <Stack
        flexGrow={1}
        sx={{
          backgroundColor: 'background.level1',
          minHeight: 0,
        }}
      >
        <Suspense fallback={<Loader fullHeight />}>
          <TabPanel value={0} keepMounted sx={{ overflow: 'auto' }}>
            <ConversationTab conversationId={convId} onConversationStarted={(convId) => setConvId(convId)} />
          </TabPanel>
          <TabPanel value={1} sx={{ overflow: 'auto', overscrollBehavior: 'contain' }}>
            <HistoryTab
              onConversationSelected={(convId) => {
                setConvId(convId);
                setActiveTab(0);
              }}
              onConversationDeleted={(deletedConvId) => {
                if (convId !== deletedConvId) {
                  return;
                }

                setConvId(undefined);
              }}
            />
          </TabPanel>
        </Suspense>
      </Stack>
    </Tabs>
  );
};

export default AgentDrawer;
