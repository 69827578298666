const impersonatedUserIdKey = 'impersonatedUserIdKey';

window.addEventListener('storage', (event) => {
  if (event.key === impersonatedUserIdKey && event.newValue !== event.oldValue) {
    console.debug('impersonation change in another tab', event.oldValue, ' -> ', event.newValue);
    // as localStorage shared across tabs we need to reload the page to keep data consistent with the new user
    window.location.reload();
  }
});

export const saveImpersonatedUserId = (id: string): void => {
  const impersonationId = localStorage.getItem(impersonatedUserIdKey);
  if (impersonationId !== id) {
    console.log('Saving impersonation userId', id); // eslint-disable-line no-console
    localStorage.setItem(impersonatedUserIdKey, id);
  }
};

export const clearImpersonatedUserId = (): void => {
  const impersonationId = localStorage.getItem(impersonatedUserIdKey);
  if (impersonationId) {
    console.log('Clearing impersonation userId'); // eslint-disable-line no-console
    localStorage.removeItem(impersonatedUserIdKey);
  }
};

export const getSavedImpersonatedUserId = (): string | null => {
  const impersonationUserId = localStorage.getItem(impersonatedUserIdKey);
  if (impersonationUserId) {
    return impersonationUserId;
  }

  return null;
};
