import { Typography } from '@mui/joy';
import { type CellContext, type ColumnDef, createColumnHelper } from '@tanstack/table-core';
import dayjs from 'dayjs';
import { bignumber } from 'mathjs';
import type { ReactElement, ReactNode } from 'react';

import { useSearchableAssetsIds } from 'components/technical/Appbar/AppbarSearch/useSearchableAssets';
import GTable from 'components/technical/GTable/GTable.tsx';
import DeleteDialogButton from 'components/technical/inputs/GButton/DeleteDialogButton';
import { useUserTimezone } from 'components/technical/UseUserTimezone.tsx';
import {
  IAction,
  type IInvestmentsInputQuery,
  IInvestmentStatus,
  useDeleteInvestmentMutation,
} from '../../../generated/graphql';
import { DateTimeFormat, formatDate, formatNumber, formatPercentage } from '../../formatter.utils';
import type { IAssetValueToAssetValue } from '../../market/asset/Asset.types';
import AssetLabel from '../../market/asset/AssetLabel';
import AssetValueView from '../../market/asset/AssetValueView';
import { SubAccountLabel } from '../../portfolio/account/SubAccountLabel.tsx';

type InvestmentRawRow = IInvestmentsInputQuery['bookkeeping']['investments']['list'][number];
export type Investment = {
  [key in keyof InvestmentRawRow]: IAssetValueToAssetValue<InvestmentRawRow[key]>;
};

const statusLabels: Record<IInvestmentStatus, string> = {
  [IInvestmentStatus.Pending]: 'Vesting',
  [IInvestmentStatus.Succeeded]: 'Vested',
};

const InvestmentList = ({
  investments,
  refetch,
}: {
  investments: Investment[];
  refetch: () => Promise<unknown>;
}): ReactElement => {
  const [deleteInvestment] = useDeleteInvestmentMutation();
  const navigableAssetIds = useSearchableAssetsIds();
  const timezone = useUserTimezone();

  const columnHelper = createColumnHelper<Investment>();
  const columns: ColumnDef<Investment>[] = [
    {
      cell: (props: CellContext<Investment, unknown>): ReactNode =>
        formatDate(props.row.original.executedAt, DateTimeFormat.DateTime, timezone),
      header: 'Date time',
      accessorFn: (value) => dayjs(value.executedAt).toDate(),
    },
    {
      header: 'Name',
      accessorKey: 'name',
    },
    columnHelper.display({
      header: 'Sub-account',
      cell: (props: CellContext<Investment, unknown>): ReactElement => {
        return <SubAccountLabel subAccount={props.row.original.subAccount} />;
      },
    }),
    {
      header: 'Buy amount',
      accessorFn: (tran: Investment): string => {
        return formatNumber(tran.credit.amount);
      },
    },
    columnHelper.display({
      header: 'Buy currency',
      cell: (props: CellContext<Investment, unknown>): ReactNode => {
        const inv = props.row.original;
        return <AssetLabel asset={inv.credit.asset} link={navigableAssetIds.has(inv.credit.asset.id)} />;
      },
    }),
    {
      header: 'Sell amount',
      accessorFn: (inv: Investment): string => {
        return formatNumber(inv.debit.amount);
      },
    },
    columnHelper.display({
      header: 'Sell currency',
      cell: (props: CellContext<Investment, unknown>): ReactNode => {
        const inv = props.row.original;
        return <AssetLabel asset={inv.debit.asset} link={navigableAssetIds.has(inv.debit.asset.id)} />;
      },
    }),
    {
      header: 'Fee',
      cell: (props: CellContext<Investment, unknown>): ReactNode => {
        const inv = props.row.original;
        if (!inv.fee) {
          return '-';
        }

        return <AssetValueView asset={inv.fee.asset} value={inv.fee.amount} link={false} />;
      },
    },
    {
      header: 'Vested amount',
      accessorFn: (inv: Investment): string => {
        return formatPercentage(bignumber(inv.vestedAmount).div(bignumber(inv.credit.amount)));
      },
    },
    {
      header: 'Status',
      cell: (props: CellContext<Investment, unknown>): ReactNode => {
        const inv = props.row.original;
        return (
          <Typography color={inv.status === IInvestmentStatus.Pending ? 'warning' : 'success'}>
            {statusLabels[inv.status]}
          </Typography>
        );
      },
    },
    columnHelper.display({
      header: 'Actions',
      meta: {
        headerStyles: {
          width: '100px',
        },
      },
      cell: (props: CellContext<Investment, unknown>): ReactNode => {
        const canDelete = props.row.original.actions.includes(IAction.Delete);
        if (!canDelete) {
          return null;
        }

        return (
          <DeleteDialogButton
            deletedMessage="Investment successfully deleted"
            confirmationMessage="Are you sure you want to remove investment?"
            deleteItem={(): Promise<unknown> =>
              deleteInvestment({
                variables: {
                  id: props.row.original.id,
                },
              })
            }
            onCompleted={refetch}
          />
        );
      },
    }),
  ];

  return <GTable columns={columns} data={investments} />;
};

export default InvestmentList;
