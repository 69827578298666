import { createTheme, type Theme } from '@mui/material';
import type { FontSize } from '@mui/joy/styles/types';
const joyIconNames: (keyof FontSize)[] = ['xs', 'sm', 'md', 'lg', 'xl', 'xl2', 'xl3', 'xl4'] as const;

export const createMaterialUiTheme = (mode: 'light' | 'dark'): Theme =>
  createTheme({
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: ({ ownerState }) => {
            // by default, materialui uses it's own wrapper for icons and doesnt understand fontSizes passed via joyui
            // this override takes props from joyui and directly translates them to joy variables
            if (joyIconNames.includes(ownerState.fontSize as keyof FontSize)) {
              return {
                fontSize: `var(--Icon-fontSize, var(--joy-fontSize-${ownerState.fontSize}))`,
              };
            }

            // if icons is rendered as part of material ui component, the props contain correct font sizes
            return {};
          },
        },
      },
    },
    palette: {
      mode,
    },
    typography: {
      fontFamily:
        'var(--joy-fontFamily-body, "Public Sans", var(--joy-fontFamily-fallback, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"))',
    },
  });
