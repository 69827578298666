import { Modal, ModalDialog, Stack } from '@mui/joy';
import { type FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  createAccountAutocompleteOptions,
  type CreateSubAccountIdAutocompleteOptionsInputAccount,
} from 'components/portfolio/account/AccountService.tsx';
import GFormProvider from 'components/technical/form/GFormProvider';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage';
import gYupResolver from 'components/technical/form/gYupResolver';
import SubmitButton from 'components/technical/form/SubmitButton';
import { type GraphQlErrorHandler, useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import StaticSingleAutocomplete from 'components/technical/inputs/Autocomplete/StaticSingleAutocomplete';
import FormSelectedChipsBoard from 'components/technical/form/FormSelectedChipsBoard';
import GButton from 'components/technical/inputs/GButton/GButton';
import { defaultRowSpacing } from 'components/StackSpacing';
import type { SubFund } from './SubFundsDashboard';
import { Edit } from '@mui/icons-material';
import { sortBy } from 'lodash/fp';
import { AccountLabel } from '../account/AccountLabel';
import { IconVariant } from 'components/market/asset/cryptocurrencies/CryptocurrenciesData';

const formSchema = yup.object({
  accounts: yup.array().of(yup.mixed().required()).required().min(1),
});

type SubFundFormState = {
  accounts: SubFund['accounts'];
};

type CreateUpdateSubFundDialogProps = {
  onClose: () => void;
  accounts: CreateSubAccountIdAutocompleteOptionsInputAccount[];
  handleFormSubmit: (data: SubFundFormState, graphQlErrorHandler: GraphQlErrorHandler) => Promise<void>;
  initialState: SubFund;
};

const ChangeSubFundAccountsDialog: FunctionComponent<CreateUpdateSubFundDialogProps> = (props) => {
  const methods = useForm<SubFundFormState>({
    resolver: gYupResolver(formSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      accounts: sortBy((a) => a.name, props.initialState.accounts),
    },
  });
  const selectedAccounts = methods.watch('accounts');
  const availableAccounts = props.accounts.filter((account) => !selectedAccounts.map((a) => a.id).includes(account.id));

  const availableToAddAccountOptions = createAccountAutocompleteOptions(availableAccounts);

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const [autocompleteClearKey, setAutocompleteClearKey] = useState(0);

  return (
    <Modal open onClose={props.onClose}>
      <ModalDialog layout="center" size="lg">
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit((data) => props.handleFormSubmit(data, onErrorAndThrow))}>
            <GDialogHeader>{props.initialState.name}</GDialogHeader>
            <Stack spacing={3} alignItems="center">
              <Stack direction="row" gap={17} maxWidth={'100%'}>
                <StaticSingleAutocomplete
                  value={null}
                  key={autocompleteClearKey} // to clear input after account selected, value=null works only on blur too late
                  label="Accounts"
                  placeholder="Search"
                  width="xl2"
                  menuWidth="xl3"
                  showChipTooltips
                  {...availableToAddAccountOptions}
                  onChange={(accountToAdd) => {
                    if (accountToAdd) {
                      methods.setValue('accounts', methods.getValues('accounts').concat(accountToAdd), {
                        shouldValidate: true,
                      });
                      setAutocompleteClearKey((prevAutocompleteClearKey) => prevAutocompleteClearKey + 1);
                    }
                  }}
                />
                <FormSelectedChipsBoard<SubFundFormState>
                  name="accounts"
                  label={`${props.initialState.name} Accounts`}
                  placeholder="Accounts"
                  height={300}
                  width="xl4"
                  getItemKey={(account) => account.id}
                  renderItem={(account) => <AccountLabel account={account} size={IconVariant.MEDIUM} wrap={false} />}
                />
              </Stack>
              <Stack width="100%">
                <GraphQLApiFormErrorMessage />
                <Stack direction="row" justifyContent="center" spacing={defaultRowSpacing}>
                  <GButton width="normal" variant="outlined" onClick={props.onClose}>
                    Cancel
                  </GButton>
                  <SubmitButton width="normal" startDecorator={<Edit />}>
                    Save
                  </SubmitButton>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};

export default ChangeSubFundAccountsDialog;
