import { Stack } from '@mui/joy';
import type { ColumnDef } from '@tanstack/react-table';
import { type CellContext, createColumnHelper } from '@tanstack/table-core';
import dayjs from 'dayjs';
import { bignumber } from 'mathjs';
import type { ReactElement, ReactNode } from 'react';
import GTable from 'components/technical/GTable/GTable';

import { Pencil } from 'components/technical/icons';
import DeleteDialogButton from 'components/technical/inputs/GButton/DeleteDialogButton';
import DialogButton from 'components/technical/inputs/GButton/DialogButton';

import {
  AssetPriceChartDocument,
  type IAssetPrice,
  useDeleteAssetPriceMutation,
} from '../../../../../../generated/graphql';
import { DateTimeFormat, formatCash, formatDate } from '../../../../../formatter.utils';
import { UpdateAssetPriceDialog } from '../price/UpdateAssetPriceDialog';

interface DenormalizedPriceRow {
  asset: { id: string; name: string };
  price: string;
  date: UtcDate;
}

export const PrivateAssetPriceList = ({
  prices,
  quoteAsset,
}: {
  prices: (Omit<IAssetPrice, 'asset'> & { asset: { id: string; name?: string | null; symbol: string } })[];
  quoteAsset: { id: string };
}): ReactElement => {
  const denormalizedPrices = prices.flatMap((assetPrice): DenormalizedPriceRow[] =>
    assetPrice.rows.map((row) => ({
      asset: {
        ...assetPrice.asset,
        name: assetPrice.asset.name ?? assetPrice.asset.symbol,
      },
      ...row,
    }))
  );

  const [deleteAssetPrice] = useDeleteAssetPriceMutation({
    ignoreResults: true,
  });

  const columnHelper = createColumnHelper<DenormalizedPriceRow>();
  const columns: ColumnDef<DenormalizedPriceRow>[] = [
    {
      header: 'Date',
      cell: (ctx: CellContext<DenormalizedPriceRow, unknown>): ReactNode => {
        return formatDate(ctx.row.original.date, DateTimeFormat.Date);
      },
      accessorFn: (value) => dayjs.utc(value.date.toString()).toDate(),
    },
    {
      header: 'Asset',
      accessorKey: 'asset.name',
    },
    {
      header: 'Price',
      cell: (ctx: CellContext<DenormalizedPriceRow, unknown>): ReactNode => formatCash(ctx.row.original.price),
      accessorFn: (value) => bignumber(value.price).toNumber(),
    },
    columnHelper.display({
      header: 'Actions',
      meta: {
        headerStyles: {
          width: '200px',
        },
      },
      cell: (ctx: CellContext<DenormalizedPriceRow, unknown>) => (
        <Stack direction="row" spacing={1.5} justifyContent="flex-end" width="100%">
          {/* jscpd:ignore-start */}
          <DialogButton
            variant="plain"
            renderDialog={({ onClose }): ReactElement => (
              <UpdateAssetPriceDialog
                onClose={onClose}
                onUpdate={onClose}
                asset={ctx.row.original.asset}
                quoteAsset={quoteAsset}
                date={ctx.row.original.date}
              />
            )}
            startDecorator={<Pencil />}
          >
            Edit
          </DialogButton>
          {/* jscpd:ignore-end */}
          <DeleteDialogButton
            deletedMessage={'Asset price successfully deleted'}
            confirmationMessage={<>Are you sure you want to delete asset {ctx.row.original.asset.name} price?</>}
            deleteItem={(): Promise<unknown> =>
              deleteAssetPrice({
                variables: {
                  input: {
                    date: ctx.row.original.date,
                    baseAssetId: ctx.row.original.asset.id,
                    quoteAssetId: quoteAsset.id,
                  },
                },
                refetchQueries: [AssetPriceChartDocument],
              })
            }
          />
        </Stack>
      ),
    }),
  ];

  return <GTable columns={columns} data={denormalizedPrices} />;
};
