import type { ReactElement } from 'react';
import NavigableTab from 'components/technical/NavigableTabs/NavigableTab';
import NavigableTabs from 'components/technical/NavigableTabs/NavigableTabs';
import { useAuth } from '../../../UseAuth.tsx';

const ProfitAndLossDashboard = (): ReactElement => {
  const { isAdmin } = useAuth();
  return (
    <NavigableTabs fullHeight>
      <NavigableTab link="/app/portfolio/profit-loss/performance" text="Performance" />
      <NavigableTab link="/app/portfolio/profit-loss/attribution" text="Attribution" />
      <NavigableTab link="/app/portfolio/profit-loss/asset-contribution" text="Contribution by asset" />
      <NavigableTab link="/app/portfolio/profit-loss/realized-pnl" text="Realized P&L" />
      <NavigableTab link="/app/portfolio/profit-loss/daily-pnl" text="Daily P&L" />
      {isAdmin && <NavigableTab link="/app/portfolio/profit-loss/unified-pnl" text="Unified P&L*" />}
    </NavigableTabs>
  );
};

export default ProfitAndLossDashboard;
