const listeners: ((input: TermlyCookieWhitelist) => void)[] = [];
window.getUpdatedCookieWhitelistByTermly = (input: TermlyCookieWhitelist): void => {
  for (const listener of listeners) {
    try {
      listener(input);
    } catch (e) {
      console.error('Cookie updater failed', e);
    }
  }
};

export const addConsentUpdateListener = (listener: (input: TermlyCookieWhitelist) => void): void => {
  listeners.push(listener);
};
