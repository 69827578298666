import isNil from 'lodash/fp/isNil';

import { type FormAssetValue, type FormState, nullIfNoValueOption } from './TransactionForm.validation.ts';
import type {
  IAssetValueInput,
  ITradeInput,
  ITransactionStatus,
  ITransactionType,
} from '../../../generated/graphql.tsx';

const convertAssetValue = (assetValue: FormAssetValue): IAssetValueInput => {
  return {
    assetId: assetValue.asset!.id,
    amount: assetValue.amount!.toString(),
    unitValue: isNil(assetValue.marketValue) ? undefined : (assetValue.marketValue / assetValue.amount!).toString(),
  };
};

export const emptyToUndefined = <T>(value: T[] | undefined): T[] | undefined =>
  value && value.length > 0 ? value : undefined;

interface TransactionFormOutput {
  credit: IAssetValueInput | null;
  debit: IAssetValueInput | null;
  executedAt: string;
  externalId: string | null;
  fee: IAssetValueInput | null;
  status: ITransactionStatus | null;
  trade: ITradeInput | null;
  type: ITransactionType;
  subType: string;
  tags: string[];
  comment: string;
}

export const getTransactionOutput = (
  data: FormState,
  shouldShowBuy: boolean,
  shouldShowSell: boolean
): TransactionFormOutput => {
  return {
    type: data.type,
    subType: data.subType,
    executedAt: data.executedAt!.toISOString(),
    externalId: data.externalId ? data.externalId : null,
    status: data.status ?? null,
    trade: nullIfNoValueOption(data.trade.type)
      ? {
          type: nullIfNoValueOption(data.trade.type),
          order: {
            type: nullIfNoValueOption(data.trade.orderType),
            side: nullIfNoValueOption(data.trade.orderSide),
          },
        }
      : null,
    credit: shouldShowBuy ? convertAssetValue(data.buy) : null,
    debit: shouldShowSell ? convertAssetValue(data.sell) : null,
    fee: !isNil(data.fee) ? convertAssetValue(data.fee) : null,
    tags: data.tags,
    comment: data.comment,
  };
};
