import type { Dayjs } from 'dayjs';
import { useEffect } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import type { ObjectSchema } from 'yup';

import { IOrderSide, IOrderType, ITradeType, ITransactionStatus, ITransactionType } from '../../../generated/graphql';
import { isDayjsDateNotInTheFuture, isValidDayjsDate } from '../../date.utils';
import type { AssetSelectOptionValue } from '../../market/asset/AssetService.tsx';

export type AssetField = {
  [Prop in keyof Required<FormState>]: FormState[Prop] extends FormAssetValue ? NonNullable<Prop> : never;
}[keyof FormState];

export interface FormAssetValue {
  asset: Omit<AssetSelectOptionValue, 'priceAsset'> | undefined;
  amount: number | undefined;
  marketValue: number | undefined;
}

export interface FormState {
  type: ITransactionType;
  subType: string;
  subAccount?: string;
  executedAt?: Dayjs;
  buy: FormAssetValue;
  sell: FormAssetValue;
  fee: FormAssetValue;
  externalId: string;
  status: ITransactionStatus | undefined | null;
  trade: {
    type?: ITradeType | 'noValue';
    orderType?: IOrderType | 'noValue';
    orderSide?: IOrderSide | 'noValue' | null;
  };
  tags: string[];
  comment: string;
}

export const noValueOption = {
  label: 'No value',
  value: 'noValue',
  key: 'No value',
} as const;

export const nullIfNoValueOption = <T>(value: T | 'noValue'): T | null => {
  if (value === noValueOption.value) {
    return null;
  }

  return value;
};

export const transactionTypes = Object.entries(ITransactionType).map(([name, value]) => ({
  label: name,
  value: value,
  key: name,
}));

export const orderTypes = [
  ...Object.entries(IOrderType).map(([name, value]) => ({
    label: name,
    value: value,
    key: name,
  })),
  noValueOption,
];

export const orderSide = [
  ...Object.entries(IOrderSide).map(([name, value]) => ({
    label: name,
    value: value,
    key: name,
  })),
  noValueOption,
];

export const hasNonEmptyAmount = (assetValue: {
  amount: string | undefined;
  marketValue: string | undefined;
}): boolean => {
  return (assetValue.amount ?? '') !== '' || (assetValue.marketValue ?? '') !== '';
};

const clearAssetErrors = (
  prefix: AssetField,
  shouldValidate: boolean,
  methods: UseFormReturn<FormState, unknown>
): void => {
  useEffect(() => {
    if (!shouldValidate) {
      methods.clearErrors(prefix);
      methods.clearErrors(`${prefix}.marketValue`);
    }
  }, [prefix, shouldValidate, methods]);
};

export type AssetBuySellVisibility = { shouldShowBuy: boolean; shouldShowSell: boolean };

export const clearAssetVisibilityErrors = (
  methods: UseFormReturn<FormState, unknown>,
  visibility: AssetBuySellVisibility,
  shouldValidateFee: boolean
): void => {
  clearAssetErrors('buy', visibility.shouldShowBuy, methods);
  clearAssetErrors('sell', visibility.shouldShowSell, methods);
  clearAssetErrors('fee', shouldValidateFee, methods);
};

export const calculateAssetFeeVisibility = (fee: {
  amount: string | undefined;
  marketValue: string | undefined;
}): boolean => {
  return hasNonEmptyAmount(fee);
};

export const isITransactionType = (type: unknown): type is ITransactionType =>
  Object.values(ITransactionType).includes(type as ITransactionType);

export const calculateAssetBuySellVisibility = (transactionType: ITransactionType): AssetBuySellVisibility => {
  const shouldValidateBuy = [
    ITransactionType.Trade,
    ITransactionType.Transfer,
    ITransactionType.Deposit,
    ITransactionType.Income,
  ].includes(transactionType);

  const shouldValidateSell = [
    ITransactionType.Trade,
    ITransactionType.Withdrawal,
    ITransactionType.Fee,
    ITransactionType.Loss,
  ].includes(transactionType);

  return {
    shouldShowSell: shouldValidateSell,
    shouldShowBuy: shouldValidateBuy,
  };
};

export const statusTypes = Object.entries(ITransactionStatus).map(([name, value]) => ({
  label: name,
  value: value,
  key: name,
}));

export const tradeTypes = [
  ...Object.entries(ITradeType).map(([name, value]) => ({
    label: name,
    value: value,
    key: name,
  })),
  noValueOption,
];

export const requiredAssetSchema = yup.object({
  asset: yup.mixed().required(),
  amount: yup.number().required().positive(),
  marketValue: yup
    .number()
    .positive()
    .when('asset', ([asset], schema) => {
      if (!asset) {
        return schema;
      }
      return schema.required();
    }),
});

export const formSchema: ObjectSchema<object> = yup.object({
  type: yup.string().required().oneOf(Object.values(ITransactionType)),
  subAccount: yup.mixed().required(),
  executedAt: yup
    .mixed()
    .required()
    .test('valid-date', 'Date is invalid', isValidDayjsDate)
    .test('future-date', 'Date cannot be in the future', isDayjsDateNotInTheFuture),
  buy: yup.mixed().when('type', ([type]: unknown[], schema) => {
    if (!isITransactionType(type)) {
      return schema.nullable().optional();
    }

    const visibility = calculateAssetBuySellVisibility(type);
    if (visibility.shouldShowBuy) {
      return requiredAssetSchema;
    }

    return schema.nullable().optional();
  }),
  sell: yup.mixed().when('type', ([type]: unknown[], schema) => {
    if (!isITransactionType(type)) {
      return schema.nullable().optional();
    }

    const visibility = calculateAssetBuySellVisibility(type);
    if (visibility.shouldShowSell) {
      return requiredAssetSchema;
    }

    return schema.nullable().optional();
  }),
  fee: yup
    .mixed()
    .transform((currentValue: { amount: string | undefined; marketValue: string | undefined }): unknown | null => {
      if (calculateAssetFeeVisibility(currentValue)) {
        return currentValue;
      }

      return null;
    })
    .concat(requiredAssetSchema)
    .nullable(),
  externalId: yup.string(),
  status: yup.string().oneOf(Object.values(ITransactionStatus)).required(),
  trade: yup.mixed().when('type', ([type]: unknown[], schema) => {
    if (type !== ITransactionType.Trade) {
      return schema;
    }

    return yup.object({
      type: yup.string().oneOf(tradeTypes.map(({ value }) => value)),
      orderType: yup.string().when('type', ([type], schema) => {
        if (!type || type === noValueOption.value) {
          return schema.oneOf(orderTypes.map(({ value }) => value));
        }

        const errorValueText = 'Invalid value for provided trade type';
        if (type === ITradeType.Maker) {
          return schema.oneOf([IOrderType.Limit], errorValueText);
        }

        return schema.oneOf([IOrderType.Market], errorValueText);
      }),
      orderSize: yup.string().oneOf(orderSide.map(({ value }) => value)),
    });
  }),
});
