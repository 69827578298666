import { Amplify } from 'aws-amplify';

import { oAuthResponseType, oAuthScope, getEnvConfig } from '../config';

const envConfig = getEnvConfig();
const { cognitoPool: creds, oAuthSettings } = envConfig.authSettings;
console.debug('Using cognito client id: ', creds.clientAppId);

Amplify.configure({
  Auth: {
    region: creds.region,
    userPoolId: creds.userPoolId,
    userPoolWebClientId: creds.clientAppId,
  },
  ...(oAuthSettings
    ? {
        oauth: {
          domain: oAuthSettings.domain,
          scope: oAuthScope,
          redirectSignIn: oAuthSettings.redirectSignIn,
          redirectSignOut: oAuthSettings.redirectSignOut,
          clientId: creds.clientAppId,
          responseType: oAuthResponseType,
        },
      }
    : {}),
});
