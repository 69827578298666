import type { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import { dateReadableValueGetter } from 'components/technical/grids/agGrid.utils.tsx';
import { type IUnifiedPnlReportQuery, useUnifiedPnlReportSuspenseQuery } from 'generated/graphql';
import { nameColumn, symbolColumn, underlyingAssetColumn } from 'components/technical/grids/SharedReportColumns';
import { bignumber } from 'mathjs';
import type { ReactElement } from 'react';
import GAgGridPresets from '../../../technical/grids/GAgGridPresets.tsx';
import { UserSettings } from '../../../management/UserSettings.types.ts';
import { presets } from './unifiedPnlGridPresets.ts';

type RowData = IUnifiedPnlReportQuery['portfolio']['unifiedPnl'][number];

const UnifiedPnlGrid = ({ reportId }: { reportId: number }): ReactElement => {
  const report = useUnifiedPnlReportSuspenseQuery({
    variables: {
      reportId,
    },
  });

  const columns: (ColDef<RowData> | ColGroupDef<RowData>)[] = [
    {
      headerName: 'Date',
      field: 'date',
      type: 'dateColumn',
      initialSort: 'desc',
      valueGetter: (params: ValueGetterParams<RowData>): string | undefined =>
        params.data ? dateReadableValueGetter(params.data.date) : undefined,
    },
    {
      headerName: 'Asset Details',
      colId: 'asset-details',
      marryChildren: true,
      children: [nameColumn({ initialHide: false }), symbolColumn(), underlyingAssetColumn()],
    },
    {
      headerName: 'Sub-fund',
      colId: 'sub-fund',
      type: 'textColumn',
      valueGetter: (params: ValueGetterParams<RowData>): string | undefined => {
        if (!params.data) {
          return undefined;
        }

        return params.data.subFund?.name ?? 'Organization';
      },
    },
    {
      headerName: 'Amount',
      type: ['numericColumn', 'extendedNumericColumn'],
      valueGetter: (params): number | undefined => {
        if (!params.data?.amount) {
          return undefined;
        }
        return bignumber(params.data?.amount).toNumber();
      },
    },
    {
      headerName: 'Price',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params): number | undefined => {
        if (!params.data?.price) {
          return undefined;
        }
        return bignumber(params.data?.price).toNumber();
      },
    },
    {
      headerName: 'AvgPrice',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params): number | undefined => {
        if (!params.data?.avgPrice) {
          return undefined;
        }
        return bignumber(params.data?.avgPrice).toNumber();
      },
    },
    {
      headerName: 'Realized P&L',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params): number | undefined => {
        if (!params.data?.rpnl) {
          return undefined;
        }
        return bignumber(params.data?.rpnl).toNumber();
      },
    },
    {
      headerName: 'Unrealized P&L',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params): number | undefined => {
        if (!params.data?.upnl) {
          return undefined;
        }
        return bignumber(params.data?.upnl).toNumber();
      },
    },
  ];

  return (
    <GAgGridPresets
      rowData={report.data.portfolio.unifiedPnl}
      presetSettingsKey={UserSettings.UnifiedPnlReportPresets}
      defaultPresets={presets}
      sideBar={{
        toolPanels: ['columns', 'filters'],
      }}
      enableCharts
      enableRangeSelection
      autoSizeStrategy={{ type: 'fitCellContents' }}
      columnDefs={columns}
    />
  );
};

export default UnifiedPnlGrid;
