import { Stack } from '@mui/joy';
import GAgGridPreserveState from 'components/technical/grids/GAgGridPreserveState.tsx';
import type { FunctionComponent } from 'react';
import { UserSettings } from 'components/management/UserSettings.types.ts';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling.tsx';
import { type ICounterpartyRiskReportQuery, useCounterpartyRiskReportQuery } from 'generated/graphql.tsx';

import {
  accountColumn,
  amountColumn,
  balanceColumn,
  exposureColumn,
  nameColumn,
  sideColumn,
  subAccountColumn,
  subFundGroupColumn,
  symbolColumn,
  typeColumn,
  underlyingAssetColumn,
  venueColumn,
} from '../../technical/grids/SharedReportColumns.tsx';
import { mapSubAccountAndDimensionToSubFund } from 'components/bookkeeping/report/Report.utils.tsx';
import { TupleKeyMap } from '../../TupleKeyMap.ts';
import isNil from 'lodash/fp/isNil';
import { type BigNumber, bignumber } from 'mathjs';

const GROUP_COLUMN_MIN_WIDTH = 220;
type Position = ICounterpartyRiskReportQuery['portfolio']['positions']['positions'][number];
type AggregatedPosition = Omit<Position, 'spot'> & {
  spot?: { price: string; amount: BigNumber; balance: BigNumber } | null;
};

const CounterpartyRiskReport: FunctionComponent = () => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const counterpartyReportQueryResult = useDefaultErrorHandling(
    useCounterpartyRiskReportQuery({
      variables: {
        subAccountAssetFilters,
      },
    })
  );

  if (!counterpartyReportQueryResult.loaded) {
    return <counterpartyReportQueryResult.Fallback />;
  }

  const subFunds = counterpartyReportQueryResult.data.portfolio.subFunds.list;
  const { subAccountAndDimensionToSubFund, subFundDimensions } = mapSubAccountAndDimensionToSubFund(subFunds);

  const positions = counterpartyReportQueryResult.data.portfolio.positions.positions;

  // merge only spots
  const aggregatedPositions: AggregatedPosition[] = [];
  const subAccAssetIdToSpotRow = new TupleKeyMap<
    [string, string],
    { price: string; amount: BigNumber; balance: BigNumber }
  >();
  for (const pos of positions) {
    if (isNil(pos.spot)) {
      aggregatedPositions.push({
        ...pos,
        spot: null,
      });
      continue;
    }

    const key: [string, string] = [pos.subAccount.id, pos.asset.id];
    if (!subAccAssetIdToSpotRow.has(key)) {
      const row = {
        price: pos.spot.price,
        amount: bignumber(0),
        balance: bignumber(0),
      };

      subAccAssetIdToSpotRow.set(key, row);
      aggregatedPositions.push({
        ...pos,
        spot: row,
      });
    }

    const spotRow = subAccAssetIdToSpotRow.get(key)!;
    spotRow.balance = spotRow.balance.add(bignumber(pos.spot.balance));
    spotRow.amount = spotRow.amount.add(bignumber(pos.spot.amount));
  }

  return (
    <Stack spacing={1.5} height="100%">
      <GAgGridPreserveState<AggregatedPosition>
        userSettingsKey={UserSettings.CounterpartyRiskReport}
        rowData={aggregatedPositions}
        sideBar={{
          toolPanels: ['columns', 'filters'],
        }}
        enableCharts
        enableRangeSelection
        autoGroupColumnDef={{
          minWidth: GROUP_COLUMN_MIN_WIDTH,
        }}
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: true,
        }}
        columnDefs={[
          underlyingAssetColumn<AggregatedPosition>({
            initialRowGroup: false,
            initialRowGroupIndex: undefined,
          }),
          nameColumn(),
          symbolColumn({
            initialRowGroup: true,
            initialRowGroupIndex: 1,
          }),
          sideColumn({ initialHide: true }),
          typeColumn({ initialHide: true }),
          {
            headerName: 'Account Details',
            colId: 'account-details',
            marryChildren: true,
            children: [accountColumn(), subAccountColumn(), venueColumn()],
          },
          {
            headerName: 'Sub-funds',
            colId: 'sub-funds',
            marryChildren: true,
            children: subFundDimensions.map((subFundDimension) =>
              subFundGroupColumn(subFundDimension, subAccountAndDimensionToSubFund)
            ),
          },
          {
            headerName: 'Current positions',
            colId: 'currentPositions',
            marryChildren: true,
            children: [
              balanceColumn(),
              exposureColumn({ sideAware: true, initialHide: true }),
              amountColumn({ sideAware: true, initialHide: true }),
              exposureColumn({ sideAware: false, initialHide: true }),
              amountColumn({ sideAware: false, initialHide: true }),
            ],
          },
        ]}
      />
    </Stack>
  );
};

export default CounterpartyRiskReport;
