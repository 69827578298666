import type { SxProps } from '@mui/joy/styles/types';
import { type ReactElement, type ReactNode, useState } from 'react';

import type { GButtonProps } from './GButton.props';
import Button from '../../inputs/GButton/GButton.tsx';

export type RenderDialogFunction = ({
  onClose,
}: {
  onClose: () => void;
}) => ReactElement;

type DialogButtonProps = {
  color?: GButtonProps['color'];
  width?: GButtonProps['width'];
  variant?: GButtonProps['variant'];
  children?: ReactNode;
  disabled?: boolean;
  renderDialog: RenderDialogFunction;
  startDecorator?: GButtonProps['startDecorator'];
  paddingVariant?: 'long' | 'short';
  sx?: SxProps;
};

const DialogButton = ({
  children,
  color = 'primary',
  width,
  variant,
  renderDialog,
  disabled = false,
  startDecorator,
  paddingVariant,
  sx,
}: DialogButtonProps): ReactElement => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={(): void => setOpen(true)}
        {...(paddingVariant === 'short' && { slotProps: { startDecorator: { sx: { m: 0, '--Icon-margin': 0 } } } })}
        color={color}
        width={width}
        type="button"
        variant={variant}
        disabled={disabled}
        startDecorator={startDecorator}
        sx={sx}
      >
        {children}
      </Button>

      {open &&
        renderDialog({
          onClose: (): void => setOpen(false),
        })}
    </>
  );
};

export default DialogButton;
