import dayjs from 'dayjs';
import isEqual from 'lodash/fp/isEqual';
import { useSnackbar } from 'notistack';
import { type ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { v4 as uuid } from 'uuid';
import { notificationLink } from 'components/technical/Sidebar/Menu';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import NotificationTile from './NotificationTile';
import {
  INotificationStatus,
  type IUserNotification,
  useConfirmNotificationDeliveryMutation,
  useNotificationsQuery,
  useUpdateNotificationStatusMutation,
} from '../../../generated/graphql';
import { usePrevious } from '../../UsePrevious';
import { useBackgroundPolling } from '../../useBackgroundPolling.ts';

const notificationIntervalSeconds = 60;
const hideDurationSeconds = 5;
const collapseNotifLimit = 3;

const calculateNotifications = (
  notifs: IUserNotification[],
  total: number
): { nodeProps: Omit<IUserNotification, 'requestId'>; confirmationRequestId?: string }[] => {
  if (total > collapseNotifLimit) {
    return [
      {
        nodeProps: {
          title: `There are ${total} waiting notifications`,
          body: 'Click on the message or visit notification center to see them all',
          placedAt: dayjs().toISOString(),
          type: '',
          status: INotificationStatus.NotDelivered,
        },
      },
    ];
  }

  return notifs.map((notif) => ({
    nodeProps: notif,
    confirmationRequestId: notif.requestId,
  }));
};

const NotificationListener = (): ReactElement | null => {
  const activeNotificationPollIntervalMs = useBackgroundPolling(notificationIntervalSeconds * 1000);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { data } = useDefaultErrorHandling(
    useNotificationsQuery({
      variables: {
        status: INotificationStatus.NotDelivered,
        pageLimit: {
          offset: 0,
          limit: 10,
        },
      },
      pollInterval: activeNotificationPollIntervalMs,
    })
  );

  const [confirmNotificationDelivery] = useConfirmNotificationDeliveryMutation({
    ignoreResults: true,
  });

  const [updateNotificationStatus] = useUpdateNotificationStatusMutation({
    ignoreResults: true,
  });

  const previousData = usePrevious(data);
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!data) {
      return;
    }

    if (isEqual(data, previousData)) {
      // data didn't change
      return;
    }

    const newNotifications = data.notification.list.data;
    if (newNotifications.length === 0) {
      return;
    }

    confirmNotificationDelivery({
      variables: {
        acknowledgedPlacedAt: newNotifications[0].placedAt,
      },
    });

    const notifications = calculateNotifications(newNotifications, data.notification.list.pageInfo.totalResults);
    for (const notif of notifications) {
      const id = uuid();
      enqueueSnackbar(
        <NotificationTile
          variant={'outlined'}
          onClose={(): void => {
            closeSnackbar(id);
          }}
          showDot={false}
          key={notif.nodeProps.placedAt ?? 0}
          notification={notif.nodeProps}
          link={notificationLink}
        />,
        {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: hideDurationSeconds * 1000,
          key: id,
        }
      );

      if (notif.confirmationRequestId) {
        updateNotificationStatus({
          variables: {
            requestId: notif.confirmationRequestId,
            status: INotificationStatus.Read,
          },
        });
      }
    }
  }, [
    data,
    previousData,
    enqueueSnackbar,
    closeSnackbar,
    navigate,
    confirmNotificationDelivery,
    updateNotificationStatus,
  ]);

  return null;
};

export default NotificationListener;
