import { Add } from '@mui/icons-material';
import { Modal, ModalDialog, Stack, Typography as JoyTypography } from '@mui/joy';
import type { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useFeedback } from 'components/technical/Feedback/UseFeedback.tsx';
import GFormProvider from 'components/technical/form/GFormProvider.tsx';
import { GraphQLApiFormErrorMessage } from 'components/technical/form/GraphQLApiErrorMessage.tsx';
import gYupResolver from 'components/technical/form/gYupResolver.ts';
import SubmitButton from 'components/technical/form/SubmitButton.tsx';
import { useGraphQLApiError } from 'components/technical/form/UseGraphQLApiError.tsx';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import { FormInput } from 'components/technical/inputs';
import { PublicAssetRequestsDocument, useCreatePublicAssetRequestMutation } from '../../../../../generated/graphql.tsx';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';
import ExternalLink from '../../../../technical/ExternalLink/ExternalLink.tsx';

interface FormState {
  coingeckoId: string;
}

const formSchema = yup.object({
  coingeckoId: yup.string().required(),
});

export const CreatePublicAssetRequestDialog = (props: { onClose: () => void; onAdded: () => void }): ReactElement => {
  const methods = useForm<FormState>({
    resolver: gYupResolver(formSchema),
    defaultValues: {
      coingeckoId: '',
    },
  });

  const { onErrorAndThrow } = useGraphQLApiError(methods);
  const { showSuccessMessage } = useFeedback();

  const [createPublicAssetRequest] = useCreatePublicAssetRequestMutation({
    refetchQueries: [PublicAssetRequestsDocument],
  });

  const handleFormSubmit = async (data: FormState): Promise<void> => {
    try {
      await createPublicAssetRequest({
        variables: {
          input: {
            coingeckoId: data.coingeckoId,
          },
        },
      });

      showSuccessMessage('New public asset successfully requested');

      props.onAdded();
    } catch (e) {
      onErrorAndThrow(e);
    }
  };

  return (
    <Modal open={true} onClose={props.onClose}>
      <ModalDialog>
        <GFormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <GDialogHeader>Add from Coingecko API Id</GDialogHeader>
            <Stack spacing={1.5}>
              <Stack spacing={defaultRowSpacing}>
                <FormInput label="Add Coingecko API Id" name="coingeckoId" width="fullWidth" />
                <JoyTypography color="neutral" level="body-sm">
                  Can&apos;t find Coingecko Id?{' '}
                  <ExternalLink to="mailto:federico@genieai.tech">Contact our team</ExternalLink> to get help
                </JoyTypography>
              </Stack>
              <Stack alignItems="center" spacing={1.5}>
                <GraphQLApiFormErrorMessage />
                <SubmitButton width="xl2" startDecorator={<Add />}>
                  Create
                </SubmitButton>
              </Stack>
            </Stack>
          </form>
        </GFormProvider>
      </ModalDialog>
    </Modal>
  );
};
