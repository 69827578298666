import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import { Badge, Box, ButtonGroup, Tooltip } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import dayjs, { type Dayjs } from 'dayjs';
import { type FunctionComponent, type ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useHasDeliveredNotifications } from 'components/copilot/newsNotification/useHasDeliveredNotifications';
import { useMarketFilters } from 'components/market/asset/filters/UseMarketFilters.ts';
import { NewsExplorerFilterContext } from 'components/market/newsExplorer/NewsExplorerFilterContext.tsx';
import { DrawerType } from 'components/technical/Drawer/Drawer.types';
import Loader from 'components/technical/Loader/Loader';
import { useShowSubAccountAssetFilter } from 'components/technical/SubAccountAssetFilterDrawer/UseShowSubAccountAssetFilters.tsx';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { useCanRequestPortfolioSnapshotQuery, useAccountsQuery, type IAccountsQuery } from 'generated/graphql';
import ColorSchemeChanger from '../../../management/ColorSchemeToggler';
import { useShowQuantInsightsFilters } from '../../../market/asset/filters/UseShowQuantInsightsFilters.tsx';
import { useShowNewsExplorerFilters } from '../../../market/newsExplorer/NewsExplorerService.ts';
import { useRequestSubAccountSnapshot } from '../../../portfolio/account/useSubAccountSnapshot.ts';
import { getLastUpdatedDate } from '../../../portfolio/PortfolioService.ts';
import { useDrawer } from '../../Drawer/UseDrawer.tsx';
import { url as errorsUrl } from '../../errors/ErrorDashboard';
import { ErrorType } from '../../errors/ErrorTypes';
import { useHasErrors } from '../../errors/UseHasErrors';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { useBackgroundPolling } from '../../../useBackgroundPolling.ts';
const LastUpdatedTooltip: FunctionComponent<{
  accounts: IAccountsQuery['portfolio']['accounts'];
  text: string;
}> = ({ accounts, text }) => {
  const lastSuccessfulSnapshotDate: Dayjs | null = getLastUpdatedDate(accounts);

  return (
    <Box display="flex" flexDirection="column" border={0} alignItems="center">
      {text}
      {lastSuccessfulSnapshotDate && (
        <span>Last updated: {dayjs(lastSuccessfulSnapshotDate.toString()).fromNow()}</span>
      )}
    </Box>
  );
};

const getSyncTooltip = (canSync: boolean | undefined, accounts: IAccountsQuery['portfolio']['accounts']): ReactNode => {
  if (accounts.length === 0) {
    return 'No accounts added yet. Nothing to sync';
  }

  if (canSync) {
    return <LastUpdatedTooltip accounts={accounts} text="Click to sync your portfolio" />;
  }

  return <LastUpdatedTooltip accounts={accounts} text="Updating your portfolio data" />;
};

const pollingIntervalMSec = 60 * 1000;
const AppbarActions: FunctionComponent = () => {
  const { drawer, openDrawer, closeDrawer } = useDrawer();
  const navigate = useNavigate();
  const shouldShowMarketFilters = useShowQuantInsightsFilters();
  const { isFilteredByMarketFilter } = useMarketFilters();
  const shouldShowNewsExplorerFilters = useShowNewsExplorerFilters();
  const { isFilteredByNewsExplorerFilter } = useContext(NewsExplorerFilterContext);
  const activePollInterval = useBackgroundPolling(pollingIntervalMSec);
  const activeErrorPollInterval = useBackgroundPolling(60 * 1000 * 10);

  const shouldShowSubAccountAssetFilter = useShowSubAccountAssetFilter();
  const { isFiltered: isFilteredBySubAccountAsset } = useSubAccountAssetFilters();
  const {
    loaded: accountsLoaded,
    data: accountData,
    Fallback,
  } = useDefaultErrorHandling(useAccountsQuery(), {
    loaderSizeVariant: 'small',
    loaderPositionVariant: 'min-content',
  });

  const { data, loading: snapshotLoading } = useCanRequestPortfolioSnapshotQuery({
    pollInterval: activePollInterval,
  });

  const canSync = data?.portfolio.canRequestPortfolioSnapshot;

  const syncPortfolio = useRequestSubAccountSnapshot();

  const { loaded, errorTypes } = useHasErrors({
    pollInterval: activeErrorPollInterval,
  });

  const { hasNotifications } = useHasDeliveredNotifications({
    pollInterval: activePollInterval,
  });

  if (!accountsLoaded) {
    return <Fallback />;
  }

  const hasAccounts = accountData.portfolio.accounts.length > 0;

  const hasAProblem = loaded && errorTypes.length > 0;
  const errorType = (loaded ? errorTypes : []).at(0) ?? ErrorType.JOURNAL;
  const badgeInset = -5;
  const showingFilteredResults =
    (shouldShowSubAccountAssetFilter && isFilteredBySubAccountAsset) ||
    (shouldShowNewsExplorerFilters && isFilteredByNewsExplorerFilter) ||
    (shouldShowMarketFilters && isFilteredByMarketFilter);

  // ButtonGroup adds extra border, which already buttons have, but as we use a box, we need to remove it
  const boxAsButtonGroupChildBorder = { border: '0!important' };

  return (
    <ButtonGroup spacing={1} color="primary" variant="outlined" size="md">
      <ColorSchemeChanger color="primary" variant="button" />
      <Tooltip title={'Open conversations with an agent'}>
        <IconButton onClick={(): void => (drawer === DrawerType.Agent ? closeDrawer() : openDrawer(DrawerType.Agent))}>
          <AutoAwesomeOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={getSyncTooltip(canSync, accountData.portfolio.accounts)}>
        {/* div is needed to make tooltip work even if the icon is disabled */}
        <Box sx={boxAsButtonGroupChildBorder}>
          <IconButton disabled={!canSync || !hasAccounts} onClick={(): void => syncPortfolio()}>
            {(hasAccounts && !canSync) || snapshotLoading ? <Loader variant="small" /> : <SyncIcon />}
          </IconButton>
        </Box>
      </Tooltip>

      <IconButton
        sx={{ display: { xs: 'none', sm: 'block' } }}
        onClick={(): void => {
          navigate(`${errorsUrl}/${errorType}`);
        }}
      >
        <Badge invisible={!hasAProblem} badgeInset={badgeInset}>
          <ReportGmailerrorredOutlinedIcon />
        </Badge>
      </IconButton>

      <IconButton
        disabled={!shouldShowMarketFilters && !shouldShowNewsExplorerFilters && !shouldShowSubAccountAssetFilter}
        onClick={(): void => {
          if (
            drawer !== null &&
            [
              DrawerType.QuantInsightsFilters,
              DrawerType.NewsExplorerFilters,
              DrawerType.SubAccountAssetFilter,
            ].includes(drawer)
          ) {
            closeDrawer();
            return;
          }
          let drawerType = null;
          if (shouldShowNewsExplorerFilters) {
            drawerType = DrawerType.NewsExplorerFilters;
          } else if (shouldShowMarketFilters) {
            drawerType = DrawerType.QuantInsightsFilters;
          } else if (shouldShowSubAccountAssetFilter) {
            drawerType = DrawerType.SubAccountAssetFilter;
          } else {
            throw new Error(
              'Unexpected filter click - correspondent filter type was not found, filter supposed to be disabled'
            );
          }
          openDrawer(drawerType);
        }}
      >
        <Tooltip title={showingFilteredResults ? 'Showing filtered results' : ''}>
          <Badge invisible={!showingFilteredResults} badgeInset={badgeInset} color="warning">
            <FilterListOutlinedIcon />
          </Badge>
        </Tooltip>
      </IconButton>

      <IconButton
        sx={{ display: { xs: 'none', sm: 'block' } }}
        onClick={(): void =>
          drawer === DrawerType.Notifications ? closeDrawer() : openDrawer(DrawerType.Notifications)
        }
      >
        <Badge invisible={!hasNotifications} badgeInset={badgeInset}>
          <NotificationsNoneOutlinedIcon />
        </Badge>
      </IconButton>
    </ButtonGroup>
  );
};

export default AppbarActions;
