import type Highcharts from 'highcharts';
import { memo, type ReactElement, useState } from 'react';
import { parseUtcDate } from 'components/date.utils.ts';
import { formatEnum, formatNumber } from 'components/formatter.utils.ts';
import {
  dateTimeAxisFormat,
  type HighchartSeries,
  noYAxisTitle,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils.ts';
import type { BacktestingConfiguration } from '../BacktestConfiguration.types.ts';
import { Select } from '../../../../technical/inputs';
import { Stack } from '@mui/joy';
import {
  type IPortfolioCorrelationsQuery,
  IWindowType,
  usePortfolioCorrelationsQuery,
} from '../../../../../generated/graphql.tsx';
import type { SelectOption } from '../../../../technical/inputs/Select/Select.props.ts';
import GInput from '../../../../technical/inputs/GInput/GInput.tsx';
import { defaultRowSpacing } from '../../../../StackSpacing.ts';
import HighChartsContainer from '../../../../technical/charts/HighChartsWrapper/HighChartsWrapper.tsx';
import { useDebounce } from '../../../../UseDebounce.ts';
import { WarmUpInput } from '../../../risk/WarmUpInput.tsx';

interface PortfolioCorrelationSectionProps {
  backtestingConfig: BacktestingConfiguration;
}

const calculateOptions = (): Highcharts.Options => {
  return {
    ...dateTimeAxisFormat,
    ...tooltipFormat,
    ...noYAxisTitle,
    plotOptions: {
      series: {
        marker: {
          symbol: 'circle',
        },
      },
    },
  };
};

const calculateChartData = (data: IPortfolioCorrelationsQuery, warmupPeriod: number): HighchartSeries[] => {
  return data.portfolio.portfolioCorrelations.map((corr) => ({
    name: [corr.portfolioPair.portfolioA.name, corr.portfolioPair.portfolioB.name].sort().join(' / '),
    data: corr.correlations.slice(warmupPeriod).map((corr) => ({
      x: parseUtcDate(corr.date).valueOf(),
      y: corr.value,
      textValue: formatNumber(corr.value),
    })),
    type: 'line',
  }));
};

const windowTypeOptions: SelectOption<IWindowType>[] = Object.entries(IWindowType).map(([key, value]) => ({
  value: value as IWindowType,
  key: key,
  label: formatEnum(value),
}));

const isWindowValid = (windowType: IWindowType, window: string | null): boolean => {
  if (windowType === IWindowType.Expanding) {
    return true;
  }

  if (window === null) {
    return false;
  }

  const value = Number.parseFloat(window);
  if (!Number.isSafeInteger(value)) {
    return false;
  }

  return value >= BACKEND_MIN_WINDOW;
};

const BACKEND_MIN_WINDOW = 3;
const PortfolioCorrelations = ({ backtestingConfig }: PortfolioCorrelationSectionProps): ReactElement => {
  const [windowType, setWindowType] = useState(IWindowType.Expanding);
  const [window, setWindow] = useState<string | null>('30');
  const [warmupPeriod, setWarmupPeriod] = useState<string>('10');
  const debouncedWindow = useDebounce(window, 300);
  const warmupEnabled = windowType === IWindowType.Expanding;
  const parsedWarmup = Number.parseFloat(warmupPeriod);

  const queryOutput = usePortfolioCorrelationsQuery({
    variables: {
      input: {
        portfolios: backtestingConfig.portfolios.map((p) => p.id) ?? [],
        dateRange: backtestingConfig.range,
        windowParams: {
          windowType: windowType,
          windowSize: windowType !== IWindowType.Expanding ? Number.parseInt(debouncedWindow ?? '') : undefined,
        },
      },
    },
    skip: !isWindowValid(windowType, debouncedWindow),
  });

  return (
    <Stack rowGap={defaultRowSpacing}>
      <Stack direction={'row'} columnGap={1}>
        <Select
          showLabelAboveInput
          disableClearable
          label="Type"
          value={windowType}
          onChange={(value): void => setWindowType(value)}
          options={windowTypeOptions}
          width="normal"
        />
        {windowType === IWindowType.Expanding ? (
          <WarmUpInput disabled={!warmupEnabled} value={warmupPeriod} onChange={(val) => setWarmupPeriod(val)} />
        ) : (
          <GInput
            error={isWindowValid(windowType, window) ? '' : 'Invalid value'}
            showLabelAboveInput
            label="Window"
            value={window ?? ''}
            type={'number'}
            onChange={(value): void => setWindow(value)}
            width="normal"
          />
        )}
      </Stack>
      <HighChartsContainer<IPortfolioCorrelationsQuery>
        {...queryOutput}
        calculateOptions={(): Highcharts.Options => calculateOptions()}
        calculateChartData={(data): HighchartSeries[] =>
          calculateChartData(data, warmupEnabled && Number.isSafeInteger(parsedWarmup) ? parsedWarmup : 0)
        }
      />
    </Stack>
  );
};

export default memo(PortfolioCorrelations);
