import type { PortfolioOptimizerOutputFields } from './portfolio/PortfolioOptimizer.validation.ts';
import {
  type IConstraint,
  IConstraintFormulation,
  IObjectiveType,
  type IOptimizationObjectiveInput,
  type IPortfolioConstraintsInput,
  IPrimaryConstrainedQuantity,
  type IPrimaryPortfolioConstraintInput,
  IReturnMeasureName,
  IReturnMeasureNameUi,
  ISecondaryConstrainedQuantity,
  type ISecondaryPortfolioConstraintInput,
} from '../../../../generated/graphql.tsx';
import { isBetaMetric, PORTFOLIO_EXPECTED_BETA_METRIC } from '../../../metrics/PortfolioRiskMeasures.ts';
import { ConstraintType } from './ConstraintTypeValues.validation.ts';
import type { AssetOptimizerOutputFields } from './asset/AssetOptimizer.validation.ts';
import isNil from 'lodash/fp/isNil';
import { getFormat } from '../../../metrics/MetricsData.tsx';
import {
  type ItemOutlookOutput,
  RiskDistributionOption,
  shouldShowReturnsForecast,
  shouldShowRiskBudgetAllocation,
} from './assumptionsAndOutlook/AssumptionsAndOutlook.validation.tsx';

export const convertValueConstraint = (value: number, constraintFormulation: IConstraintFormulation): number => {
  if (constraintFormulation === IConstraintFormulation.AbsoluteValue) {
    return value;
  }

  return value / 100;
};

interface ConstraintFields {
  min: number | null;
  max: number | null;
  value: number | null;
}

export const getConstraintValue = (
  constraintValueAndType: { constraintType: ConstraintType; constraintValue?: ConstraintFields },
  constraintFormulation: IConstraintFormulation
): IConstraint => {
  const convertValue = (value: number): number => convertValueConstraint(value, constraintFormulation);
  switch (constraintValueAndType.constraintType) {
    case ConstraintType.Equal:
      return {
        lowerBound: convertValue(constraintValueAndType.constraintValue?.value as number),
        upperBound: convertValue(constraintValueAndType.constraintValue?.value as number),
      };
    case ConstraintType.Between:
      return {
        lowerBound: convertValue(constraintValueAndType.constraintValue?.min as number),
        upperBound: convertValue(constraintValueAndType.constraintValue?.max as number),
      };
    default:
      throw new Error('Unsupported constraint type');
  }
};

export const getObjectives = (
  output: AssetOptimizerOutputFields | PortfolioOptimizerOutputFields
): IOptimizationObjectiveInput[] => {
  return output.objectives.map((obj) => {
    const benchmark = isBetaMetric(obj.riskMetric) ? obj.benchmark?.id : undefined;
    return {
      objectiveType: obj.type,
      riskMetric: {
        benchmark,
        riskMeasureName: obj.riskMetric,
      },
      returnMeasureName: obj.type === IObjectiveType.MaxRiskAdjustedReturns ? IReturnMeasureName.UserSupplied : null,
    };
  });
};

export const getSecondaryPortfolioConstraint = (
  output: AssetOptimizerOutputFields | PortfolioOptimizerOutputFields
): ISecondaryPortfolioConstraintInput | null => {
  const constraint = output.portfolioConstraints.secondaryConstraint;
  if (!constraint) {
    return null;
  }
  const riskMetric: { riskMeasureName: string; benchmark: string | undefined | null } = {
    riskMeasureName: '',
    benchmark: '',
  };

  if (constraint.constrainedQuantity === ISecondaryConstrainedQuantity.Beta) {
    riskMetric.riskMeasureName = PORTFOLIO_EXPECTED_BETA_METRIC;
    riskMetric.benchmark = constraint.benchmark?.id;
  }

  const constraintFormulation =
    constraint.constrainedQuantity === ISecondaryConstrainedQuantity.NetExposure
      ? IConstraintFormulation.Percentage
      : IConstraintFormulation.AbsoluteValue;
  const convertedConstraint = getConstraintValue(constraint, constraintFormulation);

  if (isNil(convertedConstraint)) {
    throw new Error('Empty constraint');
  }

  return {
    riskMetric: riskMetric.riskMeasureName ? riskMetric : null,
    constraint: convertedConstraint,
    constrainedQuantity:
      constraint.constrainedQuantity === ISecondaryConstrainedQuantity.NetExposure
        ? ISecondaryConstrainedQuantity.NetExposure
        : ISecondaryConstrainedQuantity.Beta,
  };
};

export const getPrimaryConstraint = (
  output: AssetOptimizerOutputFields | PortfolioOptimizerOutputFields
): IPrimaryPortfolioConstraintInput | null => {
  const primaryConstraint = output.portfolioConstraints.primaryConstraint;
  if (!primaryConstraint) {
    return null;
  }

  let constraintFormulation = IConstraintFormulation.Percentage;
  if (primaryConstraint.constrainedQuantity === IPrimaryConstrainedQuantity.TargetRisk) {
    const format = getFormat(primaryConstraint.riskMetric.name!);
    if (['cash', 'number'].includes(format)) {
      constraintFormulation = IConstraintFormulation.AbsoluteValue;
    }
  }

  const primaryConstraintValue = getConstraintValue(primaryConstraint, constraintFormulation);

  let riskMetric = null;
  if (primaryConstraint.constrainedQuantity === IPrimaryConstrainedQuantity.TargetRisk) {
    riskMetric = {
      riskMeasureName: primaryConstraint.riskMetric?.name ?? '',
      benchmark: undefined as string | undefined,
    };

    if (primaryConstraint.riskMetric.name === PORTFOLIO_EXPECTED_BETA_METRIC) {
      riskMetric.benchmark = primaryConstraint?.riskMetric?.benchmark?.id;
    }
  }

  return {
    constraint: primaryConstraintValue,
    constrainedQuantity: primaryConstraint.constrainedQuantity,
    riskMetric,
  };
};

export const getPortfolioConstraints = (
  output: AssetOptimizerOutputFields | PortfolioOptimizerOutputFields
): IPortfolioConstraintsInput => {
  return {
    primary: getPrimaryConstraint(output),
    secondary: getSecondaryPortfolioConstraint(output),
  };
};

export const getRiskBudget = (
  id: string,
  output: AssetOptimizerOutputFields | PortfolioOptimizerOutputFields,
  outlook: Record<string, ItemOutlookOutput>,
  forecast: Record<string, { expectedReturn: number; targetRiskBudget: number | null }>
): number | null => {
  if (!shouldShowRiskBudgetAllocation(output.objectives)) {
    return null;
  }

  const assetOutlook = outlook[id];
  if (!isNil(assetOutlook?.riskWeight)) {
    return assetOutlook.riskWeight / 100;
  }

  if (
    output.riskBudgetAllocation === RiskDistributionOption.Forecast ||
    output.riskBudgetAllocation === RiskDistributionOption.MultifactorScore
  ) {
    const riskBudget = forecast[id]?.targetRiskBudget;
    return isNil(riskBudget) ? null : riskBudget / 100; // extra handling in case we didn't have a forecast
  }

  throw new Error(`Missing risk budget for ${id}`);
};

export const getUserExpectedReturn = (
  id: string,
  output: AssetOptimizerOutputFields | PortfolioOptimizerOutputFields,
  outlook: Record<string, ItemOutlookOutput>,
  forecast: Record<string, { expectedReturn: number; targetRiskBudget: number | null }>
): number | null => {
  if (!shouldShowReturnsForecast(output.objectives)) {
    return null;
  }

  const assetOutlook = outlook[id];
  if (!isNil(assetOutlook?.returns)) {
    return assetOutlook.returns / 100;
  }

  if (output.returnsForecast === IReturnMeasureNameUi.ForecastedReturns) {
    const assetForecast = forecast[id];
    const value = assetForecast?.expectedReturn;
    if (isNil(value)) {
      return null;
    }

    return value / 100;
  }

  if (output.returnsForecast === IReturnMeasureNameUi.HistoricMedian) {
    return null;
  }

  throw new Error(`Missing forecast for ${id}`);
};
