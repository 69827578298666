import type { IAggFuncParams } from 'ag-grid-community';
import type { RowData } from './Report.types.ts';
import { type BigNumber, isBigNumber } from 'mathjs';
import isNil from 'lodash/fp/isNil';
import bigNumMath from '../../../bigNumMath.ts';

export const timeAvgOfSumsFactory =
  (timeColId: string) =>
  ({
    values,
    rowNode,
  }: IAggFuncParams<
    RowData,
    { value: number | null; totalSum: number | null; isGroupingDateColumn: boolean } | number | BigNumber | null
  >): {
    value: number | null;
    totalSum: number | null;
    isGroupingDateColumn: boolean;
  } => {
    if (values.length === 0) {
      return {
        value: null,
        totalSum: null,
        isGroupingDateColumn: false,
      };
    }

    const totalSum = bigNumMath.sum(
      values.map((v): number => {
        if (isNil(v)) {
          return 0;
        }

        if (typeof v === 'number') {
          return v;
        }

        if (isBigNumber(v)) {
          return v.toNumber();
        }

        return v.totalSum ?? 0;
      })
    );

    return {
      isGroupingDateColumn: rowNode.rowGroupColumn?.getColId() === timeColId,
      totalSum: totalSum,
      value: values.some((child) => {
        if (isNil(child)) {
          return false;
        }

        if (typeof child === 'number' || isBigNumber(child)) {
          return false;
        }

        return child.isGroupingDateColumn;
      })
        ? totalSum / values.length
        : totalSum,
    };
  };
