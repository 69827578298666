import { addConsentUpdateListener } from '../cookieConsentUpdateEmitter';

// always initialize clarify queue, regardless if clarify component is rendered or not
window.clarity =
  window.clarity ||
  ((...rest: unknown[]): void => {
    if (!window.clarity.q) {
      window.clarity.q = [];
    }

    window.clarity.q.push(rest);
  });

let startedTelemetry = false;
let stoppedTelemetry = false;
addConsentUpdateListener((input: TermlyCookieWhitelist): void => {
  // it's not possible to restart telemetry once it's stopped
  const msClarifyCategory = 'analytics';
  if (input.categories.includes(msClarifyCategory)) {
    window.clarity('consent');

    if (!stoppedTelemetry) {
      startedTelemetry = true;
      console.log('Starting telemetry'); // eslint-disable-line no-console
    }
  } else if (!input.categories.includes(msClarifyCategory) && startedTelemetry && !stoppedTelemetry) {
    stoppedTelemetry = true;
    console.log('Stopping telemetry'); // eslint-disable-line no-console
    window.clarity('stop');
  }
});
