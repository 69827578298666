import { type ApolloClient, useApolloClient } from '@apollo/client';
import type { HubCapsule } from '@aws-amplify/core';
import * as Sentry from '@sentry/react';
import type { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import React, {
  type Dispatch,
  type FunctionComponent,
  type PropsWithChildren,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';

import { SUB_ACCOUNT_ASSET_FILTERS_KEY } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import { getGroupsFromAccessToken } from './AuthService.ts';
import { clearImpersonatedUserId, getSavedImpersonatedUserId, saveImpersonatedUserId } from './AuthStorage';
import {
  type ICognitoUser,
  type IIsExternalUserQuery,
  IsExternalUserDocument,
  type IUsersQuery,
  UsersDocument,
} from './generated/graphql';

export interface User {
  id: string;
  name: string;
  email: string;
  groups: string[];
}

type LoadingAuthn = {
  initialized: false;
};

export type LoadedAuthnLoggedIn = {
  initialized: true;
  originalUserId: string;
  user: User;
  mfa: 'NO_MFA' | 'TOTP';
  localUser: boolean;
  impersonate: (user: ICognitoUser) => void;
};

type LoadedAuthnLoggedOut = {
  initialized: true;
  user: null;
};

export type AuthnState = LoadingAuthn | LoadedAuthnLoggedIn | LoadedAuthnLoggedOut;

const NOT_INITIALIZED_USER = {
  initialized: false,
} as const;

export const AuthnContext = React.createContext<AuthnState>(NOT_INITIALIZED_USER);

const impersonate = ({
  newUser,
  originalUser,
  mfa,
  localUser,
  setState,
}: {
  newUser: ICognitoUser;
  originalUser: CognitoUser & { attributes: Record<string, string> };
  mfa: 'NO_MFA' | 'TOTP';
  localUser: boolean;
  setState: Dispatch<SetStateAction<AuthnState>>;
}): void => {
  console.debug('Impersonating', newUser);

  setState({
    originalUserId: originalUser.attributes.sub,
    user: {
      id: newUser.id,
      name: newUser.name ?? '',
      email: newUser.email,
      groups: newUser.groups,
    },
    localUser,
    mfa,
    initialized: true,
    impersonate: (newUser: ICognitoUser) => impersonate({ newUser, originalUser, mfa, localUser, setState }),
  });

  sessionStorage.removeItem(SUB_ACCOUNT_ASSET_FILTERS_KEY);
  saveImpersonatedUserId(newUser.id);
};

const finalizeUserInitialization = async (
  user: CognitoUser & { attributes: Record<string, string> },
  mfa: 'NO_MFA' | 'TOTP',
  setState: Dispatch<SetStateAction<AuthnState>>,
  apolloClient: ApolloClient<unknown>
): Promise<void> => {
  const impersonatedUserId = getSavedImpersonatedUserId();
  let impersonationUser: undefined | ICognitoUser = undefined;
  const isExternalUserResponse = await apolloClient.query<IIsExternalUserQuery>({
    query: IsExternalUserDocument,
  });

  const isExternalUser = isExternalUserResponse.data.management.isExternalUser;
  if (impersonatedUserId) {
    const response = await apolloClient.query<IUsersQuery>({
      query: UsersDocument,
    });

    impersonationUser = response.data?.management.users.find((user) => user.id === impersonatedUserId);
  }

  const localUser = !isExternalUser;
  if (!impersonationUser) {
    clearImpersonatedUserId();
    setState({
      originalUserId: user.attributes.sub,
      user: {
        id: user.attributes.sub,
        name: user.attributes.name,
        email: user.attributes.email,
        groups: getGroupsFromAccessToken(user.getSignInUserSession()?.getAccessToken()),
      },
      localUser: localUser,
      mfa: mfa,
      initialized: true,
      impersonate: (newUser: ICognitoUser) => impersonate({ newUser, originalUser: user, mfa, localUser, setState }),
    });

    return;
  }

  impersonate({ newUser: impersonationUser, originalUser: user, mfa, localUser, setState });

  return;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isLoggedIn = (state: AuthnState): state is LoadedAuthnLoggedIn => state.initialized && state.user;

const configureSentryUser = (state: AuthnState): void => {
  const originalUserTag = 'originalUser';
  const impersonatingTag = 'impersonating';
  if (!isLoggedIn(state)) {
    Sentry.setUser(null);
    Sentry.setTag(originalUserTag, null);
    Sentry.setTag(impersonatingTag, null);
    return;
  }

  Sentry.setUser({
    id: state.user.id,
    email: state.user.email,
  });

  const isImpersonating = state.user.id !== state.originalUserId;
  Sentry.setTag(originalUserTag, isImpersonating ? state.originalUserId : null);
  Sentry.setTag(impersonatingTag, isImpersonating);
};

export const AuthnProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<AuthnState>(NOT_INITIALIZED_USER);
  const apolloClient = useApolloClient();

  useEffect(() => {
    const updateUser = async (capsule: HubCapsule): Promise<void> => {
      if (['signOut'].includes(capsule.payload.event)) {
        console.info('Signing user out'); // eslint-disable-line no-console

        clearImpersonatedUserId();
        setState({
          user: null,
          initialized: true,
        });

        return;
      }

      console.debug('Auth event', capsule.payload.event);

      try {
        const user = await Auth.currentAuthenticatedUser();
        const cognitoMfa = await Auth.getPreferredMFA(user);
        console.log('User updated', capsule.payload.event); // eslint-disable-line no-console

        await finalizeUserInitialization(user, cognitoMfa === 'NOMFA' ? 'NO_MFA' : 'TOTP', setState, apolloClient);
      } catch (e) {
        // error thrown when window is reloaded when query is in progress. This happens when hotreload causes a full reload
        if ((e as Error).message === 'Failed to fetch') {
          setState({
            user: null,
            initialized: true,
          });
          return;
        }

        console.error('Cannot read authenticated user', e);
        clearImpersonatedUserId();
        setState({
          user: null,
          initialized: true,
        });

        return;
      }
    };

    Hub.listen('auth', updateUser);

    updateUser({
      channel: 'auth',
      patternInfo: [],
      payload: {
        event: 'configured',
        data: null,
      },
      source: 'GenieAuth',
    });
    return (): void => Hub.remove('auth', updateUser);
  }, [apolloClient]);

  useEffect(() => {
    configureSentryUser(state);
  }, [state]);

  return <AuthnContext.Provider value={state}>{children}</AuthnContext.Provider>;
};
