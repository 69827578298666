import PageShell from '../components/technical/PageShell/PageShell.tsx';
import PortfolioDashboard from '../components/portfolio/dashboard/PortfolioDashboard.tsx';
import ProfitAndLossDashboard from '../components/portfolio/profitLoss/ProfitAndLossDashboard.tsx';
import ProfitAndLossPerformance from '../components/portfolio/profitLoss/ProfitAndLossPerformance.tsx';
import PerformanceAttributionContainer from '../components/portfolio/profitLoss/attribution/PerformanceAttribution.tsx';
import AssetContribution from '../components/portfolio/profitLoss/assetContribution/AssetContribution.tsx';
import RealizedPnl from '../components/portfolio/profitLoss/realizedPnl/RealizedPnl.tsx';
import DailyPnl from '../components/portfolio/profitLoss/dailyPnl/DailyPnl.tsx';
import RiskDashboard from '../components/copilot/risk/RiskDashboard.tsx';
import RiskOverview from '../components/copilot/risk/RiskOverview.tsx';
import CounterpartyRiskReport from '../components/copilot/risk/CounterpartyRiskReport.tsx';
import AssetsRiskReport from '../components/copilot/risk/assetRiskMetrics/AssetRiskMetricsReport.tsx';
import PositionsDashboard from '../components/portfolio/openPositions/PositionsDashboard.tsx';
import PositionsSummary from '../components/portfolio/openPositions/PositionsSummary.tsx';
import PositionsSpot from '../components/portfolio/openPositions/PositionsSpot.tsx';
import PositionsDerivatives from '../components/portfolio/openPositions/PositionsDerivatives.tsx';
import { createIndexRoute } from './CreateIndexRoute.tsx';
import { Navigate } from 'react-router';
import UnifiedPnl from 'components/portfolio/profitLoss/unifiedPnl/UnifiedPnl.tsx';

export const portfolioRoutes = {
  handle: {
    title: 'Portfolio',
    breadcrumb: 'Portfolio',
  },
  path: 'portfolio',
  children: [
    {
      handle: {
        breadcrumb: 'Dashboard',
      },
      path: 'dashboard',
      element: (
        <PageShell>
          <PortfolioDashboard />
        </PageShell>
      ),
    },
    {
      handle: {
        breadcrumb: 'Profit and loss',
      },
      path: 'profit-loss',
      element: (
        <PageShell>
          <ProfitAndLossDashboard />
        </PageShell>
      ),
      children: [
        { handle: { breadcrumb: 'Performance' }, path: 'performance', element: <ProfitAndLossPerformance /> },
        {
          handle: { breadcrumb: 'Attribution' },
          path: 'attribution',
          element: <PerformanceAttributionContainer />,
        },
        {
          handle: { breadcrumb: 'Contribution by asset' },
          path: 'asset-contribution',
          element: <AssetContribution />,
        },
        {
          handle: { breadcrumb: 'Realized P&L' },
          path: 'realized-pnl',
          element: <RealizedPnl />,
        },
        {
          handle: { breadcrumb: 'Daily P&L' },
          path: 'daily-pnl',
          element: <DailyPnl />,
        },
        {
          handle: { breadcrumb: 'Unified P&L' },
          path: 'unified-pnl',
          element: <UnifiedPnl />,
        },
        { index: true, element: <Navigate to="performance" replace /> },
      ],
    },
    {
      handle: { breadcrumb: 'Risk' },
      path: 'risk',
      element: (
        <PageShell>
          <RiskDashboard />
        </PageShell>
      ),
      children: [
        { handle: { breadcrumb: 'Overview' }, path: 'overview', element: <RiskOverview /> },
        {
          handle: { breadcrumb: 'Counterparty risk' },
          path: 'counterparty-report',
          element: <CounterpartyRiskReport />,
        },
        {
          handle: { breadcrumb: 'Asset risk metrics' },
          path: 'asset-risk-metrics',
          element: <AssetsRiskReport />,
        },
        { index: true, element: <Navigate to="overview" replace /> },
      ],
    },
    {
      handle: { breadcrumb: 'Positions' },
      path: 'positions',
      element: (
        <PageShell>
          <PositionsDashboard />
        </PageShell>
      ),
      children: [
        { handle: { breadcrumb: 'Summary' }, path: 'summary', element: <PositionsSummary /> },
        { handle: { breadcrumb: 'Spot' }, path: 'spot', element: <PositionsSpot /> },
        { handle: { breadcrumb: 'Derivatives' }, path: 'derivatives', element: <PositionsDerivatives /> },
        { index: true, element: <Navigate to="summary" replace /> },
      ],
    },

    createIndexRoute('dashboard'),
  ],
};
