import { useContext } from 'react';

import { AuthnContext, type LoadedAuthnLoggedIn } from './Authn.tsx';
import { AuthzContext } from './Authz.tsx';
import { isImpersonating } from './AuthService.ts';

type UseAuth = (
  | {
      initialized: boolean;
      user: null;
      mfa: null;
      originalUserId: null;
      localUser: null;
    }
  | Pick<LoadedAuthnLoggedIn, 'user' | 'initialized' | 'mfa' | 'originalUserId' | 'localUser'>
) & {
  isAdmin: boolean;
  isImpersonating: boolean;
  hasAccess: (group: string) => boolean;
  isImpersonatingAdmin: boolean;
  setIsImpersonatingAdmin: (val: boolean) => void;
};

export const useAuth = (): UseAuth => {
  const authnState = useContext(AuthnContext);
  const authzState = useContext(AuthzContext);

  return {
    user: null,
    mfa: null,
    localUser: null,
    originalUserId: null,
    ...authnState,
    isImpersonating: isImpersonating(authnState),
    ...authzState,
  };
};
