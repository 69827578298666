import type { ApolloError } from '@apollo/client';
import { Stack, useTheme } from '@mui/joy';
import dayjs, { type Dayjs } from 'dayjs';
import type * as Highcharts from 'highcharts';
import { bignumber } from 'mathjs';
import React, { type ReactElement } from 'react';
import { getDefaultRange } from 'components/predefinedDateRanges';
import {
  addHoverHighlight,
  cashFormatter,
  dateTimeExportFormat,
  DEFAULT_DATE_FORMAT,
  getChartClickEvent,
  getHighchartColor,
  getPointClickEvent,
  type HighChartRef,
  type HighchartSeries,
  highChartsNegativeColorIndex,
  highChartsPositiveColorIndex,
  inactivePointRadius,
  percentageFormatter,
  removeHoverHighlight,
  type SelectedChartElement,
  tooltipFormat,
} from 'components/technical/charts/HighChartsWrapper/Highchart.utils';
import HighChartsContainer from 'components/technical/charts/HighChartsWrapper/HighChartsWrapper';
import PredefinedDateRangeButtonsWithCalendar from 'components/technical/inputs/date/PredefinedDateRangeButtonsWithCalendar.tsx';
import type { IPortfolioTwrQuery } from 'generated/graphql';

import { formatCash, formatPercentage } from '../../formatter.utils';
import { useFinalColorScheme } from '../../../useFinalColorScheme.ts';

const DAILY_RETURNS = 'Daily returns';
const BALANCE = 'Balance';

const calculateOptions = (
  colorScheme: 'dark' | 'light',
  chartRef: React.MutableRefObject<null | HighChartRef>,
  onElementSelected: (element: SelectedChartElement) => void,
  setVisibleSeries: (val: (val: string[]) => string[]) => void
): Highcharts.Options => {
  return {
    ...dateTimeExportFormat('portfolio-balance'),
    ...tooltipFormat,
    chart: {
      events: {
        click: (): void => {
          if (chartRef.current?.chart) {
            getChartClickEvent(colorScheme, chartRef.current.chart, onElementSelected, true);
          }
        },
      },
    },
    xAxis: {
      type: 'datetime' as const,
      labels: {
        format: DEFAULT_DATE_FORMAT,
      },
    },
    plotOptions: {
      column: {
        states: {
          select: {
            // Don't change appearance for selected bar
            color: undefined,
            borderColor: undefined,
          },
        },
      },
      series: {
        events: {
          legendItemClick: (e) =>
            setVisibleSeries((prev: string[]) => {
              if (prev.includes(e.target.name)) {
                return prev.filter((val: string) => val !== e.target.name);
              }
              return [...prev, e.target.name];
            }),
        },
      },
    },
    yAxis: [
      {
        labels: {
          formatter: cashFormatter,
        },
        title: {
          text: BALANCE,
        },
      },
      {
        labels: {
          formatter: percentageFormatter,
        },
        title: {
          text: DAILY_RETURNS,
        },
        opposite: true,
      },
    ],
  };
};

const calculateChartData = (
  colorScheme: 'dark' | 'light',
  data: IPortfolioTwrQuery,
  onElementSelected: (element: SelectedChartElement) => void,
  ref: HighChartRef | null,
  visibleSeries: string[]
): HighchartSeries[] => {
  return [
    {
      name: DAILY_RETURNS,
      data: data.portfolio.portfolioTimeWeightedReturns.values.map(({ date, return: { weighted_return: value } }) => ({
        x: dayjs.utc(date.toString()).valueOf(),
        y: bignumber(value).toNumber(),
        textValue: formatPercentage(bignumber(value).toNumber()),
        color:
          bignumber(value).toNumber() > 0
            ? getHighchartColor(colorScheme, highChartsPositiveColorIndex)
            : getHighchartColor(colorScheme, highChartsNegativeColorIndex),
      })),
      point: {
        events: {
          select: (event: Highcharts.PointInteractionEventObject) =>
            getPointClickEvent(colorScheme, event, ref, onElementSelected),
          mouseOver: (): void => addHoverHighlight(colorScheme, ref?.chart),
          mouseOut: (): void => removeHoverHighlight(ref?.chart),
        },
      },

      allowPointSelect: true,
      // needed to show different colors for positive and negative returns
      colorByPoint: true,
      type: 'column',
      visible: visibleSeries.includes(DAILY_RETURNS),
      yAxis: 1,
    },
    {
      name: BALANCE,
      data: data.portfolio.portfolioTimeWeightedReturns.values.map((row) => ({
        x: dayjs.utc(row.date.toString()).valueOf(),
        y: bignumber(row.return.balance).toNumber(),
        textValue: formatCash(bignumber(row.return.balance).toNumber()),
      })),
      point: {
        events: {
          select: (event: Highcharts.PointInteractionEventObject) =>
            getPointClickEvent(colorScheme, event, ref, onElementSelected),
          mouseOver: (): void => addHoverHighlight(colorScheme, ref?.chart),
          mouseOut: (): void => removeHoverHighlight(ref?.chart),
        },
      },
      type: 'line',
      visible: visibleSeries.includes(BALANCE),
      allowPointSelect: true,
      threshold: null,
      marker: {
        enabled: data.portfolio.portfolioTimeWeightedReturns.values.length <= 90,
        radius: inactivePointRadius,
        states: {
          select: {
            // Don't change appearance for selected marker
            radius: inactivePointRadius,
            lineWidth: undefined,
          },
        },
      },
    },
  ];
};

export const PortfolioSnapshotBalanceChart = ({
  queryOutput,
  setDateRange,
  onElementSelected,
}: {
  queryOutput: {
    data: IPortfolioTwrQuery | undefined;
    error?: ApolloError;
    loading: boolean;
  };
  setDateRange: (val: [Dayjs, Dayjs] | null) => void;
  onElementSelected: (element: SelectedChartElement) => void;
}): ReactElement => {
  const defaultDateRange = getDefaultRange();
  const chartRef = React.useRef<null | HighChartRef>(null);
  const theme = useTheme();
  const xsScreenSize = window.innerWidth < theme.breakpoints.values.sm;
  const initialVisibleSeries = xsScreenSize ? [BALANCE] : [DAILY_RETURNS, BALANCE];
  const [visibleSeries, setVisibleSeries] = React.useState<string[]>(initialVisibleSeries);
  const colorScheme = useFinalColorScheme();

  return (
    <Stack gap={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
        <PredefinedDateRangeButtonsWithCalendar
          defaultValue={defaultDateRange}
          onChange={(val): void => setDateRange(val)}
          maxDate={dayjs.utc()}
        />
      </Stack>
      <HighChartsContainer<IPortfolioTwrQuery>
        {...queryOutput}
        ref={chartRef}
        calculateOptions={(): Highcharts.Options =>
          calculateOptions(colorScheme, chartRef, onElementSelected, setVisibleSeries)
        }
        calculateChartData={(data): HighchartSeries[] =>
          calculateChartData(colorScheme, data, onElementSelected, chartRef.current, visibleSeries)
        }
      />
    </Stack>
  );
};
