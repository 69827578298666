export const presets = [
  {
    name: 'Default',
    isDefault: true,
    settings: {
      filters: {},
      pivotMode: false,
      columns: [
        {
          colId: '0',
          width: 200,
          hide: false,
          pinned: null,
          sort: 'desc' as const,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'status',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'result.processedLines',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: 'result.totalErrors',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
        {
          colId: '1',
          width: 200,
          hide: false,
          pinned: null,
          sort: null,
          sortIndex: null,
          aggFunc: null,
          rowGroup: false,
          rowGroupIndex: null,
          pivot: false,
          pivotIndex: null,
          flex: null,
        },
      ],
    },
  },
];
