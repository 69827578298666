import type { GButtonProps } from '../technical/inputs/GButton/GButton.props.ts';
import { type ReactElement, useState } from 'react';
import {
  useManagementDashboardInputSuspenseQuery,
  useUpdateJournalDimensionMutation,
} from '../../generated/graphql.tsx';
import { uniq } from 'lodash/fp';
import { useFeedback } from '../technical/Feedback/UseFeedback.tsx';
import { Select } from '../technical/inputs';

export const JournalModeChanger = (props: {
  width: Required<GButtonProps>['width'];
}): ReactElement => {
  const input = useManagementDashboardInputSuspenseQuery();
  const portfolio = input.data.portfolio;
  const dimensions = portfolio.subFunds.list.map((subFund) => subFund.dimension);

  const [activeDimension, setActiveDimension] = useState<string | null>(
    portfolio.journal.preferences.dimension ?? null
  );

  const sortedDimensions = uniq(dimensions).sort();
  const { showGraphqlError } = useFeedback();
  const [updateJournalPreferences] = useUpdateJournalDimensionMutation();

  return (
    <Select
      options={sortedDimensions.map((dim) => ({
        key: dim,
        value: dim,
        label: dim,
      }))}
      // color is unsupported
      width={props.width}
      menuWidth={'normal'}
      placeholder={!activeDimension ? 'Organization' : ''}
      label={'Dimension'}
      showLabelAboveInput
      disableClearable={false}
      value={activeDimension}
      onChange={(value) => {
        const previousDimension = activeDimension;
        setActiveDimension(value);
        updateJournalPreferences({ variables: { dimension: value } }).catch((e) => {
          setActiveDimension(previousDimension);
          showGraphqlError(e);
        });
      }}
    />
  );
};
