import type { DefaultColorPalette } from '@mui/joy/styles/types';
import type { IconTypeMap } from '@mui/material';
import { useFinalColorScheme } from '../useFinalColorScheme.ts';
import { getHighchartColorWithOpacity } from '../components/technical/charts/HighChartsWrapper/Highchart.utils.ts';

export const neutralPlainDisabledColor = 'var(--joy-palette-neutral-plainDisabledColor)';

export const commonBlack = 'var(--joy-palette-common-black)';
export const commonWhitePropertyName = '--joy-palette-common-white';
export const commonWhite = `var(${commonWhitePropertyName})`;

export const getRegularChartColor = (colorScheme: 'dark' | 'light', index: number): string =>
  getHighchartColorWithOpacity(colorScheme, index, 1);
export const getLightChartColor = (colorScheme: 'dark' | 'light', index: number): string =>
  getHighchartColorWithOpacity(colorScheme, index, 0.6);

export const useColorFactory = (): {
  getRegularChartColor: (index: number) => string;
  getLightChartColor: (index: number) => string;
} => {
  const colorScheme = useFinalColorScheme();
  return {
    getRegularChartColor: (index: number): string => getRegularChartColor(colorScheme, index),
    getLightChartColor: (index: number): string => getLightChartColor(colorScheme, index),
  };
};

export const getFontColorByRegularChartBackgroundColors = (_color: string): string => {
  return commonBlack;
};

export const getLightFromRegularChartColor = (color: string): string => `rgba(from ${color} r b g / 0.8)`;

// use when you need to have gradient, but not necessary hot and cold meaning of values
export const gradientColorsLightMode: [number, string][] = [
  [0, '#FEC84B'],
  [0.5, '#FFFFFF'],
  [1, '#C69EFF'],
];

export const gradientColorsDarkMode: [number, string][] = [
  [0, '#F79009'],
  [0.5, '#000000'],
  [1, '#5F35AE'],
];

// @TODO move to colors.ts once it's merged
export const joyColorsPaletteToMui = (color: DefaultColorPalette): IconTypeMap['props']['color'] => {
  switch (color) {
    case 'success':
      return 'success';
    case 'danger':
      return 'error';
    case 'neutral':
      return 'inherit';
    case 'warning':
      return 'warning';
    default:
      return 'info';
  }
};
