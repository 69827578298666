import type { IPortfolioRegimeResultQuery } from '../../../../../generated/graphql.tsx';
import type { ReactElement } from 'react';
import groupBy from 'lodash/fp/groupBy';
import { PortfolioReturnsPerformanceChart } from '../../../portfolioOptimizer/results/PortfolioReturnsPerformanceChart.tsx';
import { Grid } from '@mui/joy';
import { Header4 } from '../../../../technical/Header4.tsx';

const PortfolioRegimeReturns = ({
  risk,
}: { risk: IPortfolioRegimeResultQuery['portfolio']['regimeRisk'] }): ReactElement => {
  const regimes = risk.flatMap((portfolioData) =>
    portfolioData.data.map((regimeData) => ({
      regimeName: regimeData.regimeName,
      portfolio: portfolioData.portfolio,
      returns: regimeData.returns,
    }))
  );

  const regimeToReturns = groupBy(({ regimeName }) => regimeName, regimes);
  const chartNames = Object.keys(regimeToReturns).sort();
  return (
    <Grid container>
      {chartNames.map((name) => (
        <Grid key={name} xs={12} md={6}>
          <Header4 title={name} />
          <PortfolioReturnsPerformanceChart
            returns={regimeToReturns[name].map(({ returns, portfolio }) => ({ data: returns, title: portfolio.name }))}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PortfolioRegimeReturns;
