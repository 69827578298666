import type { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import { dateReadableValueGetter } from 'components/technical/grids/agGrid.utils.tsx';
import GAgGrid from 'components/technical/grids/GAgGrid';
import { type IDailyPnlQuery, useDailyPnlSuspenseQuery } from 'generated/graphql';
import {
  accountColumn,
  subAccountColumn,
  subFundGroupColumn,
  venueColumn,
} from 'components/technical/grids/SharedReportColumns';
import { mapSubAccountAndDimensionToSubFund } from 'components/bookkeeping/report/Report.utils';
import { type BigNumber, bignumber } from 'mathjs';
import { parseUtcDate } from '../../../date.utils.ts';
import bigNumMath from '../../../../bigNumMath.ts';
import type { Dayjs } from 'dayjs';
import isNil from 'lodash/fp/isNil';
import { convertDateRangeToSinceToDate } from '../../../technical/inputs/date/dateRange.utils.ts';
import { useSubAccountAssetFilters } from '../../../technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters.tsx';
import groupBy from 'lodash/fp/groupBy';
import type { ReactElement } from 'react';

type SubAccountRowValues = IDailyPnlQuery['portfolio']['subAccountsTimeWeightedReturns']['subAccounts'][number];
type RowData = SubAccountRowValues['values'][number] & {
  subAccount: SubAccountRowValues['subAccount'];
};

const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
};

interface DailyReturnsValue {
  days: {
    date: Dayjs;
    balance: BigNumber;
    pnl: BigNumber;
  }[];
  value: number;
}

const DailyPnlGrid = ({ dateRange }: { dateRange: [Dayjs, Dayjs] | null }): ReactElement => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();
  const queryResult = useDailyPnlSuspenseQuery({
    variables: {
      subAccountAssetFilters,
      ...convertDateRangeToSinceToDate(dateRange),
    },
  });

  const subFunds = queryResult.data.portfolio.subFunds.list;

  const rows = queryResult.data.portfolio.subAccountsTimeWeightedReturns.subAccounts.flatMap((subAccount) =>
    subAccount.values.map((value) => ({
      ...value,
      subAccount: subAccount.subAccount,
    }))
  );

  const { subAccountAndDimensionToSubFund, subFundDimensions } = mapSubAccountAndDimensionToSubFund(subFunds);

  const columns: (ColDef<RowData> | ColGroupDef<RowData>)[] = [
    {
      headerName: 'Date',
      field: 'date',
      type: 'dateColumn',
      initialSort: 'desc',
      valueGetter: (params: ValueGetterParams<RowData>): string | undefined =>
        params.data ? dateReadableValueGetter(params.data.date) : undefined,
    },
    {
      headerName: 'Account Details',
      colId: 'account-details',
      marryChildren: true,
      children: [accountColumn(), subAccountColumn({ initialHide: true }), venueColumn({ initialHide: true })],
    },
    {
      headerName: 'Balance',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params): number | undefined => {
        if (isNil(params.data?.return.balance)) {
          return undefined;
        }
        return bignumber(params.data?.return.balance).toNumber();
      },
    },
    {
      headerName: 'P&L',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params): number | undefined => {
        if (isNil(params.data?.return.return)) {
          return undefined;
        }
        return bignumber(params.data?.return.return).toNumber();
      },
    },
    {
      headerName: 'Returns',
      type: ['numericColumn', 'percentageColumn'],
      valueGetter: (params): null | DailyReturnsValue => {
        if (!params.node?.group) {
          // no need to handle group levels - calculated in the 'aggFunc'
          const data = params.data;
          if (!data) {
            return null;
          }

          const parsedEndingBalance = bignumber(data.return.balance);
          const parsedPnl = bignumber(data.return.return);
          const parsedDate = parseUtcDate(data.date);

          return {
            days: [
              {
                pnl: parsedPnl,
                balance: parsedEndingBalance,
                date: parsedDate,
              },
            ],
            value: data.return.weighted_return,
          };
        }

        return null;
      },
      aggFunc: (params): null | DailyReturnsValue => {
        const values = params.values;
        if (isNil(values)) {
          return null;
        }

        const nonNullValues: DailyReturnsValue['days'] = values.flatMap((val) => val.days).filter((val) => !isNil(val));
        if (nonNullValues.length === 0) {
          return null;
        }

        const perDayValues = groupBy((val) => val.date.unix(), nonNullValues);
        const perDayHp = Object.values(perDayValues).map((values) => {
          const dayPnl = bigNumMath.sum(values.map((val) => val.pnl));
          const dayBalance = bigNumMath.sum(values.map((val) => val.balance));
          const denum = dayBalance.sub(dayPnl);
          return denum.eq(0) ? bignumber(0) : dayPnl.div(denum);
        });

        const twr = perDayHp.reduce((acc, hp) => hp.add(1).mul(acc), bignumber(1)).sub(1);

        return {
          days: nonNullValues,
          value: twr.toNumber(),
        };
      },
      allowedAggFuncs: [],
    },
    {
      headerName: 'Cash flow',
      type: ['numericColumn', 'cashColumn'],
      valueGetter: (params): number | undefined => {
        if (isNil(params.data?.return.cash_flow)) {
          return undefined;
        }
        return bignumber(params.data?.return.cash_flow).toNumber();
      },
    },
    {
      headerName: 'Sub-funds',
      colId: 'sub-funds',
      marryChildren: true,
      children: subFundDimensions.map((subFundDimension) =>
        subFundGroupColumn<RowData>(subFundDimension, subAccountAndDimensionToSubFund)
      ),
    },
  ];

  return (
    <GAgGrid
      rowData={rows}
      sideBar={{
        toolPanels: ['columns', 'filters'],
      }}
      enableCharts
      enableRangeSelection
      defaultColDef={defaultColDef}
      autoSizeStrategy={{ type: 'fitCellContents' }}
      columnDefs={columns}
    />
  );
};

export default DailyPnlGrid;
