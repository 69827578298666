import { Stack, Tab, TabList, TabPanel, Tabs, Typography } from '@mui/joy';
import type { ReactElement } from 'react';
import type { IPortfolioDetailsCommentaryQuery } from '../../../../../generated/graphql.tsx';
import isNil from 'lodash/fp/isNil';
import groupBy from 'lodash/fp/groupBy';
import { GMarkdown } from '../../../../technical/GMarkdown.tsx';
import sortBy from 'lodash/fp/sortBy';

type Summary = IPortfolioDetailsCommentaryQuery['portfolio']['portfolioSummary'];

const prepareSummary = <S extends { index: number; content: string }>(summary: S[]): S[] =>
  sortBy((sum) => sum.index, summary);

const SingleSummary = ({ summary, value }: { summary: Summary; value: string }): ReactElement => {
  return (
    <TabPanel value={value}>
      <Stack rowGap={2}>
        {prepareSummary(summary ?? []).map((section) => (
          <div key={section.index}>
            <Typography level="h4">{section.title}</Typography>
            <GMarkdown>{section.content}</GMarkdown>
          </div>
        ))}
      </Stack>
    </TabPanel>
  );
};

const PortfolioSummary = ({
  portfolios,
  summary,
}: {
  portfolios: { id: string; name: string }[];
  summary: Summary;
}): ReactElement => {
  const allPortfolioSummary = summary.filter((sum) => isNil(sum.portfolio));
  const perPortfolioSummary = groupBy(
    (sum) => sum.portfolio!.id,
    summary.filter((sum) => !isNil(sum.portfolio))
  );

  return (
    <Tabs defaultValue={portfolios.length > 1 ? 'summary' : portfolios.at(0)?.id}>
      <TabList>
        {portfolios.length > 1 && <Tab value={'summary'}>Summary</Tab>}
        {portfolios.map((pd) => (
          <Tab value={pd.id} key={pd.id}>
            {pd.name}
          </Tab>
        ))}
      </TabList>
      {portfolios.length > 1 && <SingleSummary value={'summary'} summary={allPortfolioSummary} />}
      {portfolios.map((pd) => (
        <SingleSummary key={pd.id} value={pd.id} summary={perPortfolioSummary[pd.id] ?? []} />
      ))}
    </Tabs>
  );
};

export default PortfolioSummary;
