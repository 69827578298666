import HeaderBar from '../HeaderBar/HeaderBar.tsx';
import { Box, Card, Typography } from '@mui/joy';
import SectionColumn from '../layout/Column/SectionColumn.tsx';
import type { TypographySystemOverrides } from '@mui/joy/styles';
import type { DefaultTypographySystem } from '@mui/joy/styles/types/typography';
import { Header3 } from '../Header3.tsx';
import { Header4 } from '../Header4.tsx';
import { Fragment, type ReactElement } from 'react';

const typographyLevels: (keyof (TypographySystemOverrides & DefaultTypographySystem))[] = [
  'h1',
  'h2',
  'h3',
  'h4',
  'title-lg',
  'title-md',
  'title-sm',
  'title-sm-emphasis',
  'title-xs',
  'body-lg',
  'body-md',
  'title-md-emphasis',
  'body-sm',
  'body-sm-bold',
  'body-xs',
  'body-xs2',
];

const Sizer = (): ReactElement => {
  return (
    <Box
      sx={{
        width: '2px',
        background: 'red',
        justifySelf: 'right',
      }}
    />
  );
};

const FontsPlayground = () => {
  return (
    <SectionColumn>
      <HeaderBar title="Fonts" />
      <Card
        sx={{
          display: 'grid',
          gridTemplateColumns: '50px 1fr',
          gap: '1rem',
          justifyItems: 'left',
        }}
      >
        <span>Height</span>
        <span>Component</span>
        {typographyLevels.map((level) => (
          <Fragment key={level}>
            <Sizer />
            <Typography level={level}>{level}</Typography>
          </Fragment>
        ))}
        <Sizer />
        <HeaderBar title="HeaderBar" />
        <Sizer />
        <Header3 title="Header3" />
        <Sizer />
        <Header4 title="Header4" />
      </Card>
    </SectionColumn>
  );
};

export default FontsPlayground;
