import { Autocomplete as JoyAutocomplete, FormControl, Box } from '@mui/joy';
import type { AutocompleteRenderOptionState } from '@mui/joy/Autocomplete';
import isNil from 'lodash/fp/isNil';
import type React from 'react';
import { type ForwardedRef, type ReactElement, type ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { fixedForwardRef } from 'components/technical/fixedForwardRef.ts';
import { calculateContainerClasses, createJoyAutocompleteProps, type GOption } from './GAutocomplete';
import type { GMultiAutocompleteProps } from './GMultiAutocomplete.props';
import { VirtualizedListbox } from './VirtualizedListBox/VirtualizedListbox';
import {
  VirtualizedListContext,
  type VirtualizedListContextType,
} from './VirtualizedListBox/VirtualizedListContext.tsx';
import { virtualizedRenderOptions } from './VirtualizedListBox/VirtualizedRenderOptions.tsx';
import { useLoading } from '../../UseLoading.ts';
import InputError from '../InputError';
import InputLabel from '../InputLabel';
import { renderOption } from '../RenderOption/RenderOption.tsx';
import { useDebounce } from '../../../UseDebounce.ts';
import { shouldRenderInputLabel } from '../LabelService.ts';
import { EmptyClearIcon } from './EmptyClearIcon.tsx';

function GMultiAutocomplete<TValue>(
  props: GMultiAutocompleteProps<TValue>,
  ref: ForwardedRef<HTMLElement>
): ReactElement {
  const {
    value,
    onChange,
    getOptions,
    isOptionEqualToValue = (option1: TValue, option2: TValue): boolean => option1 === option2,
    getOptionKey,
    renderOption: renderOptionProp,
  } = props;

  /* jscpd:ignore-start */
  const [input, setInput] = useState('');
  const [options, setOptions] = useState<GOption<TValue>[]>([]);
  const { loading, load } = useLoading();
  // avoid flashing of loading indicator
  const deboundedLoading = useDebounce(loading, 50);
  const currentInput = useRef(input);
  currentInput.current = input;

  /* jscpd:ignore-end */
  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    return load(async (signal) => {
      const result = await getOptions({ input: input, signal, paginationState: null });
      const fetchedOptions = result.data;
      const selectedOptions = value ?? [];
      const newOptions = fetchedOptions.filter(
        (opt) => !selectedOptions.some((selOpt) => isOptionEqualToValue(selOpt, opt))
      );

      const hasMoreOption: GOption<TValue>[] = [];

      if (result.hasMoreResults) {
        hasMoreOption.push({
          type: 'hasMoreResults',
        });
      }

      const regularOptions = [...selectedOptions, ...newOptions].map(
        (opt): GOption<TValue> => ({
          type: 'regular',
          value: opt,
        })
      );

      setOptions([...hasMoreOption, ...regularOptions]);
    });
  }, [getOptions, input, isOptionEqualToValue, load, value, getOptionKey]);

  const finalValue = useMemo(
    () =>
      isNil(value)
        ? value
        : value.map(
            (val): GOption<TValue> => ({
              type: 'regular',
              value: val,
            })
          ),
    [value]
  );

  const VirtualizedBoxContext = VirtualizedListContext as React.Context<VirtualizedListContextType<TValue>>;
  return (
    <Box sx={calculateContainerClasses(props)}>
      <FormControl error={!!props.error} disabled={props.disabled} color={props.color}>
        {shouldRenderInputLabel(props) ? <InputLabel {...props} /> : <></>}
        <VirtualizedBoxContext.Provider
          value={{
            optionHeight: props.optionHeight,
            getOptionKey: props.getOptionKey,
            hasGroups: !!props.groupBy,
            renderOption: (
              props: Omit<React.HTMLAttributes<HTMLLIElement>, 'color'>,
              option: TValue,
              state: AutocompleteRenderOptionState
            ): ReactNode => {
              return renderOption(renderOptionProp(props, option, state));
            },
            menuWidth: props.menuWidth ?? props.width,
          }}
        >
          <JoyAutocomplete<GOption<TValue>, true>
            {...createJoyAutocompleteProps(props)}
            // @ts-expect-error: seems like a error in joy types - no ref for multiple=true/false, but actually ref is required
            ref={ref}
            value={finalValue}
            onChange={(_event, value: GOption<TValue>[] | null): void => {
              if (value === null) {
                onChange?.([]);
                return;
              }

              setInput('');
              onChange?.(value.map((el) => el.value as TValue));
            }}
            options={options}
            onClose={(): void => {
              setInput('');
            }}
            multiple
            renderOption={virtualizedRenderOptions}
            onInputChange={(_event, value, reason): void => {
              if (reason === 'reset') {
                return;
              }

              setInput(value);
            }}
            inputValue={input}
            loading={deboundedLoading}
            disableCloseOnSelect
            getOptionDisabled={(option): boolean => option.type !== 'regular'}
            slots={
              props.showClearable
                ? { listbox: VirtualizedListbox }
                : {
                    clearIndicator: EmptyClearIcon,
                    listbox: VirtualizedListbox,
                  }
            }
          />
        </VirtualizedBoxContext.Provider>
        <InputError error={props.error} />
      </FormControl>
    </Box>
  );
}

const ForwardedGMultiAutocomplete = fixedForwardRef(GMultiAutocomplete);
export default ForwardedGMultiAutocomplete;
