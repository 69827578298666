import { Stack, Typography } from '@mui/joy';
import type { SvgIconProps as MSvgIconProps } from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/joy/SvgIcon';
import type { ReactElement, ReactNode } from 'react';

import BorderedItem from './BorderedItem/BorderedItem';
import type { GButtonProps } from './inputs/GButton/GButton.props';

export const BorderedActionItem = ({
  icon: Icon,
  title,
  description,
  action: Action,
  width,
}: {
  icon: ((props: SvgIconProps) => ReactNode) | ((props: MSvgIconProps) => ReactNode);
  title: string;
  description: ReactNode;
  action: (props: Required<Pick<GButtonProps, 'width' | 'color'>>) => ReactElement;
  width?: GButtonProps['width'];
}): ReactElement => {
  return (
    <BorderedItem padding="sm">
      <Stack direction={'row'} flexWrap={'wrap'} alignItems={'center'} justifyContent="space-between" spacing={1.5}>
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Icon />
          <Stack spacing={1}>
            <Typography level="title-md">{title}</Typography>
            <Typography level="body-md">{description}</Typography>
          </Stack>
        </Stack>
        <Action width={width ?? 'smaller'} color="primary" />
      </Stack>
    </BorderedItem>
  );
};
