import { APP, GENIE } from './Permissions.ts';

export type GroupGuard = (options: { groups: string[] }) => boolean;
export const hasGroups = (group: string): GroupGuard => {
  return ({ groups }: { groups: string[] }): boolean => groups.includes(group);
};

export const genieUser = (groups: string[]): boolean => groups.includes(GENIE);

export const appUser = hasGroups(APP);
