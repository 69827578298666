import groupBy from 'lodash/fp/groupBy';
import isNil from 'lodash/fp/isNil';
import { bignumber, type BigNumber } from 'mathjs';

import bigNumMath, { reverseRelativeChange } from '../../../bigNumMath.ts';
import { type IAsset, type IAssetCostBasis, IPositionSide } from '../../../generated/graphql.tsx';
import { isPublicAsset, type PublicAsset } from '../../market/asset/Asset.types.ts';
import { getUnderlyingAsset } from '../../market/asset/AssetService.tsx';
import { logErrorOnce } from 'components/log.utils.ts';
import type { AssetLabelInput } from '../../market/asset/AssetLabelService.ts';
import type { FlattenUnion } from '../../type.utils.ts';
import { TupleKeyMap } from '../../TupleKeyMap.ts';

interface SpotSnapshot {
  balance: string;
}

export const getPublicSpotPositionsWithValue = <
  T extends {
    asset: Omit<IAsset, 'derivativeDetails' | 'unvestedAsset' | 'priceAsset'>;
    spot?: SpotSnapshot | null | undefined;
  },
>(
  positions: T[]
): (T & { value: BigNumber; asset: PublicAsset })[] =>
  positions
    .filter(
      (pos): pos is T & { asset: PublicAsset; spot: SpotSnapshot } => isPublicAsset(pos.asset) && !isNil(pos.spot)
    )
    .map((pos) => ({
      ...pos,
      value: bignumber(pos.spot.balance),
    }));

export const aggregateSupportedAssetPositions = (
  positions: { asset: { id: string }; value: BigNumber }[],
  supportedAssets: { id: string }[]
): { id: string; value: BigNumber }[] => {
  const supportedAssetIds = new Set(supportedAssets.map((asset) => asset.id));

  const knownPortfolioAssets: { id: string; value: BigNumber }[] = positions
    .filter((pos) => supportedAssetIds.has(pos.asset.id))
    .map((pos) => ({
      ...pos,
      id: pos.asset.id,
    }));

  const assetIdToValues = groupBy((entry) => entry.id, knownPortfolioAssets);
  return Object.entries(assetIdToValues).map(([id, assets]) => ({
    id,
    value: bigNumMath.sum(...assets.map((asset) => asset.value)),
  }));
};
export const sideMultiplier = (side: IPositionSide): number => (side === IPositionSide.Long ? 1 : -1);

export interface SubAccountPosition<TAsset = { id: string }, TDerivativeFields = unknown> {
  asset: TAsset;
  spot?: {
    amount: string;
    price: string;
    balance: string;
    slot: string;
  } | null;
  derivative?:
    | ({
        amount: string;
        notional?: string | null;
        side: IPositionSide;
        unrealizedPnl?: string | null;
        balance?: string | null;
        initialMargin?: string | null;
        maintenanceMargin?: string | null;
        unitMarketPrice?: string | null;
        unitEntryPrice?: string | null;
        leverage?: string | null;
      } & TDerivativeFields)
    | null;
  subAccount: {
    id: string;
    name: string;
    account: {
      id: string;
      name: string;
      venue: {
        label: string;
      };
    };
  };
}

export const getUnderlyingPosAsset = <T extends { id: string }>(data: {
  asset: T & FlattenUnion<AssetLabelInput>;
}): T => {
  return getUnderlyingAsset(data.asset);
};

export const calculateExposure = (
  position: {
    spot?: { amount: string | number | BigNumber; price: string } | null;
    derivative?: { notional?: string | null; side: IPositionSide } | null;
  },
  sideAware: boolean
): BigNumber | undefined => {
  const spot = position.spot;
  if (spot) {
    return bignumber(spot.amount).mul(bignumber(spot.price));
  }

  const derivative = position.derivative;
  if (!derivative) {
    console.error('Missing data for exposure of derivative', position);
    return undefined;
  }

  if (isNil(derivative.notional)) {
    return undefined;
  }

  const value = bignumber(derivative.notional);
  if (sideAware) {
    return value.mul(sideMultiplier(derivative.side));
  }

  return value;
};

export const calculateBalance = (position: {
  spot?: { balance: string | number | BigNumber } | null;
  derivative?: { balance?: string | null } | null;
}): BigNumber | undefined => {
  const spot = position.spot;
  if (spot) {
    return bignumber(spot.balance);
  }

  const derivative = position.derivative;
  if (!derivative) {
    logErrorOnce('Missing data for balance of derivative', position);
    return undefined;
  }

  return derivative.balance ? bignumber(derivative.balance) : undefined;
};

export type SubAccountBalanceSummary = {
  amount: BigNumber;
  balance: BigNumber;
  price: BigNumber | undefined;
  priceChange: BigNumber | undefined;
  balanceChange: BigNumber | undefined;
  amountChange: BigNumber | undefined;
  balanceContribution: BigNumber | undefined;
};

export const calculateSummary = (
  positions: {
    amount: string;
    price: string;
    balance: string;
    change?: {
      amount?: string;
      price?: string;
    };
  }[],
  portfolioBalance: BigNumber
): SubAccountBalanceSummary => {
  let balance = bignumber(0);
  let absBalance = bignumber(0);
  let pastBalance = bignumber(0);
  let pastAmount = bignumber(0);
  let amount = bignumber(0);
  let absAmount = bignumber(0);
  for (const pos of positions) {
    const posAmount = bignumber(pos.amount);
    amount = amount.plus(posAmount);
    absAmount = absAmount.plus(posAmount.abs());

    const posPrice = bignumber(pos.price);
    const posBalance = bignumber(pos.balance);
    balance = balance.plus(posBalance);
    absBalance = absBalance.plus(posBalance.abs());

    if (!pos.change || isNil(pos.change.amount)) {
      continue;
    }

    const positionPastAmount = reverseRelativeChange(posAmount, bignumber(pos.change.amount)).abs();
    pastAmount = pastAmount.plus(positionPastAmount);

    if (isNil(pos.change.price)) {
      continue;
    }

    const pastPrice = reverseRelativeChange(posPrice, bignumber(pos.change.price));
    pastBalance = pastBalance.plus(pastPrice.mul(positionPastAmount));
  }

  const foundChangeAmount = positions.every((pos) => pos.change?.amount && !bignumber(pos.amount).equals(0));
  const foundChangeBalance =
    positions.every((pos) => pos.change && !isNil(pos.change.amount) && !isNil(pos.change.price)) &&
    !pastBalance.equals(0) &&
    !absBalance.equals(0) &&
    !absAmount.equals(0) &&
    !pastAmount.equals(0);

  const pastPrice = pastBalance.div(pastAmount);
  const price = absBalance.div(absAmount);
  const priceChange = price.minus(pastPrice).div(pastPrice);
  const amountChange = absAmount.minus(pastAmount).div(pastAmount);
  const balanceChange = absBalance.minus(pastBalance).div(pastBalance);
  const balanceContribution = balance.div(portfolioBalance);

  return {
    amount,
    balance,
    price,
    priceChange: foundChangeBalance ? priceChange : undefined,
    balanceChange: foundChangeBalance ? balanceChange : undefined,
    amountChange: foundChangeAmount ? amountChange : undefined,
    balanceContribution: balanceContribution.isFinite() ? balanceContribution : undefined,
  };
};

export const calculateUnrealizedGain = (amount: BigNumber, price: BigNumber, unitCostBasis: BigNumber): BigNumber => {
  // quantity * (current_price - cost_basis)
  return amount.mul(price.minus(unitCostBasis));
};

type AssetCostBasis = Pick<IAssetCostBasis, 'costBasis'> & {
  asset: Pick<IAsset, 'id'>;
  subFund?:
    | {
        id: number;
      }
    | null
    | undefined;
};

export const groupCostBasisByAssetAndSubFund = (
  costBasis: AssetCostBasis[]
): TupleKeyMap<[string, number | null], BigNumber> => {
  return new TupleKeyMap(
    costBasis.map((costBasis) => [[costBasis.asset.id, costBasis.subFund?.id ?? null], bignumber(costBasis.costBasis)])
  );
};
