import capitalize from 'lodash/fp/capitalize';

export const yupLocale = {
  number: {
    min: 'Must be greater than or equal to ${min}',
    max: 'Must be less than or equal to ${max}',
    lessThan: 'Must be less than ${less}',
    moreThan: 'Must be greater than ${more}',
    negative: 'Must be a negative number',
    integer: 'Must be an integer',
    required: 'Is required',
    positive: 'Must be positive',
  },
  mixed: {
    required: 'Is required',
    notType: (_ref: { type: string; originalValue: unknown }): string => {
      switch (_ref.type) {
        case 'number':
          return 'Should be a number';
        default:
          return `Has a wrong type. Expected ${_ref.type}`;
      }
    },
  },
  string: {
    length: 'Must be exactly ${length} characters',
    min: 'Must be at least ${min} characters',
    max: 'Must be at most ${max} characters',
    matches: 'Must match the following: "${regex}"',
    email: 'Must be a valid email',
    url: 'Must be a valid URL',
    uuid: 'Must be a valid UUID',
    trim: 'Must be a trimmed string',
    lowercase: 'Must be a lowercase string',
    uppercase: 'Must be an uppercase string',
  },
  array: {
    min: 'Field must have at least ${min} items',
    max: 'Field must have less than or equal to ${max} items',
    length: 'Must have ${length} items',
  },
} as const;

export const translate = (message: string): string => capitalize(message);
