import Avatar from '@mui/joy/Avatar';
import type { ReactElement } from 'react';

import { getLightChartColor } from '../../../theme/colors';
import { hashcode } from '../../hashcode.utils';
import { useFinalColorScheme } from '../../../useFinalColorScheme.ts';
import type { SxProps } from '@mui/system';
import { type IconVariant, iconVariantToPx } from '../../market/asset/cryptocurrencies/CryptocurrenciesData.tsx';

const UnknownIcon = (props: {
  text: string | null | undefined;
  sx?: SxProps;
  size: IconVariant;
}): ReactElement => {
  const hash = hashcode(props.text);
  const firstLetter = props.text?.at(0)?.toUpperCase() ?? '';
  const colorScheme = useFinalColorScheme();

  const iconSize = iconVariantToPx(props.size);
  const style = {
    height: iconSize,
    width: iconSize,
    fontSize: iconSize * 0.6, // empyrical value
  } as const;

  return (
    <Avatar
      sx={[
        { background: getLightChartColor(colorScheme, hash), color: 'var(--joy-palette-neutral-500)' },
        style,
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]).filter((sx) => !!sx),
      ]}
    >
      {firstLetter}
    </Avatar>
  );
};

export default UnknownIcon;
