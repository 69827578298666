import { Stack, IconButton } from '@mui/joy';
import { Drawer } from '@mui/material';
import { type ReactElement, Suspense, useMemo } from 'react';

import { REPLAY_UNMASK_CLASS_NAME } from 'setup/initializeAll.ts';
import { createMenu, footerMenu } from './Menu';
import { SidebarMenu } from './SidebarMenu';
import { SidebarTop } from './SidebarTop';
import { useScreenBreakpointDown } from '../screenSizeUtils';
import { KeyboardArrowRight, KeyboardArrowLeft, Menu } from '@mui/icons-material';
import Loader from '../Loader/Loader.tsx';
import { useAuth } from '../../../UseAuth.tsx';
import { useOrganizationFeaturesSuspenseQuery } from '../../../generated/graphql.tsx';

type SidebarProps = {
  setSideBarOpen: (isOpen: boolean) => void;
  isSideBarOpen: boolean;
};

export const sidebarAnimation = '225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms';

const Sidebar = ({ setSideBarOpen, isSideBarOpen }: SidebarProps): ReactElement => {
  const toggleSidebar = (): void => setSideBarOpen(!isSideBarOpen);
  const mobileView = useScreenBreakpointDown('sm');

  return (
    <>
      <IconButton
        size={mobileView ? 'lg' : 'sm'}
        onClick={toggleSidebar}
        sx={(theme) => ({
          // mobile: the toggle button is at the bottom and when menu collapsed it's "floating" not half hidden
          // desktop: it is at the top and when sidebar collapsed it's half hidden
          bottom: { xs: 10, sm: 'unset' },
          top: { sm: 12 },
          transform: { xs: isSideBarOpen ? 'translateX(-50%)' : undefined, sm: 'translateX(-50%)' },

          position: 'fixed',
          borderRadius: '50%',
          transition: isSideBarOpen ? `left ${sidebarAnimation}` : undefined,
          left: isSideBarOpen ? 'var(--sidebar-width)' : 2,
          zIndex: theme.zIndex.modal - 10,
          backgroundColor: theme.palette.background.surface,
          border: '1px solid',
          borderColor: theme.palette.divider,
          '&:hover': {
            backgroundColor: theme.palette.primary.plainHoverBg,
          },
        })}
      >
        {isSideBarOpen ? (
          <KeyboardArrowLeft />
        ) : mobileView ? (
          // menu looks better than an arrow as a floating button
          <Menu />
        ) : (
          // IconButton is half hidden behind left edge of the screen
          // to not cut the arrow itself we move it 3px to the right from the center of IconButton
          <KeyboardArrowRight sx={{ transform: 'translateX(3px)' }} />
        )}
      </IconButton>
      {/* for now classic mui Drawer is used, mui joy does not support 'persistent' drawer, they have only plan for analog navigation sidebar component */}
      <Drawer
        variant={mobileView ? 'temporary' : 'persistent'}
        open={isSideBarOpen}
        className={REPLAY_UNMASK_CLASS_NAME}
        anchor="left"
        PaperProps={{
          sx: {
            width: 'var(--sidebar-width)',
            backgroundColor: 'var(--joy-palette-background-surface)',
            boxSizing: 'border-box',
          },
        }}
        sx={{ flexShrink: 0 }}
      >
        <SidebarTop />
        <Stack justifyContent="space-between" flexGrow={1} spacing={4} minHeight={0}>
          <Suspense fallback={<Loader variant={'medium'} fullHeight />}>
            <SidebarMenuSection />
          </Suspense>
        </Stack>
      </Drawer>
    </>
  );
};

const SidebarMenuSection = () => {
  const organizationFeatures = useOrganizationFeaturesSuspenseQuery();
  const features = organizationFeatures.data.management.features;
  const { isAdmin } = useAuth();
  const nameToStatus = new Map(features.map((feature) => [feature.name, feature.status]));

  const menu = useMemo(() => createMenu(nameToStatus, isAdmin), [nameToStatus, isAdmin]);
  return (
    <Stack justifyContent="space-between" flexGrow={1} spacing={4} minHeight={0}>
      <SidebarMenu menu={menu} scrollable />
      <SidebarMenu menu={footerMenu} />
    </Stack>
  );
};

export default Sidebar;
