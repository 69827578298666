import type { ReactElement } from 'react';
import { usePrivateAssetsSuspenseQuery } from '../../../../../generated/graphql.tsx';
import { Grid } from '@mui/joy';
import { CreatePrivateAssetDialog } from './CreatePrivateAssetDialog.tsx';
import GCard, { type GridWidth } from '../../../../technical/GCard/GCard.tsx';
import WithDialog from '../../../../technical/inputs/withDialogHoc/WithDialog.tsx';
import PrivateAssetCard from './PrivateAssetCard.tsx';

const GCardWithDialog = WithDialog(GCard);
const PrivateAssetManagementList = ({
  cardHeight,
  gridWidth,
}: { cardHeight: string; gridWidth: GridWidth }): ReactElement => {
  const { data } = usePrivateAssetsSuspenseQuery();

  return (
    <Grid container spacing={3}>
      <GCardWithDialog
        renderDialog={({ onClose }): ReactElement => <CreatePrivateAssetDialog onAdded={onClose} onClose={onClose} />}
        height={cardHeight}
        gridWidth={gridWidth}
        addNewText="Add new private asset"
      />
      {data.assets.list.map((asset) => (
        <PrivateAssetCard key={asset.id} asset={asset} gridWidth={gridWidth} height={cardHeight} />
      ))}
    </Grid>
  );
};

export default PrivateAssetManagementList;
