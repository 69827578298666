import bigNumMath from 'bigNumMath';
import { Decimal } from 'decimal.js';
import { bignumber, type BigNumber } from 'mathjs';
import { isValidNumber } from '../number.utils.ts';

const isEmpty = (value: string | number | undefined | null): value is string | number => !isValidNumber(value);

export const calculateAllocationSummary = (
  values: (string | number | undefined | null)[],
  failOnNegativeValues: boolean
): {
  showError: boolean;
  totalAllocation: BigNumber;
  emptyFieldsCount: number;
} => {
  const emptyFieldsCount = values.filter((val) => isEmpty(val)).length;

  const allocationValues = values.filter((val) => !isEmpty(val)).map((val) => bignumber(val));
  const hasNegativeValues = allocationValues.some((val) => val.isNegative());
  const absAllocationValues = allocationValues.map((val) => val.abs());
  const totalValue: BigNumber = bigNumMath.sum(absAllocationValues);

  const isTotalEqual100 = totalValue.equals(100);

  const canNormalizeValues = (totalValue.lessThan(100) || emptyFieldsCount === 0) && values.length > 0;

  return {
    showError:
      !(failOnNegativeValues && hasNegativeValues) && (!isTotalEqual100 || emptyFieldsCount > 0) && canNormalizeValues,
    emptyFieldsCount,
    totalAllocation: totalValue,
  };
};

export const scaleAllocation = (
  assetCount: number,
  summary: ReturnType<typeof calculateAllocationSummary>,
  getAssetAllocation: (index: number) => string | undefined,
  setAssetAllocation: (index: number, value: string, options?: { shouldValidate: boolean }) => void
): void => {
  let sum = bignumber(0);
  for (let i = 0; i < assetCount; i++) {
    const text = getAssetAllocation(i);
    const value = bignumber(text);

    let proposedNewValue = value.mul(100).div(summary.totalAllocation).toDP(2, Decimal.ROUND_DOWN);

    if (i === assetCount - 1) {
      proposedNewValue = bignumber(100).minus(sum);
    }

    sum = sum.plus(proposedNewValue.abs());

    setAssetAllocation(i, proposedNewValue.toFixed(2), { shouldValidate: true });
  }
};

export const scaleUpEmptyFields = (
  assetCount: number,
  summary: ReturnType<typeof calculateAllocationSummary>,
  getAssetAllocation: (index: number) => string | undefined,
  setAssetAllocation: (index: number, value: string, options?: { shouldValidate: boolean }) => void
): void => {
  let sum = bignumber(0);
  const valueToAdd = bignumber(100).minus(summary.totalAllocation);
  for (let i = 0; i < assetCount; i++) {
    const text = getAssetAllocation(i);
    if (!isEmpty(text)) {
      continue;
    }

    const proposedNewValue = valueToAdd.div(summary.emptyFieldsCount).toDP(2, Decimal.ROUND_DOWN);
    sum = sum.plus(proposedNewValue);

    setAssetAllocation(i, proposedNewValue.toFixed(2), { shouldValidate: true });
  }

  const lastValue = bignumber(getAssetAllocation(assetCount - 1));
  setAssetAllocation(
    assetCount - 1,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    lastValue
      .plus(valueToAdd.minus(sum))
      .toFixed(2),
    { shouldValidate: true }
  );
};
