import type { ReactElement } from 'react';
import { Box, Stack } from '@mui/system';
import { DateTimeFormat, formatDate } from 'components/formatter.utils';
import { IconVariant } from 'components/market/asset/cryptocurrencies/CryptocurrenciesData';
import SeeMoreDropdown from 'components/technical/SeeMoreDropDown/SeeMoreDropdown.tsx';
import GCard, { type GridWidth } from 'components/technical/GCard/GCard';
import { useUserTimezone } from 'components/technical/UseUserTimezone';
import { useNavigate } from 'react-router';
import { optimizationCardHeight } from './PortfolioOptimizerDashboard';
import UnknownIcon from 'components/technical/UnknownIcon/UnknownIcon';
import OptimizationStatusChip from './OptimizationStatusChip';
import { BUTTON_MENU_POSITION_STYLE } from 'components/technical/GCard/consts';
import type { OptimizationType } from './optimization.utils';
import { GCardKeyValueContent } from '../../technical/GCard/GCardKeyValueContent.tsx';
import { GCardContentWithDivider } from '../../technical/GCard/GCardContentWithDivider.tsx';
import { MenuItem } from '@mui/joy';
import type { IPortfolioOptimizationResultStatusType } from '../../../generated/graphql.tsx';

type OptimizationCardProps = {
  optimizationType: OptimizationType;
  optimization: {
    name: string;
    description: string;
    optimizationId: number;
    status: IPortfolioOptimizationResultStatusType;
    createdAt: string;
    updatedAt?: string | null;
  };
  gridWidth: GridWidth;
};

const OptimizationCard = ({ optimizationType, optimization, gridWidth }: OptimizationCardProps): ReactElement => {
  const navigate = useNavigate();
  const timezone = useUserTimezone();
  const updateSummary = [
    { key: 'Created At', value: formatDate(optimization.createdAt, DateTimeFormat.DateTime, timezone) },
    { key: 'Updated At', value: formatDate(optimization.updatedAt, DateTimeFormat.DateTime, timezone) },
  ];

  return (
    <GCard height={optimizationCardHeight} gridWidth={gridWidth} cardWrapperSx={{ position: 'relative' }}>
      <GCardContentWithDivider>
        <Box sx={BUTTON_MENU_POSITION_STYLE}>
          <SeeMoreDropdown>
            <MenuItem
              onClick={(): void =>
                navigate(
                  `/app/copilot/portfolio-optimizer/optimizations/${optimizationType}/${optimization.optimizationId}`
                )
              }
            >
              Open
            </MenuItem>
          </SeeMoreDropdown>
        </Box>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1.5} alignItems="center">
            <UnknownIcon text={optimization.name} size={IconVariant.XXXL} />
            <Stack direction="column" spacing={1}>
              <span>{optimization.name}</span>
              <OptimizationStatusChip resultCode={optimization.status} />
            </Stack>
          </Stack>
        </Stack>
        <GCardKeyValueContent
          data={[{ key: 'Description', value: optimization.description }]}
          updateSummary={updateSummary}
        />
      </GCardContentWithDivider>
    </GCard>
  );
};

export default OptimizationCard;
