import type { IFormat, IPortfolioRegimeResultQuery } from '../../../../../generated/graphql.tsx';
import { type ReactElement, useMemo } from 'react';
import GAgGrid from '../../../../technical/grids/GAgGrid.tsx';
import type { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { formatNumber, formatPercentage } from '../../../../formatter.utils.ts';
import stringify from 'json-stable-stringify';

type RowData = {
  portfolio: {
    name: string;
  };
  regime: string;
  metrics: Map<
    string, // metric id
    {
      value: number;
      format: IFormat;
    }
  >;
};

const labelBenchmarkToId = ({
  metricName,
  benchmark,
}: { metricName: string; benchmark?: { id: string; symbol: string } | null }): string => {
  return stringify([metricName, benchmark]);
};

const idToLabelBenchmark = (id: string): { metricName: string; benchmark?: { id: string; symbol: string } | null } => {
  const [name, benchmark] = JSON.parse(id);
  return { metricName: name, benchmark };
};

export const PortfolioRegimeMetricGrid = ({
  risk,
}: { risk: IPortfolioRegimeResultQuery['portfolio']['regimeRisk'] }): ReactElement => {
  const rows: RowData[] = risk.flatMap((portfolio) =>
    portfolio.data.map((regimeData) => {
      return {
        portfolio: portfolio.portfolio,
        regime: regimeData.regimeName,
        metrics: new Map(
          regimeData.metrics.map((metricItem) => {
            return [
              labelBenchmarkToId(metricItem),
              {
                value: metricItem.metricValue,
                format: metricItem.format,
              },
            ];
          })
        ),
      };
    })
  );

  const columns: ColDef<RowData>[] = useMemo(() => {
    const metrics: Record<string, IFormat> = Object.fromEntries(
      risk.flatMap((portfolio) =>
        portfolio.data.flatMap((regime) => regime.metrics.map((met) => [labelBenchmarkToId(met), met.format]))
      )
    );

    const sortedMetricNames = Object.keys(metrics).sort();

    return [
      {
        headerName: 'Portfolio',
        type: 'textColumn',
        field: 'portfolio.name',
      },
      {
        headerName: 'Regime',
        type: 'textColumn',
        field: 'regime',
      },
      ...sortedMetricNames.map((metric) => {
        const format = metrics[metric];
        const { metricName, benchmark } = idToLabelBenchmark(metric);
        return {
          headerName: `${metricName} ${benchmark ? `(${benchmark.symbol})` : ''}`,
          type: format === 'NUMBER' ? 'numericColumn' : 'percentageColumn',
          valueFormatter: (params: ValueFormatterParams<RowData>): string => {
            const formatter = format === 'NUMBER' ? formatNumber : formatPercentage;
            return formatter(params.value);
          },
          valueGetter: (params: ValueGetterParams<RowData>): number | undefined => {
            if (!params.data) {
              return undefined;
            }

            return params.data.metrics.get(metric)?.value;
          },
        };
      }),
    ];
  }, [risk]);

  return (
    <GAgGrid<RowData>
      height="700px"
      rowData={rows}
      columnDefs={columns}
      defaultColDef={{
        resizable: true,
        sortable: true,
        filter: true,
      }}
      enableCharts
      enableRangeSelection
      sideBar={{
        toolPanels: ['columns', 'filters'],
      }}
    />
  );
};
