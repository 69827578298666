import type { Dayjs } from 'dayjs';
import type { ObjectSchema } from 'yup';
import * as yup from 'yup';

import {
  IOrderSide,
  IOrderType,
  IPositionSide,
  ISlotType,
  ITransactionLegType,
  ITransactionStatus,
  IUserTransactionType,
} from '../../../../generated/graphql';
import { isDayjsDateNotInTheFuture, isValidDayjsDate } from '../../../date.utils';
import type { AssetSelectOptionValue } from '../../../market/asset/AssetService.tsx';
import type { AssetLabelInput } from '../../../market/asset/AssetLabelService.ts';
import type { FormInputType } from '../../../technical/form/Form.types.ts';
import { formatEnum } from '../../../formatter.utils.ts';
import type { SubAccountLabelInputAccount } from '../../../portfolio/account/SubAccountLabel.tsx';
import { yupWhen } from '../../../../validation.ts';
import { hasOrderAtLeastOneValue } from './TransactionCreateService.ts';
import { isAssetValidForAccount } from '../../../portfolio/account/AccountService.tsx';

export interface LegFormOutput {
  amount: number;
  asset: { id: string };
  side: IPositionSide | null;
  slot: ISlotType;
  time: Dayjs | null;
  type: ITransactionLegType;
  marketValue: number | null;
}

export type FormInputFields = FormInputType<FormOutputFields>;
export interface FormOutputFields {
  userType: IUserTransactionType;
  time: Dayjs;
  attributedToAsset: AssetLabelInput | null;
  externalId: string;
  order: {
    side: IOrderSide | null;
    type: IOrderType | null;
    externalId: string;
  };
  externalType: string;
  status: ITransactionStatus | null;
  legs: LegFormOutput[];
  tags: string[];
  comment: string;
  subAccount: SubAccountLabelInputAccount;
}

export const userTransactionTypes = Object.entries(IUserTransactionType).map(([name, value]) => ({
  label: formatEnum(value),
  value: value,
  key: name,
}));

export const statusTypes = Object.entries(ITransactionStatus).map(([name, value]) => ({
  label: name,
  value: value,
  key: name,
}));

export const orderTypes = [
  ...Object.entries(IOrderType).map(([name, value]) => ({
    label: formatEnum(value),
    value: value,
    key: name,
  })),
];

export const orderSide = [
  ...Object.entries(IOrderSide).map(([name, value]) => ({
    label: formatEnum(value),
    value: value,
    key: name,
  })),
];

export const positionSide = [
  ...Object.entries(IPositionSide).map(([name, value]) => ({
    label: formatEnum(value),
    value: value,
    key: name,
  })),
];

export const slotType = [
  ...Object.entries(ISlotType).map(([name, value]) => ({
    label: name,
    value: value,
    key: name,
  })),
];

export const transactionLegType = [
  ...Object.entries(ITransactionLegType).map(([name, value]) => ({
    label: name,
    value: value,
    key: name,
  })),
];

const createAssetSchema = (subAccount: {
  account: {
    venue: {
      label: string;
    };
  };
}): yup.Schema => {
  return yup
    .mixed()
    .nullable()
    .test('valid-asset', 'Asset is incompatible with sub-account', (value: unknown): boolean => {
      if (!subAccount) {
        return true;
      }

      if (!value) {
        return true;
      }

      return isAssetValidForAccount(value as AssetSelectOptionValue, subAccount.account);
    });
};

const orderFillOutText = 'Fill out all or leave blank order';

const createRequiredValuesSchema = (items: { value: string }[]): yup.Schema => {
  return yup
    .string()
    .oneOf(items.map((item) => item.value))
    .required();
};

const createNullableValuesSchema = (items: { value: string }[]): yup.Schema => {
  return yup
    .string()
    .oneOf(items.map((item) => item.value))
    .nullable();
};

export const formSchema: ObjectSchema<object> = yup.object({
  attributedToAsset: yupWhen(['subAccount'], ([subAccount]: { account: { venue: { label: string } } }[]) => {
    return createAssetSchema(subAccount);
  }),
  legs: yupWhen(['subAccount'], ([subAccount]: [{ account: { venue: { label: string } } }, Dayjs | null]) => {
    return yup.array().of(
      yup.object({
        amount: yup.number().required(),
        asset: createAssetSchema(subAccount).required(),
        side: createNullableValuesSchema(positionSide),
        slot: createRequiredValuesSchema(slotType),
        time: yup
          .mixed()
          .nullable()
          .test('valid-date', 'Date is invalid', isValidDayjsDate)
          .test('future-date', 'Date cannot be in the future', isDayjsDateNotInTheFuture),
        type: createRequiredValuesSchema(transactionLegType),
        marketValue: yup.number().nullable(),
      })
    );
  }),
  userType: yup.string().required().oneOf(Object.values(IUserTransactionType)),
  time: yup
    .mixed()
    .required()
    .test('valid-date', 'Date is invalid', isValidDayjsDate)
    .test('future-date', 'Date cannot be in the future', isDayjsDateNotInTheFuture),
  order: yup.object({
    side: createNullableValuesSchema(orderSide).test('orderSide required', orderFillOutText, (value, ctx) => {
      if (!hasOrderAtLeastOneValue(ctx.parent)) {
        return true;
      }

      return !!value;
    }),
    type: createNullableValuesSchema(orderTypes).test('orderType required', orderFillOutText, (value, ctx) => {
      if (!hasOrderAtLeastOneValue(ctx.parent)) {
        return true;
      }

      return !!value;
    }),
    externalId: yup
      .string()
      .test('orderType required', orderFillOutText, (value, ctx) => {
        if (!hasOrderAtLeastOneValue(ctx.parent)) {
          return true;
        }

        return !!value;
      })
      .nullable(),
  }),
  externalId: yup.string(),
  externalType: yup.string(),
  subAccount: yup.mixed().required(),
  status: yup.string().oneOf(Object.values(ITransactionStatus)).required(),
  tags: yup.array().of(yup.string()).required(),
  comment: yup.string(),
});
