import type { Table } from '@tanstack/table-core';
import type { ReactNode } from 'react';

import type { GTableProps } from './GTable.props';
import { isDynamic } from './GTable.utils';
import { GTablePagination } from './GTablePagination';
import { DEFAULT_PAGE_SIZES } from './UseTablePaginator.tsx';
import { Stack } from '@mui/system';

const canFillMoreThanOneMinPage = (results: number | undefined): boolean => {
  return (results ?? 0) > Math.min(...DEFAULT_PAGE_SIZES);
};

export const showPagination = <ROW,>(props: GTableProps<ROW>): boolean => {
  const maxResults = isDynamic(props) ? props.totalResults : props.data?.length ?? 0;
  return (
    !props.disablePagination &&
    ((isDynamic(props) && !props.loading && !props.error) || !isDynamic(props)) &&
    canFillMoreThanOneMinPage(maxResults)
  );
};

export const createPagination = <ROW,>(
  table: Table<ROW>,
  props: GTableProps<ROW>,
  hideRowsPerPage: boolean
): ReactNode => {
  const maxResults = isDynamic(props) ? props.totalResults : props.data?.length ?? 0;
  const pagination = showPagination(props);

  if (!props.topBar && !pagination) {
    return null;
  }

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      columnGap={1.5}
      rowGap={1}
      alignItems="flex-end"
      justifyContent={'flex-start'}
      width={'100%'}
    >
      {props.topBar}
      {pagination && <GTablePagination table={table} totalResults={maxResults} hideRowsPerPage={hideRowsPerPage} />}
    </Stack>
  );
};
