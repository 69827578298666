import { type ReactElement, Suspense } from 'react';
import { Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import PrivateAssetManagementList from './privateAsset/PrivateAssetManagementList.tsx';
import Loader from 'components/technical/Loader/Loader.tsx';
import PublicAssetRequestManagementList from './publicAsset/PublicAssetRequestManagementList.tsx';

const cardHeight = '10rem';
const gridWidth = { sm: 12, md: 6, lg: 4, xl: 4 };

const AssetManagementDashboard = (): ReactElement => {
  return (
    <Tabs aria-label="Tabs" defaultValue={0}>
      <TabList>
        <Tab>Private asset</Tab>
        <Tab>Asset support requests</Tab>
      </TabList>
      <TabPanel value={0}>
        <Suspense fallback={<Loader />}>
          <PrivateAssetManagementList cardHeight={cardHeight} gridWidth={gridWidth} />
        </Suspense>
      </TabPanel>
      <TabPanel value={1}>
        <Suspense fallback={<Loader />}>
          <PublicAssetRequestManagementList cardHeight={cardHeight} gridWidth={gridWidth} />
        </Suspense>
      </TabPanel>
    </Tabs>
  );
};

export default AssetManagementDashboard;
