import { Stack } from '@mui/joy';
import { bignumber } from 'mathjs';
import type { FunctionComponent } from 'react';
import { UserSettings } from 'components/management/UserSettings.types';
import GAgGridPresets from 'components/technical/grids/GAgGridPresets';
import { useSubAccountAssetFilters } from 'components/technical/SubAccountAssetFilterDrawer/UseSubAccountAssetFilters';
import { useDefaultErrorHandling } from 'components/technical/UseDefaultErrorHandling';
import { type IOpenPositionsSummaryQuery, useOpenPositionsSummaryQuery } from 'generated/graphql';

import { positionsSummaryDefaultPresets } from './defaultPresets';
import {
  accountColumn,
  amountColumn,
  balanceColumn,
  clusterColumn,
  costBasisColumn,
  exposureColumn,
  nameColumn,
  priceChangesGroupColumns,
  sideColumn,
  slotColumn,
  subAccountColumn,
  subFundGroupColumn,
  symbolColumn,
  typeColumn,
  underlyingAssetColumn,
  unrealizedPnlColumn,
  venueColumn,
  weightColumn,
} from '../../technical/grids/SharedReportColumns';
import { usePriceChanges } from './UsePriceChanges';
import { useReportAssetGroup } from '../../UseReportAssetGroups';
import { groupCostBasisByAssetAndSubFund } from '../account/SubAccountPositionsService';
import { mapSubAccountAndDimensionToSubFund } from 'components/bookkeeping/report/Report.utils';
import { calculateSubAccountToSubFundsMapper } from './PositionsSummary.util.ts';

const GROUP_COLUMN_MIN_WIDTH = 220;
type RowData = IOpenPositionsSummaryQuery['portfolio']['positions']['positions'][number];
const PositionsSummary: FunctionComponent = () => {
  const { subAccountAssetFilters } = useSubAccountAssetFilters();

  const openPositionsSummaryQueryResult = useDefaultErrorHandling(
    useOpenPositionsSummaryQuery({
      variables: { subAccountAssetFilters },
    })
  );
  const reportAssetGroup = useReportAssetGroup();

  const assetIds =
    openPositionsSummaryQueryResult.data?.portfolio.positions.positions.map((position) => position.asset.id) ?? [];

  const pricesChangesResult = usePriceChanges(assetIds);

  if (!openPositionsSummaryQueryResult.loaded) {
    return <openPositionsSummaryQueryResult.Fallback />;
  }
  if (!reportAssetGroup.loaded) {
    return <reportAssetGroup.Fallback />;
  }
  if (!pricesChangesResult.loaded) {
    return <pricesChangesResult.Fallback />;
  }

  const portfolio = openPositionsSummaryQueryResult.data.portfolio;
  const totalBalance = bignumber(portfolio.positions.summary.balance.total);
  const subFunds = portfolio.subFunds.list;
  const costBasisDimension = portfolio.journal.preferences.dimension ?? null;
  const subAccountToCostBasisSubFundsMapper = calculateSubAccountToSubFundsMapper(
    costBasisDimension,
    portfolio.subFunds.list
  );
  const costBasisPerAssetAndSubFund = groupCostBasisByAssetAndSubFund(portfolio.journal.costBasis);

  const { subAccountAndDimensionToSubFund, subFundDimensions } = mapSubAccountAndDimensionToSubFund(subFunds);

  return (
    <Stack spacing={1.5} height="100%">
      <GAgGridPresets<RowData>
        defaultPresets={positionsSummaryDefaultPresets}
        presetSettingsKey={UserSettings.PositionsSummaryPresets}
        rowData={portfolio.positions.positions}
        groupDefaultExpanded={-1}
        sideBar={{
          toolPanels: ['columns', 'filters'],
        }}
        enableCharts
        enableRangeSelection
        autoGroupColumnDef={{
          minWidth: GROUP_COLUMN_MIN_WIDTH,
        }}
        autoSizeStrategy={{ type: 'fitCellContents' }}
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: true,
        }}
        columnDefs={[
          {
            headerName: 'Asset Details',
            colId: 'asset-details',
            marryChildren: true,
            children: [
              nameColumn(),
              symbolColumn(),
              underlyingAssetColumn({
                initialRowGroup: true,
                initialRowGroupIndex: 0,
              }),
              ...reportAssetGroup.clusters.map((cluster) =>
                clusterColumn<RowData>(cluster, reportAssetGroup.assetAndGroupClusterMapToGroup)
              ),
            ],
          },
          {
            headerName: 'Account Details',
            colId: 'account-details',
            marryChildren: true,
            children: [accountColumn(), subAccountColumn(), venueColumn()],
          },
          {
            headerName: 'Sub-funds',
            colId: 'sub-funds',
            marryChildren: true,
            children: subFundDimensions.map((subFundDimension) =>
              subFundGroupColumn(subFundDimension, subAccountAndDimensionToSubFund)
            ),
          },
          typeColumn(),
          sideColumn(),
          slotColumn(),
          {
            headerName: 'Current positions',
            colId: 'currentPositions',
            marryChildren: true,
            children: [
              balanceColumn(),
              exposureColumn({ sideAware: true, initialHide: false }),
              amountColumn({ sideAware: true, initialHide: false }),
              unrealizedPnlColumn(costBasisPerAssetAndSubFund, subAccountToCostBasisSubFundsMapper),
              costBasisColumn(costBasisPerAssetAndSubFund, subAccountToCostBasisSubFundsMapper),
              weightColumn(totalBalance),

              exposureColumn({ sideAware: false, initialHide: true }),
              amountColumn({ sideAware: false, initialHide: true }),
            ],
          },
          ...priceChangesGroupColumns<RowData>(
            pricesChangesResult.priceChangeDates,
            pricesChangesResult.pricesByAssetAndDate,
            pricesChangesResult.priceChanges24hByAssetId
          ),
        ]}
      />
    </Stack>
  );
};

export default PositionsSummary;
