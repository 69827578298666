import { Add } from '@mui/icons-material';
import { Grid, Stack } from '@mui/joy';
import type { ReactElement } from 'react';
import GDialogHeader from 'components/technical/GDialog/GDialogHeader.tsx';
import GButton from 'components/technical/inputs/GButton/GButton';

import { useAuth } from '../../../../UseAuth.tsx';
import { venues as exchangesData } from '../../../venue/VenueData.tsx';
import VenueLabel from '../../../venue/VenueLabel.tsx';
import { AccountType } from '../AccountService.tsx';

const SelectExchangeStep = ({
  onVenueSelected,
  onGenieAccountSelected,
  accountsType,
}: {
  onVenueSelected: (exchange: string) => void;
  onGenieAccountSelected: () => void;
  accountsType?: AccountType;
}): ReactElement => {
  const { isAdmin } = useAuth();
  let entries = Object.entries(exchangesData)
    .filter(([, value]) => !value.cannotConnect)
    .filter(([, value]) => !value.adminOnly || isAdmin);

  switch (accountsType) {
    case AccountType.Custodian:
      entries = entries.filter(([, value]) => !value.blockchain);
      break;

    case AccountType.Blockchain:
      entries = entries.filter(([, value]) => value.blockchain);
      break;

    default:
      break;
  }

  return (
    <>
      <GDialogHeader>Select the account you want to connect</GDialogHeader>
      <Stack spacing={1.5}>
        <Grid container justifyContent="center">
          {entries.map(([label]) => (
            <Grid key={label} xs={6}>
              <GButton
                onClick={(): void => onVenueSelected(label)}
                width="fullWidth"
                variant="outlined"
                sx={{ justifyContent: 'flex-start' }}
              >
                <VenueLabel venue={label} />
              </GButton>
            </Grid>
          ))}
        </Grid>
        {(accountsType === AccountType.Virtual || !accountsType) && (
          <Stack alignItems="center" spacing={1.5}>
            <GButton
              color="primary"
              variant="plain"
              onClick={(): void => onGenieAccountSelected()}
              startDecorator={<Add />}
            >
              Create a custom account
            </GButton>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default SelectExchangeStep;
