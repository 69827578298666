import type { ReactElement } from 'react';
import { usePublicAssetRequestsSuspenseQuery } from '../../../../../generated/graphql.tsx';
import { Grid } from '@mui/joy';
import GCard, { type GridWidth } from '../../../../technical/GCard/GCard.tsx';
import WithDialog from '../../../../technical/inputs/withDialogHoc/WithDialog.tsx';
import { CreatePublicAssetRequestDialog } from './CreatePublicAssetRequestDialog.tsx';
import PublicAssetRequestCard from './PublicAssetRequestCard.tsx';

const GCardWithDialog = WithDialog(GCard);
const PublicAssetRequestManagementList = ({
  cardHeight,
  gridWidth,
}: { cardHeight: string; gridWidth: GridWidth }): ReactElement => {
  const { data } = usePublicAssetRequestsSuspenseQuery();

  return (
    <Grid container spacing={3}>
      <GCardWithDialog
        renderDialog={({ onClose }): ReactElement => (
          <CreatePublicAssetRequestDialog onAdded={onClose} onClose={onClose} />
        )}
        height={cardHeight}
        gridWidth={gridWidth}
        addNewText="Add using Coingecko id"
      />
      {data.assets.publicAssetRequests.map((req, i) => (
        <PublicAssetRequestCard key={i} request={req} gridWidth={gridWidth} height={cardHeight} />
      ))}
    </Grid>
  );
};

export default PublicAssetRequestManagementList;
